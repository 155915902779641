import { useContext, useState, useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

import { ConfigProvider } from 'antd';
import cx from 'classnames';
import Cookies, { CookieGetOptions } from 'universal-cookie';

import './Layout.scss';
import Overlay from 'components/Overlay/Overlay';
import SidebarMenu from 'components/SidebarMenu/SidebarMenu';

import { AppContext } from '../../contexts/AppContext';
import { useAuthContext } from '../../contexts/AuthContext';
import Nav from '../Nav/Nav';

const LayoutWithMenu = ({
  children,
  className = '',
}: {
  className?: string;
  children: React.ReactNode | React.ReactNode[];
}) => {
  const cookies = new Cookies();
  const options = { path: '/' };
  const location = useLocation();

  const history = useNavigate();

  // -------CAN USE MATCH ------------
  // const match = useMatch('/apps/:appKey/:entityKey');
  // const match2 = useMatch('/apps/:appKey');
  const { appKey } = useParams();

  const [expanded, setExpanded] = useState(
    cookies.get('sidebar', options as CookieGetOptions) || 'menu-expanded',
  );

  const onChange = () => {
    const value =
      expanded === 'menu-expanded' ? 'menu-collapsed' : 'menu-expanded';
    setExpanded(value);
    cookies.set('sidebar', value, options);
  };

  const {
    actions: { logout },
  } = useAuthContext();

  const {
    state: { apps, menuItems, settings, isLoading, me },
    actions: { changeMenuItems },
  } = useContext(AppContext);

  useEffect(() => {
    if (appKey) {
      changeMenuItems(appKey);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apps]);

  const selectedAppKey = appKey;
  const selectedApp =
    selectedAppKey && !!apps?.length
      ? apps.find(app => app.key === selectedAppKey)
      : {};
  return (
    <div className="m-0 p-0 w-100 d-flex">
      {expanded === 'menu-expanded' && (
        <div className="d-md-none d-block" onClick={onChange}>
          <Overlay className={`bg-black`} zIndex={1040} />
        </div>
      )}
      {location.pathname !== '/' && (
        <SidebarMenu
          expanded={expanded}
          instanceIcon={settings?.instanceIcon}
          instanceName={settings?.name}
        />
      )}

      <div className="w-100">
        <Nav
          apps={apps}
          instanceIcon={settings?.instanceIcon}
          instanceName={settings?.name}
          isAdmin={me?.isAdmin}
          loading={isLoading}
          selectedApp={selectedApp}
          menuItems={menuItems}
          menuLayout={settings?.menuLayout}
          onAppClick={app => {
            history(`/apps/${app.key}`);
            changeMenuItems(app.key);
          }}
          onLogoutClick={logout}
          onSettingSchemaClick={() => {
            history(`/settings/metadata`);
            changeMenuItems(null);
          }}
          onTap={onChange}
        />
        <div className={cx('Layout full-width', className)}>
          <ConfigProvider
            theme={{
              components: {
                Card: {},
              },
              token: {
                borderRadius: 0,
              },
            }}
          >
            <main>{children}</main>
          </ConfigProvider>
        </div>
      </div>
    </div>
  );
};

export default LayoutWithMenu;
