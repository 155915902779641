import { useContext, useEffect } from 'react';
import Helmet from 'react-helmet';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import './App.scss';
import 'react-toastify/dist/ReactToastify.css';
import DialogManager from './components/DialogManager/DialogManager';
import { AppContext } from './contexts/AppContext';
import PageRoutes from './page-routes';

const App = () => {
  const {
    state: { settings },
    actions: { fetchApps, fetchMe },
  } = useContext(AppContext);

  useEffect(() => {
    fetchApps();
    fetchMe();
  }, [fetchApps, fetchMe]);

  return (
    <>
      <BrowserRouter>
        <Helmet>
          {settings.favicon && <link rel="icon" href={settings.favicon} />}
        </Helmet>
        <PageRoutes />
      </BrowserRouter>
      <ToastContainer position="top-left" hideProgressBar />
      <DialogManager />
    </>
  );
};

export default App;
