import { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { BsPlusCircle, BsArrowUpRightCircle } from 'react-icons/bs';

import { get } from 'core/fetch';
import { ComponentInputProps } from 'sdk/models/ComponentProperties';
import buildRelatedGridOptions from 'utils/buildRelatedGridOptions';

import { AgGrid } from 'components/ThirdLibraries';
import { AppContext } from 'contexts/AppContext';
import { useDialogManager } from 'contexts/DialogManagerContext';

import LabelComponent from './Label';

const Grid = ({
  entityKey = '',
  entityId,
  defaultValue = '',
  props,
  settings = {
    allowCreate: false,
  },
  references,
}: ComponentInputProps) => {
  const {
    state: { settings: appSettings, selectedApp },
  } = useContext(AppContext);

  const { allowCreate } = settings;
  const { relatedTableKey, relatedFieldKey } = references || {};
  // props.ui_component_options?.relationshipField;
  const relation_name = references?.name || '';
  const [gridItems, setGridItems] = useState(defaultValue);
  const { openDialog } = useDialogManager();
  const { t } = useTranslation();

  const entitySchema = props?.schema;

  if (!entitySchema) {
    return <div />;
  }

  const { columnDefs, defaultColDef, suppressHorizontalScroll } =
    buildRelatedGridOptions(
      settings?.listView,
      entitySchema,
      appSettings,
      relatedTableKey,
    );

  const afterActionCallback = async () => {
    const response = await get(`/${entityKey}/${entityId}`);

    setGridItems(response[relation_name]);
  };

  return (
    <LabelComponent
      props={props}
      actions={
        <div className="text-right">
          <button
            className="btn btn-link btn-sm"
            type="button"
            disabled={!entityId}
            onClick={() => {
              window.open(`/apps/${selectedApp}/${relatedTableKey}`, '_blank');
            }}
          >
            <BsArrowUpRightCircle title={`${props.label} очих`} />
          </button>
          {allowCreate && (
            <button
              className="btn btn-link btn-sm"
              type="button"
              disabled={!entityId}
              title={
                !entityId
                  ? `Please create the ${entityKey} first, to start adding ${props.label}`
                  : ''
              }
              onClick={() => {
                openDialog({
                  entityKey: relatedTableKey,
                  afterSaveCallback: afterActionCallback,
                  afterDeleteCallback: afterActionCallback,
                  foreignKey: {
                    fieldName: relatedFieldKey,
                    fieldValue: entityId,
                  },
                });
              }}
            >
              <BsPlusCircle
                title={`${t('entity.buttons.create')} ${props.label}`}
              />
            </button>
          )}
        </div>
      }
    >
      <AgGrid
        rowData={gridItems || []}
        columnDefs={columnDefs}
        onRowSelected={event => {
          if (!event.node.selected) return;

          openDialog({
            entityKey: relatedTableKey,
            entityId: parseInt(event.node.data.id, 10),
            afterSaveCallback: afterActionCallback,
            afterDeleteCallback: afterActionCallback,
            foreignKey: {
              fieldName: relatedFieldKey,
              fieldValue: entityId,
            },
          });

          // Unselect row selected, so I can re-select the same again
          event.node.setSelected(false);
        }}
        defaultColDef={defaultColDef}
        suppressHorizontalScroll={suppressHorizontalScroll}
      />

      {props.description && (
        <small className="form-text text-body-tertiary">
          {props.description}
        </small>
      )}

      {gridItems.length >= 100 && (
        <small className="form-text text-danger">
          Холбоотой өгөгдөл 100-аас дээш гарсан байна
        </small>
      )}
    </LabelComponent>
  );
};

export default Grid;
