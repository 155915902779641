import i18n from 'i18next';

const Messages = {
  EntityCreated: i18n.t('message.entity-created'),
  EntityDeleted: i18n.t('message.entity-deleted'),
  EntityUpdated: i18n.t('message.entity-updated'),
  EntitySaved: i18n.t('message.entity-saved'),
  CommentAdded: i18n.t('message.comment-added'),
  AttachmentInserted: i18n.t('message.attachment-inserted'),
  AttachmentDeleted: i18n.t('message.attachment-deleted'),
};

export { Messages };
