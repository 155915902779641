import { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import {
  AiOutlineLink,
  AiOutlineUpload,
  AiOutlineCloudUpload,
} from 'react-icons/ai';

import PropTypes from 'prop-types';

import './Dropzone.scss';
import FileIcon from '../FileIcon/FileIcon';

function Dropzone({ onSubmit }) {
  const [files, setFiles] = useState<Array<any>>([]);
  const { t } = useTranslation();

  const onDrop = useCallback(
    acceptedFiles => setFiles(prevState => [...prevState, ...acceptedFiles]),
    [],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  return (
    <div className="row">
      <div className="col-12 mb-3">
        <div {...getRootProps({ className: 'Dropzone' })}>
          <input {...getInputProps()} />

          <AiOutlineCloudUpload size={40} />
          {t('entity.attachment.click-here')}

          <button type="button" className="btn btn-secondary mt-3">
            <AiOutlineUpload className="mr-2" />
            <span className="mx-1">
              {' '}
              {t('entity.attachment.upload-files')}{' '}
            </span>
          </button>
        </div>
      </div>

      {files?.length > 0 && (
        <>
          <div className="d-flex align-items-center justify-content-between mb-2">
            <h5 className="mt-2 mt-md-0">Files to upload:</h5>
            <button
              type="button"
              className="btn btn-outlined text-primary"
              onClick={() => {
                const response = onSubmit(files);
                if (response) {
                  setFiles([]);
                }
              }}
            >
              <AiOutlineLink />
              <span className="mx-2">Attach files</span>
            </button>
          </div>
          <div className="list-unstyled list-files">
            {files.map(file => (
              <div key={file.path} className="mb-1">
                <FileIcon type={file.type} /> <span>{file.name}</span>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
}
Dropzone.propTypes = {
  onSubmit: PropTypes.func,
};
Dropzone.defaultProps = {
  onSubmit: () => {},
};
export default Dropzone;
