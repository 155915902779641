import { Nav, Navbar } from 'react-bootstrap';

import './FormActionBar.scss';

const FormActionBar = ({ onRefLoaded }: { onRefLoaded: any }) => (
  <Navbar collapseOnSelect className="entity-actionbar">
    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
    <Navbar.Collapse id="responsive-navbar-nav">
      <Nav className="w-100">
        <div className="w-100" ref={el => onRefLoaded(el)} />
      </Nav>
    </Navbar.Collapse>
  </Navbar>
);

export default FormActionBar;
