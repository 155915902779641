import { useFormContext } from 'react-hook-form';

import cx from 'classnames';
import { ComponentInputProps } from 'sdk/models/ComponentProperties';

const Checkbox = ({
  name,
  defaultValue = false,
  props = { readOnly: false },
}: ComponentInputProps) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const { readOnly } = props;

  return (
    <div className="form-group">
      <div className="custom-control custom-checkbox">
        <div className="form-check">
          <input
            type="checkbox"
            id={`checkbox-${name}`}
            className={cx('custom-control-input form-check-input', {
              'is-invalid': errors[name],
            })}
            {...register(name)}
            defaultChecked={defaultValue}
            disabled={readOnly}
          />
          <label
            className="custom-control-label fomr-check-label mx-1"
            htmlFor={`checkbox-${name}`}
          >
            {props.label}
          </label>
        </div>
        <div>
          {props.description && (
            <small className="form-text text-body-tertiary">
              {props.description}
            </small>
          )}
        </div>
      </div>
    </div>
  );
};

export default Checkbox;
