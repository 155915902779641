import React, { useContext, useState } from 'react';
import { Tab, Nav } from 'react-bootstrap';

import { html } from '@codemirror/lang-html';
import { EditorView } from '@codemirror/view';
import CodeMirror from '@uiw/react-codemirror';
import { Button, Card } from 'antd';

import TsumEmpty from 'components/TsumEmpty/TsumEmpty';
import { MetadataContext } from 'contexts/MetadataContext';

// import { get } from '../../../core/fetch';

const DocumentEditor = () => {
  const [templateId, setTemplateId] = useState<any>();
  const [template] = useState<any>(null);

  const { state } = useContext(MetadataContext);

  // useEffect(() => {
  //   if (!templateId) {
  //     get(`/printable_document/${templateId}`).then(response => {
  //       setTemplate(response);
  //     });
  //   }
  // }, [templateId]);

  // useEffect(() => {
  //   if (state.tableMetadata?.printableDocuments?.length) {
  //     setTemplateId(state.tableMetadata.printableDocuments[0].id);
  //   } else {
  //     setTemplateId(undefined);
  //   }
  // }, [state.tableMetadata?.printableDocuments]);

  const PREVIEW_HEIGHT = 400;

  return (
    <div className="h-100">
      {state.tableMetadata?.printableDocuments?.length > 0 && (
        <div>
          <h5>Загварууд</h5>
          <div className="row mb-2">
            <div className="col-12 col-md-6 mb-2">
              <select
                className="form-control px-2"
                onChange={e => setTemplateId(e.target.value)}
              >
                {state.tableMetadata.printableDocuments.map((document: any) => (
                  <option key={document.id} value={document.id}>
                    {document.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-12 col-md-6 mb-2">
              <a
                type="button"
                className="btn btn-block btn-outline-primary"
                href="/apps/-/printable_document"
                target="blank"
              >
                <i className="fa fa-plus mr-2" />
                Шинэ загвар
              </a>
            </div>
          </div>

          <div>
            {template && (
              <Tab.Container id="printable-docs-tabs" defaultActiveKey="design">
                <Nav variant="tabs" className="mb-4">
                  <Nav.Item>
                    <Nav.Link eventKey="design">Дезайн</Nav.Link>
                  </Nav.Item>

                  <Nav.Item>
                    <Nav.Link eventKey="source">Код</Nav.Link>
                  </Nav.Item>
                </Nav>

                <Tab.Content>
                  <Tab.Pane eventKey="source" mountOnEnter>
                    <div>
                      <CodeMirror
                        value={template.template}
                        defaultValue={template.template}
                        height={PREVIEW_HEIGHT.toString()}
                        readOnly={true}
                        extensions={[[html(), EditorView.lineWrapping]]}
                      />
                    </div>
                  </Tab.Pane>

                  <Tab.Pane eventKey="design" mountOnEnter>
                    <iframe
                      src={`/api/${template.entity_key}/doc/${templateId}`}
                      title="description"
                      height={PREVIEW_HEIGHT}
                      width="100%"
                      frameBorder="0"
                    />
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            )}
          </div>
        </div>
      )}

      {!state.tableMetadata?.printableDocuments?.length && (
        <Card className="h-100">
          <TsumEmpty
            description={
              <span>
                Та алхам сонгоогүй байна. Хэрэв шинэ алхам үүсгэх бол доорх
                товчийг дарж үүсгэнэ үү
              </span>
            }
          >
            <Button type="primary" htmlType="button">
              Шинэ баримт
            </Button>
          </TsumEmpty>
        </Card>
      )}
    </div>
  );
};

export default DocumentEditor;
