import { useContext } from 'react';
import { Container } from 'react-bootstrap';

import AppMenu from '../components/AppMenu/AppMenu';
import LayoutWithMenu from '../components/Layout/LayoutWithMenu';
import { AppContext } from '../contexts/AppContext';

enum MenuType {
  GROUP,
  ITEM,
}
const Dashboard = () => {
  const { state } = useContext(AppContext);

  const settingsMenus = ['settings', 'report'];
  const menus = [
    { key: 'group-apps', type: MenuType.GROUP, name: 'APPS' },
    ...state.apps.filter(app => !settingsMenus.includes(app.key)),
    { key: 'group-settings', type: MenuType.GROUP, name: 'SETTINGS' },
    ...state.apps.filter(app => settingsMenus.includes(app.key)),
  ];
  return (
    <LayoutWithMenu>
      <Container fluid className="Dashboard">
        <div>
          {menus.map(app => {
            if (app.type === MenuType.GROUP) {
              return (
                <h5 key={`${app.key}_label`} className="mx-4 mb-3">
                  {app.name}
                </h5>
              );
            }
            return (
              <div
                key={app.key}
                className="mb-5 mx-4"
                style={{
                  width: '163.11px',
                  display: 'inline-block',
                }}
              >
                <AppMenu app={app} />
              </div>
            );
          })}
        </div>
      </Container>
    </LayoutWithMenu>
  );
};

export default Dashboard;
