import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AgGridReact } from 'ag-grid-react';
import cx from 'classnames';

import s from './AgGrid.module.scss';
type IAgGrid = {
  rowData?: any[];
  columnDefs?: any[];
  onGridReady?: (api: any) => any;
  onCellClicked?: (event) => any;
  onSortChanged?: (params) => any;
  getRowNodeId?: (params) => any;
  defaultColDef?: {
    autoHeight?: boolean;
    resizable?: boolean;
  };
  rowSelection?: 'single' | 'multiple';
  suppressHorizontalScroll?: boolean;
  rowStyle?: any;
  className?: string;
  onRowSelected?: (params) => any;
  enableCellTextSelection?: boolean;
};

const AgGrid = ({
  rowData = [],
  columnDefs = [],
  onGridReady = () => {},
  onCellClicked,
  onSortChanged,
  getRowNodeId,
  defaultColDef = {
    autoHeight: true,
    resizable: true,
  },
  rowSelection = 'single',
  suppressHorizontalScroll = false,
  rowStyle = {},
  className,
  enableCellTextSelection = false,
  onRowSelected,
}: IAgGrid) => {
  const [gridApi, setGridApi] = useState(null);
  const { t } = useTranslation();

  const autoSizeColumns = columnApi => {
    const skipHeader = false;
    const allColumnIds: any = [];
    columnApi.getAllColumns().forEach(column => {
      allColumnIds.push(column.colId);
    });
    columnApi.autoSizeColumns(allColumnIds, skipHeader);
  };

  const gridReady = params => {
    setGridApi(params.api);

    if (window.innerWidth > 768) {
      params.api.sizeColumnsToFit();
      return onGridReady(params.api);
    }

    autoSizeColumns(params.columnApi);
    return onGridReady(params.api);
  };

  const gridResize = useCallback(() => {
    if (!gridApi) return;

    if (window.innerWidth > 768) {
      // obs: sizeColumnsToFit() warning message still there, it is an issue of aggrid
      // gridApi.sizeColumnsToFit();
    }
  }, [gridApi]);

  useEffect(() => {
    window.addEventListener('resize', gridResize);

    return () => window.removeEventListener('resize', gridResize);
  }, [gridResize]);

  return (
    <div className={cx('ag-theme-material EntityTable', className, s.grid)}>
      <AgGridReact
        rowClass={cx({ 'row-pointer': onCellClicked })}
        rowData={rowData}
        onGridReady={gridReady}
        enableCellTextSelection={enableCellTextSelection}
        onCellClicked={onCellClicked}
        onSortChanged={onSortChanged}
        getRowId={getRowNodeId}
        defaultColDef={defaultColDef}
        rowSelection={rowSelection}
        suppressHorizontalScroll={suppressHorizontalScroll}
        rowStyle={rowStyle}
        onRowSelected={onRowSelected}
        overlayNoRowsTemplate={t('entity.grid.no-row')}
        columnDefs={columnDefs}
      />
    </div>
  );
};

export default AgGrid;
