import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import cx from 'classnames';

import './FormTableComment.scss';
import { useEntityContext } from '../../../contexts/EntityContext';
import { Messages } from '../../../utils/messages';
import ShowToast from '../../../utils/ShowToast';
import { getInitialLetter } from '../../../utils/text';
import { IronBody } from '../../Layout';
import CommentsLoader from '../../Loaders/CommentsLoader';
import Moment from '../../ThirdLibraries/Moment/Moment';

const FormTableComment = ({
  entityKey,
  entityId,
}: {
  entityKey: string;
  entityId: number;
}) => {
  const [loading, setLoading] = useState(false);
  const [insertLoading, setInsertLoading] = useState(false);
  const [displayCommentField, setDisplayCommentField] = useState(false);
  const {
    formState: { errors },
    register,
    setValue,
    handleSubmit,
  } = useForm();
  const { t } = useTranslation();

  const {
    getEntityState,
    actions: { fetchTableComments, insertTableComment },
  } = useEntityContext();

  const entityState = getEntityState(entityKey, entityId);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        await fetchTableComments(entityKey, entityId);
      } catch (error) {
        await ShowToast.error(error as string);
      }
      setLoading(false);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleDisplayCommentField = () => {
    setDisplayCommentField(!displayCommentField);
  };

  const cancel = () => {
    setDisplayCommentField(false);
  };

  const onSubmit = async formData => {
    setInsertLoading(true);
    const { id } = await insertTableComment(entityKey, formData, entityId);
    setInsertLoading(false);

    if (id) {
      await ShowToast.success(Messages.CommentAdded);
      fetchTableComments(entityKey, entityId);

      setValue('comment', '');
      toggleDisplayCommentField();
    }
  };

  const avatar = createdBy => (
    <div className="d-flex align-items-center py-3">
      <div className="circle circle-gray rounded-circle">
        {createdBy?.image?.url ? (
          <img src={createdBy.image.url} className="w-100" />
        ) : (
          getInitialLetter(createdBy?.first_name)
        )}
      </div>
      <div className="mx-2">
        {createdBy.first_name
          ? createdBy.first_name
          : t('entity.comment.unknown')}
      </div>
    </div>
  );

  return (
    <IronBody>
      <div className="TableComment">
        {!displayCommentField ? (
          <div
            role="presentation"
            onClick={() => toggleDisplayCommentField()}
            data-cy="show-comment-field"
          >
            <div className="form-group">
              <input
                className="form-control"
                role="button"
                id="insert-comment"
                type="text"
                name="insert-comment"
                placeholder={t('entity.comment.add-comment')}
              />
            </div>
          </div>
        ) : (
          <div className="media comment pt-0">
            <div className="media-body">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group mb-0">
                  <textarea
                    className={cx('form-control', {
                      'is-invalid': errors.comment,
                    })}
                    rows={5}
                    autoFocus
                    maxLength={500}
                    {...register('comment', { required: true, maxLength: 500 })}
                  />

                  {errors.comment && (
                    <span className="text-danger">
                      {/* {errors.comment.message} */}
                    </span>
                  )}
                </div>

                <div className="mt-2">
                  <button
                    className="btn btn-primary"
                    type="submit"
                    data-cy="save-comment"
                    // disabled={meta.isLoading}
                  >
                    {t('entity.comment.add-comment')}
                  </button>

                  <button
                    className="btn btn-danger mx-3"
                    type="submit"
                    disabled={entityState?.formIsBlocked}
                    onClick={e => {
                      e.preventDefault();
                      cancel();
                    }}
                  >
                    {t('entity.comment.cancel')}
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
        <div className="d-flex align-items-center pt-3">
          <div>{t('entity.comment.comments')}</div>
          <div className="comment-count mx-2">
            <div>{entityState?.comments?.length || 0}</div>
          </div>
        </div>
        {insertLoading && <CommentsLoader rows={1} />}
        {!loading ? (
          entityState?.comments?.map(comment => (
            <div className="comment" key={comment.id}>
              <div className="media">
                <div className="d-flex align-items-center">
                  {avatar(comment.created_by)}
                  <small>
                    <Moment fromNow>{comment._created_at}</Moment>
                  </small>
                </div>
                <div className="media-body">
                  <div data-cy="comment-message">{comment.comment}</div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <CommentsLoader />
        )}
      </div>
    </IronBody>
  );
};

export default FormTableComment;
