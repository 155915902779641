import { Popconfirm } from 'antd';
import i18n from 'i18next';

const { t } = i18n;
const PopConfirmExit = ({
  title,
  onCancel,
  onConfirm,
  cancelMessage,
  confirmMessage,
  body,
}: {
  title?: string;
  modalLevel?: number;
  onCancel?: any;
  cancelMessage?: string;
  onConfirm?: any;
  confirmMessage?: string;
  body: JSX.Element;
}) => {
  return (
    <Popconfirm
      disabled={false}
      title={title || t('entity.modal.saving-confirm')}
      onConfirm={() => onConfirm()}
      onCancel={onCancel && onCancel}
      okText={confirmMessage || t('entity.modal.close-without-save')}
      cancelText={cancelMessage || t('entity.modal.cancel')}
    >
      {body}
    </Popconfirm>
  );
};

export default PopConfirmExit;
