export function adjustColour(color, amount) {
  return `#${color
    .replace(/^#/, '')
    .replace(/../g, currColor =>
      `0${Math.min(255, Math.max(0, parseInt(currColor, 16) + amount)).toString(
        16,
      )}`.substr(-2),
    )}`;
}

const changeThemeColor = themeColor => {
  document.documentElement.style.setProperty('--primary', themeColor.primary);

  document.documentElement.style.setProperty(
    '--primary-darker',
    themeColor.primaryDarker,
  );

  document.documentElement.style.setProperty(
    '--primary-light',
    themeColor.primaryLight,
  );

  document.documentElement.style.setProperty(
    '--primary-mid-light',
    themeColor.primaryMidLight,
  );

  document.documentElement.style.setProperty(
    '--primary-extra-light',
    themeColor.primaryExtraLight,
  );

  document.documentElement.style.setProperty(
    '--primary-lightest',
    themeColor.primaryLightest,
  );
};

export default changeThemeColor;
