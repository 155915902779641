import React, { createContext, useReducer } from 'react';
import { MarkerType } from 'reactflow';

import { orderBy } from 'lodash';

import reducer, { ActionTypes, initialState } from './WorkflowState';
import {
  WorkflowSteps,
  convertToActions,
  convertToSteps,
} from './WorkflowUtils';

interface WorkflowContextActions {
  fetchActions: () => void;
  fetchSteps: () => void;
  setNodes: (nodes: any[]) => void;

  addNode: (node: any) => void;
  updateNodes: (changes: any[]) => void;
  removeNode: (node) => void;
  selectNode: (node) => void;
  deselectNode: () => void;

  updateAction: (action: any) => void;
  removeAction: (action: any) => void;
  addAction: (node: any) => void;

  setEdges: (edges: any[]) => void;
  updateEdge: (node) => void;
  selectEdge: (node) => void;

  changeTab: (tabKey) => void;
}

export interface WorkflowContextState {
  flowActions: WorkflowContextActions;
  flowState: {
    error: null | string;
    steps: any[];
    actions: any[];
    nodes: any[];
    edges: any[];
    selectedNode: any;
    selectedEdge: any;
    selectedTab: string | null;
    isUpdated: boolean;
  };
}

export const WorkflowContext = createContext<WorkflowContextState>({
  flowActions: {
    fetchActions: () => {},
    fetchSteps: () => {},

    setNodes: ([]) => {},
    addNode: ({}) => {},
    updateNodes: ([]) => {},
    removeNode: () => {},
    selectNode: () => {},
    deselectNode: () => {},

    updateAction: () => {},
    removeAction: () => {},
    addAction: ({}) => {},

    setEdges: ([]) => {},
    updateEdge: () => {},
    selectEdge: () => {},

    changeTab: () => {},
  },
  flowState: initialState,
});

const WorkflowContextProvider = ({ children }) => {
  const [flowState, dispatch] = useReducer(reducer, initialState);

  const linkFirstNode = (nodes, removedNodeId?) => {
    const firstNode = orderBy(nodes, ['data.tableData.order'], ['asc'])[0];
    const firstEdge = flowState.edges.find(
      edge => edge.source === WorkflowSteps.start,
    );

    if (firstEdge.target !== firstNode.id || removedNodeId) {
      const updatedEdges = flowState.edges
        .map(edge => {
          if (edge.source === WorkflowSteps.start) {
            return { ...edge, target: firstNode.id };
          }
          return edge;
        })
        .filter(
          edge =>
            edge.source !== removedNodeId && edge.target !== removedNodeId,
        );
      dispatch({
        type: ActionTypes.UPDATE_EDGE_LIST,
        payload: updatedEdges,
      });
    }
  };

  const fetchActions = () => {
    dispatch({
      type: ActionTypes.FETCH_ACTION_LIST,
      payload: convertToActions(flowState.edges),
    });
  };

  const fetchSteps = () => {
    dispatch({
      type: ActionTypes.FETCH_STEP_LIST,
      payload: convertToSteps(flowState.nodes),
    });
  };

  const setNodes = nodes => {
    dispatch({
      type: ActionTypes.SET_NODE_LIST,
      payload: nodes,
    });
  };

  const setEdges = edges => {
    dispatch({
      type: ActionTypes.SET_EDGE_LIST,
      payload: edges,
    });
  };

  const addNode = tableData => {
    const newNode = {
      id: tableData.key,
      data: {
        label: tableData.name,
        isStep: true,
        tableData: { ...tableData, ownershipDetails: {} },
      },
      position: { x: 0, y: 0 },
      type: 'stepNode',
    };

    const nodes = [...flowState.nodes, newNode];
    linkFirstNode(nodes);

    dispatch({
      type: ActionTypes.UPDATE_NODE_LIST,
      payload: nodes,
    });

    dispatch({
      type: ActionTypes.SELECT_NODE,
      payload: newNode,
    });
  };

  const updateNodes = changes => {
    const nodes = flowState.nodes.map(node => {
      const change = changes.find(c => c.id === node.id);
      if (change) {
        const updatedNode = {
          ...node,
          ...(change.position ? { position: change.position } : {}),
          ...(change.data?.tableData
            ? {
                data: {
                  label: change.data.tableData.name,
                  tableData: change.data.tableData,
                  isStep: node.data.isStep,
                },
              }
            : {}),
        };
        return updatedNode;
      }
      return node;
    });

    linkFirstNode(nodes);
    dispatch({
      type: ActionTypes.UPDATE_NODE_LIST,
      payload: nodes,
    });
  };

  const removeNode = node => {
    const nodes = flowState.nodes.filter(n => n.id !== node.id);

    linkFirstNode(nodes, node.id);
    dispatch({
      type: ActionTypes.UPDATE_NODE_LIST,
      payload: nodes,
    });
  };

  const selectNode = node => {
    const selectedNode = flowState.nodes.find(n => n.id === node.id);
    dispatch({
      type: ActionTypes.SELECT_NODE,
      payload: selectedNode,
    });
  };

  const deselectNode = () => {
    dispatch({
      type: ActionTypes.DESELECT_NODE,
      payload: null,
    });
  };

  const updateAction = action => {
    const updatedEdges = flowState.edges
      .filter(
        edge =>
          (action.from.includes(edge.source) && action.to === edge.target) ||
          edge.target !== action.to ||
          edge.source === WorkflowSteps.start,
      )
      .map(edge => {
        if (edge.target === action.to && edge.source !== WorkflowSteps.start) {
          return {
            ...edge,
            label: action.name,
          };
        }
        return edge;
      });

    // Шинээр нэмсэн холбоосууд
    for (const newEdge of action.from.filter(
      from =>
        !updatedEdges.find(
          edge => edge.target === action.to && edge.source === from,
        ),
    )) {
      updatedEdges.push({
        id: `${newEdge}to${action.to}`,
        source: newEdge,
        target: action.to,
        label: action.name,
        markerEnd: { type: MarkerType.ArrowClosed },
        sourceHandle: 'right',
        targetHandle: 'left',
      });
    }
    dispatch({
      type: ActionTypes.UPDATE_EDGE_LIST,
      payload: updatedEdges,
    });
  };

  const removeAction = action => {
    const updatedEdges = flowState.edges.filter(
      edge => edge.target !== action.to,
    );
    dispatch({
      type: ActionTypes.UPDATE_EDGE_LIST,
      payload: updatedEdges,
    });
  };

  const addAction = action => {
    const newEdges: any[] = [];

    for (const newEdge of action.from) {
      newEdges.push({
        id: `to${action.to}`,
        source: newEdge,
        key: action.key,
        target: action.to,
        label: action.name,
        labelBgPadding: [8, 4],
        labelBgBorderRadius: 4,
      });
    }

    const edges = [...flowState.edges, ...newEdges];
    dispatch({
      type: ActionTypes.UPDATE_EDGE_LIST,
      payload: edges,
    });
  };

  const updateEdge = edge => {
    const edges = flowState.edges.map(e => (e.id === edge.id ? edge : e));

    dispatch({
      type: ActionTypes.UPDATE_EDGE_LIST,
      payload: edges,
    });
    dispatch({
      type: ActionTypes.SELECT_EDGE,
      payload: edge,
    });
  };

  const selectEdge = edge => {
    const selectedEdge = flowState.edges.find(e => e.id === edge.id);
    dispatch({
      type: ActionTypes.SELECT_EDGE,
      payload: selectedEdge,
    });
  };

  const changeTab = tabKey => {
    dispatch({
      type: ActionTypes.CHANGE_TAB,
      payload: tabKey,
    });
  };

  const flowActions: WorkflowContextActions = {
    fetchActions,
    fetchSteps,
    setNodes,
    addNode,
    updateNodes,
    removeNode,
    selectNode,
    deselectNode,

    updateAction,
    removeAction,
    addAction,

    setEdges,
    updateEdge,
    selectEdge,

    changeTab,
  };

  return (
    <WorkflowContext.Provider value={{ flowActions, flowState }}>
      {children}
    </WorkflowContext.Provider>
  );
};

export default WorkflowContextProvider;
