import { useTranslation } from 'react-i18next';

import LoginLayout from 'components/Layout/LoginLayout/LoginLayout';

const TSUM_API = process.env.REACT_APP_JACSYS_TSUM_API;

const Login = () => {
  const { t } = useTranslation();
  return (
    <LoginLayout>
      <div className="w-100 d-flex flex-column align-items-center form-style">
        <div className="w-100 px-3 mb-3">
          <a
            className="btn btn-primary w-100"
            type="button"
            href={`${TSUM_API}/auth/login`}
          >
            {t('main.signin')}
          </a>
        </div>
        <p className="m-0 letter-spacig-lg text-center">
          <a
            className="text-body"
            href="https://www.jacsys.mn"
            rel="noopener noreferrer"
            target="_blank"
          >
            {t('main.copyright')}
          </a>
        </p>
      </div>
    </LoginLayout>
  );
};

export default Login;
