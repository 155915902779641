import { useEffect } from 'react';

import { useEntityContext } from '../../../contexts/EntityContext';
import { FormMode } from '../../../core/formMode';
import FormMain from '../FormMain/FormMain';
import FormTableAttachment from '../FormTableAttachment/EntityAttachment';
import FormTableComment from '../FormTableComment/FormTableComment';
import FormTableHistory from '../FormTableHistory/FormTableHistory';

const FormDrawerBody = ({
  entityId,
  entityKey,
  actionBarRef,
  mainTab,
}: {
  entityId: number;
  entityKey: string;
  actionBarRef: any;
  mainTab: string;
}) => {
  const { actions, getEntityState } = useEntityContext();

  const entityState = getEntityState(entityKey, entityId);
  useEffect(() => {
    if (entityId || !entityState?.schema)
      actions.fetchOneByType(entityKey, entityId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createOrEditMode = entityId ? FormMode.edit : FormMode.create;
  const formMode = entityState?.formData?._workflow_outcome
    ? FormMode.readonly
    : createOrEditMode;

  const entitySchema = entityState?.schema;
  switch (mainTab) {
    case 'default':
      return (
        <FormMain
          entityState={entityState}
          formMode={formMode}
          entityActionBarRef={actionBarRef}
          entityKey={entityKey}
          entityId={entityId}
        />
      );
    case 'attachments':
      return (
        <>
          {entitySchema?.allowAttachments && (
            <FormTableAttachment entityKey={entityKey} entityId={entityId} />
          )}
        </>
      );

    case 'history':
      return <FormTableHistory entityKey={entityKey} entityId={entityId} />;
    case 'comments':
      return (
        <>
          {entitySchema?.allowComments && (
            <FormTableComment entityKey={entityKey} entityId={entityId} />
          )}
        </>
      );
    default:
      return (
        <FormMain
          entityState={entityState}
          formMode={formMode}
          entityActionBarRef={actionBarRef}
          entityKey={entityKey}
          entityId={entityId}
        />
      );
  }
};

export default FormDrawerBody;
