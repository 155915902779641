import { useContext } from 'react';

import { Tabs } from 'antd';

import {
  WorkflowContext,
  WorkflowContextState,
} from '../WorkflowContextProvider';
import { ActionProperty } from './ActionProperty';
import { EdgeProperty } from './EdgeProperty';
import { NodeProperty } from './NodeProperty';

export const WorkflowProperties: React.FC = () => {
  const { flowActions, flowState } =
    useContext<WorkflowContextState>(WorkflowContext);

  return (
    <Tabs
      className="h-100"
      activeKey={flowState.selectedTab || 'action-property'}
      onChange={key => flowActions.changeTab(key)}
      items={[
        {
          label: `Үйлдэл`,
          key: 'action-property',
          children: <ActionProperty />,
        },
        {
          label: `Алхам`,
          key: 'node-property',
          children: <NodeProperty />,
        },
        {
          label: `Холбоос`,
          key: 'connection-property',
          children: <EdgeProperty />,
        },
      ]}
    />
  );
};
