import i18n from 'i18next';

const ActionTypes = {
  FETCH_APPS_REQUEST: '[App] Fetch Apps Request',
  FETCH_APPS_SUCCESS: '[App] Fetch Apps Success',
  FETCH_APPS_FAIL: '[App] Fetch Apps Fail',
  FETCH_MENU_ITEMS_REQUEST: '[App] Fetch Menu Items Request',
  FETCH_MENU_ITEMS_SUCCESS: '[App] Fetch Menu Items Success',
  FETCH_MENU_ITEMS_FAIL: '[App] Fetch Menu Items Fail',
  MENU_ITEMS_CHANGED: '[App] Fetch Menu Items Changed',
  FETCH_ME_REQUEST: '[App] Fetch Me Request',
  FETCH_ME_SUCCESS: '[App] Fetch Me Success',
  FETCH_ME_FAIL: '[App] Fetch Me Fail',
};

const initialState = {
  isLoading: false,
  error: null,
  apps: [],
  me: null,
  settings: {
    name: 'loading',
    themeColor: {
      primaryDarker: '#5534e6',
      primary: '#5534e6',
      primaryLight: '#5534e6',
      primaryMidLight: '#5534e6',
      primaryExtraLight: '#5534e6',
      primaryLightest: '#5534e6',
    },
    dateFormat: 'dd/MM/yyyy',
    timeFormat: 'h:mm a',
  },
  menuItems: [],
  selectedApp: undefined,
  isLoadingDashboard: false,
  dashboardEmbedUrl: undefined,
  dashboardAdapter: undefined,
};

const reducer = (state, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_APPS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.FETCH_APPS_SUCCESS: {
      return {
        ...state,
        apps: [
          ...action.payload.apps,
          ...[
            {
              id: 999,
              key: 'report',
              icon: 'la-chart-bar',
              color: 'purple',
              menu: [],
              name: i18n.t('main.report-app-name'),
            },
          ],
        ],
        settings: {
          ...state.settings,
          ...action.payload.settings,
        },
        isLoading: false,
        creds: action.payload.creds,
      };
    }

    case ActionTypes.FETCH_APPS_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload.message || action.payload.messages,
      };

    case ActionTypes.FETCH_MENU_ITEMS_REQUEST:
      return {
        ...state,
        isLoading: true,
        selectedApp: action.payload,
        menuItems: [],
      };

    case ActionTypes.MENU_ITEMS_CHANGED:
    case ActionTypes.FETCH_MENU_ITEMS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        menuItems: action.payload.menuItems,
        selectedApp: action.payload.selectedApp,
      };

    case ActionTypes.FETCH_MENU_ITEMS_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload.message || action.payload.messages,
      };

    case ActionTypes.FETCH_ME_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.FETCH_ME_SUCCESS: {
      return {
        ...state,
        me: {
          ...action.payload,
          isAdmin: action.payload.security_role?.is_administrator,
        },
      };
    }

    case ActionTypes.FETCH_ME_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload.message,
      };

    default:
      throw new Error();
  }
};

export { initialState, ActionTypes };

export default reducer;
