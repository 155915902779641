import React, {
  createContext,
  useCallback,
  useContext,
  useReducer,
} from 'react';

import { isEmpty } from 'lodash';
import moment from 'moment';

import { get, post, put, remove, download } from '../core/fetch';
import downloadFile from '../utils/fileUtils';
import ShowToast from '../utils/ShowToast';
import { buildSchemaValidation } from '../utils/validations';
import reducer, {
  initialState,
  ActionTypes,
  getStateName,
} from './state/EntityState';

interface EntityContextActions {
  resetEntityStates: (entityKey: string, entityId?: number) => Promise<void>;
  resetEntity: (entityKey: string) => void;
  resetEntityFormData: (entityKey: string) => void;
  fetchOneByType: (entityKey: string, entityId?: number) => Promise<void>;
  fetchTableTriggerContent: (triggerId: string) => Promise<void>;
  setEntityView: (entityKey, view) => void;
  fetchAllByEntityName: (
    entityKey: string,
    limit: number,
    page?: number,
    sort?: string[],
    params?: any,
    withRelated?: string[],
    groupBy?: any[],
  ) => Promise<void>;
  insertEntityAttachment: (
    entityKey: string,
    data: any,
    entityId: number,
  ) => Promise<any>;
  deleteEntityAttachment: (
    entityKey: string,
    entityId: number,
    attachmentId: number,
  ) => Promise<boolean>;
  deleteEntity: (entityKey: string, entityId: number) => Promise<any>;
  fetchEntityAttachments: (entityKey: string, entityId: number) => Promise<any>;
  fetchTableHistories: (entityKey: string, entityId: number) => Promise<any>;

  fetchTableComments: (entityKey: string, entityId: number) => Promise<any>;
  insertTableComment: (
    entityKey: string,
    data: any,
    entityId: number,
  ) => Promise<any>;

  insertFileUpload: (
    entityKey: string,
    name: string,
    data: any,
    onUploadProgress: (progressEvent: any) => void,
  ) => Promise<any>;
  create: (entityKey: string, data: any) => Promise<any>;
  edit: (entityKey: string, data: any, entityId: number) => Promise<any>;
  exportToExcel: (
    entityKey: string,
    params: any,
    withRelated?: [],
  ) => Promise<any>;

  returnStep: (entityKey: string, data: any, entityId: number) => Promise<any>;
  reject: (entityKey: string, data: any, entityId: number) => Promise<any>;
  takeOwnership: (
    entityKey: string,
    data: any,
    entityId: number,
  ) => Promise<any>;
  approve: (entityKey: string, data: any, entityId: number) => Promise<any>;
  moveStep: (
    entityKey: string,
    data: any,
    entityId: number,
    actionKey: string,
  ) => Promise<any>;
}

interface EntityContextState {
  state: any;
  actions: EntityContextActions;
  getEntityState: (entityKey: string, entityId?: number) => any;
  loadEntitySchema: (entityKey: string, entityId?: number) => Promise<any>;
}

export const EntityContext = createContext({} as EntityContextState);

const useEntityContext = () => useContext<EntityContextState>(EntityContext);

const EntityContextProvider = ({ children }: React.PropsWithChildren<any>) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  /*
   * ACTIONS
   */
  const resetEntityStates = useCallback(
    async (entityKey: string, entityId?: number) => {
      dispatch({
        type: ActionTypes.RESET_TABLE_STATES_REQUEST,
        payload: { entityKey, entityId },
      });
    },
    [dispatch],
  );

  const resetEntity = entityKey => {
    dispatch({
      type: ActionTypes.RESET_TABLE_DATA_REQUEST,
      payload: { entityKey },
    });
  };

  const resetEntityFormData = entityKey => {
    dispatch({
      type: ActionTypes.RESET_TABLE_FORM_DATA_REQUEST,
      payload: { entityKey },
    });
  };

  const onFailure = async (action, entityKey, ex) => {
    if (action && entityKey) {
      dispatch({ type: action, payload: { entityKey } });
    }

    await ShowToast.error(ex.error || ex.e || ex.message);

    return ex;
  };

  const getEntityState = (entityKey, entityId) =>
    state[getStateName(entityKey, entityId)];

  /*
   * loadEntitySchema
   * Return entity schema
   * params: entity key
   */
  const loadEntitySchema = useCallback(
    async (entityKey: string, entityId?: number) =>
      get(`/schema/${entityKey}${entityId ? `/${entityId}` : ''}`)
        .then(response => ({
          ...response,
          formValidation: buildSchemaValidation(response),
          draftValidation: buildSchemaValidation(response, {
            ignoreRequired: true,
          }),
        }))
        .catch(ex => {
          ShowToast.error(ex.message);
        }),
    [],
  );

  /*
   * fetchEntityHookContent
   * Return entity schema
   * params: entity key
   */
  const fetchEntityHookContent = useCallback(
    async triggerId =>
      get(`/triggers/${triggerId}`)
        .then(response => response)
        .catch(ex => {
          ShowToast.error(ex.message);
        }),
    [],
  );

  /*
   * fetchAllByEntityName
   * FetchAll: Return all entity records
   * params: entity name
   */
  const fetchAllByEntityName = useCallback(
    async (
      entityKey: string,
      limit: number,
      page: number | undefined = 0,
      sort?: string[],
      params: any | undefined = {},
      withRelated: string[] | undefined = [],
      groupBy: string[] | undefined = [],
    ) => {
      try {
        dispatch({
          type: ActionTypes.FETCH_ALL_BY_TABLE_KEY_REQUEST,
          payload: { entityKey, params },
        });

        const hasGroupBy = groupBy && groupBy.length > 0;
        const pageParam = (!hasGroupBy && `&page=${page || 0}`) || '';
        const schema = await loadEntitySchema(entityKey);
        const data = await post(
          `/${entityKey}/search?limit=${limit || 25}${pageParam}`,
          {
            $withRelated: withRelated,
            $where: {
              ...params,
              _is_deleted: false,
            },
            $sort: sort,
            $groupBy: groupBy,
            $select: schema.listView.columnDefs.map(item => item.field),
          },
        );

        dispatch({
          type: hasGroupBy
            ? ActionTypes.FETCH_ALL_GROUPED_BY_TABLE_KEY_SUCCESS
            : ActionTypes.FETCH_ALL_BY_TABLE_KEY_SUCCESS,
          payload: {
            entityKey,
            data,
            schema,
          },
        });

        return data;
      } catch (e) {
        return onFailure(ActionTypes.FETCH_TABLE_FAILURE, entityKey, e);
      }
    },
    [dispatch, loadEntitySchema],
  );

  /*
   * fetchOneByType
   * FetchOne: Return one record detail by
   * params: entity name and id
   */
  const fetchOneByType = useCallback(
    async (entityKey, entityId) => {
      dispatch({
        type: ActionTypes.FETCH_ONE_BY_TABLE_KEY_REQUEST,
        payload: { entityKey, entityId },
      });

      const [schema, data] = await Promise.all([
        loadEntitySchema(entityKey, entityId),
        entityId &&
          get(`/${entityKey}/${entityId}`).catch(e =>
            onFailure(ActionTypes.FETCH_TABLE_FAILURE, entityKey, e),
          ),
      ]);

      dispatch({
        type: ActionTypes.FETCH_ONE_BY_TABLE_KEY_SUCCESS,
        payload: {
          entityKey,
          entityId,
          data,
          schema,
        },
      });
    },
    [dispatch, loadEntitySchema],
  );

  /*
   * ENTITY VIEWS
   */
  const setEntityView = (entityKey, view) => {
    dispatch({
      type: ActionTypes.SET_TABLE_VIEW,
      payload: { entityKey, view },
    });
  };

  const insertEntityAttachment = async (entityKey, data, entityId) => {
    dispatch({
      type: ActionTypes.CREATE_TABLE_ATTACHMENT_REQUEST,
      payload: { entityKey, entityId },
    });

    return post(`/${entityKey}/${entityId}/attachment`, data, {
      'Content-Type': 'multipart/form-data',
    })
      .then(response => {
        dispatch({
          type: ActionTypes.CREATE_TABLE_ATTACHMENT_SUCCESS,
          payload: {
            entityKey,
            entityId,
            response,
          },
        });

        return response;
      })
      .catch(e =>
        onFailure(ActionTypes.CREATE_TABLE_ATTACHMENT_FAIL, entityKey, e),
      );
  };

  const deleteEntityAttachment = async (entityKey, entityId, attachmentId) => {
    dispatch({
      type: ActionTypes.DELETE_TABLE_ATTACHMENT_REQUEST,
      payload: { entityKey, entityId },
    });

    return remove(`/${entityKey}/${entityId}/attachment/${attachmentId}`)
      .then(response => {
        dispatch({
          type: ActionTypes.DELETE_TABLE_ATTACHMENT_SUCCESS,
          payload: {
            entityKey,
            entityId,
            response,
          },
        });

        return true;
      })
      .catch(e => {
        onFailure(ActionTypes.DELETE_TABLE_ATTACHMENT_FAIL, entityKey, e);
        return false;
      });
  };

  const fetchEntityAttachments = async (entityKey, entityId) => {
    dispatch({
      type: ActionTypes.FETCH_TABLE_ATTACHMENT_REQUEST,
      payload: { entityKey, entityId },
    });

    return get(`/${entityKey}/${entityId}/attachment`)
      .then(response => {
        dispatch({
          type: ActionTypes.FETCH_TABLE_ATTACHMENT_SUCCESS,
          payload: {
            entityKey,
            entityId,
            response,
          },
        });
        return response;
      })
      .catch(e => onFailure(ActionTypes.FETCH_TABLE_FAILURE, entityKey, e));
  };

  const fetchTableHistories = useCallback(
    async (entityKey, entityId) => {
      dispatch({
        type: ActionTypes.FETCH_TABLE_HISTORY_REQUEST,
        payload: { entityKey, entityId },
      });

      return get(`/${entityKey}/${entityId}/history`)
        .then(response => {
          dispatch({
            type: ActionTypes.FETCH_TABLE_HISTORY_SUCCESS,
            payload: {
              entityKey,
              entityId,
              response,
            },
          });
          return response;
        })
        .catch(e => onFailure(ActionTypes.FETCH_TABLE_FAILURE, entityKey, e));
    },
    [dispatch],
  );

  const fetchTableComments = async (entityKey, entityId) => {
    dispatch({
      type: ActionTypes.FETCH_TABLE_COMMENT_REQUEST,
      payload: { entityKey, entityId },
    });

    return get(`/${entityKey}/${entityId}/comment`)
      .then(response => {
        dispatch({
          type: ActionTypes.FETCH_TABLE_COMMENT_SUCCESS,
          payload: {
            entityKey,
            entityId,
            response,
          },
        });

        return response;
      })
      .catch(e => onFailure(ActionTypes.FETCH_TABLE_FAILURE, entityKey, e));
  };

  const insertTableComment = async (entityKey, data, entityId) => {
    dispatch({
      type: ActionTypes.CREATE_TABLE_COMMENT_REQUEST,
      payload: { entityKey, entityId },
    });

    return post(`/${entityKey}/${entityId}/comment`, data)
      .then(response => {
        dispatch({
          type: ActionTypes.CREATE_TABLE_COMMENT_SUCCESS,
          payload: {
            entityKey,
            entityId,
            response,
          },
        });

        return response;
      })
      .catch(e =>
        onFailure(ActionTypes.CREATE_TABLE_COMMENT_FAIL, entityKey, e),
      );
  };

  const insertFileUpload = async (entityKey, name, data, onUploadProgress) => {
    dispatch({
      type: ActionTypes.CREATE_TABLE_FILE_UPLOAD_REQUEST,
      payload: { entityKey },
    });
    return post(
      `/${entityKey}/storage/${name}`,
      data,
      { 'Content-Type': 'multipart/form-data' },
      onUploadProgress,
    )
      .then(response => {
        dispatch({
          type: ActionTypes.CREATE_TABLE_FILE_UPLOAD_SUCCESS,
          payload: {
            entityKey,
            response,
          },
        });
        return response;
      })
      .catch(e =>
        onFailure(ActionTypes.CREATE_TABLE_FILE_UPLOAD_FAIL, entityKey, e),
      );
  };

  /*
   * create
   * store: Create one entity
   * params: entity name, form data
   */
  const create = async (entityKey, data) => {
    dispatch({
      type: ActionTypes.CREATE_TABLE_REQUEST,
      payload: { entityKey },
    });

    return post(`/${entityKey}`, data)
      .then(response => {
        dispatch({
          type: ActionTypes.CREATE_TABLE_SUCCESS,
          payload: {
            entityKey,
            response,
          },
        });

        return response;
      })
      .catch(e => onFailure(ActionTypes.CREATE_TABLE_FAIL, entityKey, e));
  };

  /*
   * edit
   * update: Update one entity
   * params: entity name, form data, entity id
   */
  const edit = useCallback(
    async (entityKey, data, entityId) => {
      dispatch({
        type: ActionTypes.UPDATE_TABLE_REQUEST,
        payload: { entityKey, entityId },
      });

      return put(`/${entityKey}/${entityId}`, data)
        .then(response => {
          dispatch({
            type: ActionTypes.UPDATE_TABLE_SUCCESS,
            payload: {
              entityKey,
              entityId,
              response,
            },
          });

          return response;
        })
        .catch(e => onFailure(ActionTypes.UPDATE_TABLE_FAIL, entityKey, e));
    },
    [dispatch],
  );

  /*
   * remove
   * Remove one entity
   * params: entity name, entity id
   */
  const deleteEntity = useCallback(
    async (entityKey, entityId) => {
      dispatch({
        type: ActionTypes.DELETE_TABLE_REQUEST,
        payload: { entityKey },
      });

      return remove(`/${entityKey}/${entityId}`)
        .then(response => {
          dispatch({
            type: ActionTypes.DELETE_TABLE_SUCCESS,
            payload: {
              entityKey,
              entityId,
              response,
            },
          });

          return response;
        })
        .catch(e => onFailure(ActionTypes.DELETE_TABLE_FAIL, entityKey, e));
    },
    [dispatch],
  );

  /** ********* WORKFLOW FUNCTIONS ********** */

  /**
   * Move to an specific workflow step
   *
   * @param {string} entityKey entity key
   * @param {number} entityId entity id
   * @param {object} data entity form data
   */
  const moveStep = async (entityKey, data, entityId, actionKey) => {
    dispatch({
      type: ActionTypes.TABLE_NEXT_STEP_REQUEST,
      payload: { entityKey, entityId },
    });

    return post(`/workflow/${entityKey}/${entityId}/move/${actionKey}`, data)
      .then(response => {
        dispatch({
          type: ActionTypes.TABLE_NEXT_STEP_SUCCESS,
          payload: {
            entityKey,
            entityId,
            response,
          },
        });

        return response;
      })
      .catch(e => onFailure(ActionTypes.TABLE_NEXT_STEP_FAIL, entityKey, e));
  };

  /**
   * Return Step Workflow
   *
   * @param {string} entityKey entity key
   * @param {number} entityId entity id
   * @param {object} data entity form data
   */
  const returnStep = async (entityKey, data, entityId) => {
    dispatch({
      type: ActionTypes.TABLE_RETURN_STEP_REQUEST,
      payload: { entityKey, entityId },
    });

    return post(`/workflow/${entityKey}/${entityId}/return`, data)
      .then(response => {
        dispatch({
          type: ActionTypes.TABLE_RETURN_STEP_SUCCESS,
          payload: {
            entityKey,
            entityId,
            response,
          },
        });

        return response;
      })
      .catch(e => onFailure(ActionTypes.TABLE_RETURN_STEP_FAIL, entityKey, e));
  };

  /**
   * Reject Workflow
   *
   * @param {string} entityKey entity key
   * @param {number} entityId entity id
   * @param {object} data entity form data
   */
  const reject = async (entityKey, data, entityId) => {
    dispatch({
      type: ActionTypes.TABLE_REJECT_REQUEST,
      payload: { entityKey, entityId },
    });

    return post(`/workflow/${entityKey}/${entityId}/reject`, data)
      .then(response => {
        dispatch({
          type: ActionTypes.TABLE_REJECT_SUCCESS,
          payload: {
            entityKey,
            entityId,
            response,
          },
        });

        return response;
      })
      .catch(e => onFailure(ActionTypes.TABLE_REJECT_FAIL, entityKey, e));
  };

  /**
   * Take ownership Workflow
   *
   * @param {string} entityKey entity key
   * @param {number} entityId entity id
   * @param {object} data entity form data
   */
  const takeOwnership = async (entityKey, data, entityId) => {
    dispatch({
      type: ActionTypes.TABLE_TAKE_OWNERSHIP_REQUEST,
      payload: { entityKey, entityId },
    });

    return post(`/workflow/${entityKey}/${entityId}/take-ownership`, data)
      .then(response => {
        dispatch({
          type: ActionTypes.TABLE_TAKE_OWNERSHIP_SUCCESS,
          payload: {
            entityKey,
            entityId,
            response,
          },
        });

        return response;
      })
      .catch(e =>
        onFailure(ActionTypes.TABLE_TAKE_OWNERSHIP_FAIL, entityKey, e),
      );
  };

  /**
   * Approve Workflow
   *
   * @param {string} entityKey entity key
   * @param {number} entityId entity id
   * @param {object} data entity form data
   */
  const approve = async (entityKey, data, entityId) => {
    dispatch({
      type: ActionTypes.TABLE_APPROVE_REQUEST,
      payload: { entityKey, entityId },
    });

    return post(`/workflow/${entityKey}/${entityId}/approve`, data)
      .then(response => {
        dispatch({
          type: ActionTypes.TABLE_APPROVE_SUCCESS,
          payload: {
            entityKey,
            entityId,
            response,
          },
        });

        return response;
      })
      .catch(e => onFailure(ActionTypes.TABLE_APPROVE_FAIL, entityKey, e));
  };

  /** ********* END WORKFLOW FUNCTIONS ********** */

  const exportToExcel = useCallback(
    async (entityKey, params = {}, withRelated = []) => {
      try {
        dispatch({
          type: ActionTypes.EXPORT_REQUEST,
          payload: { entityKey, params },
        });

        const response = await download(
          `/${entityKey}/export?limit=${isEmpty(params) ? 10000 : ''}`,
          {
            $withRelated: withRelated,
            $where: {
              ...params,
              _is_deleted: false,
            },
          },
        );

        downloadFile(
          `${moment(new Date()).format('YYYYMMDDTHHmmss')}-${entityKey}.xlsx`,
          response,
        );

        dispatch({
          type: ActionTypes.EXPORT_SUCCESS,
          payload: {
            entityKey,
            response,
          },
        });

        return response;
      } catch (e) {
        return onFailure(ActionTypes.EXPORT_FAIL, entityKey, e);
      }
    },
    [dispatch],
  );

  const actions: EntityContextActions = {
    resetEntityStates,
    resetEntity,
    resetEntityFormData,
    fetchAllByEntityName,
    fetchOneByType,
    fetchTableTriggerContent: fetchEntityHookContent,
    setEntityView,
    fetchEntityAttachments,
    insertEntityAttachment,
    deleteEntityAttachment,
    fetchTableHistories,
    fetchTableComments,
    insertTableComment,
    insertFileUpload,
    create,
    edit,
    deleteEntity,
    exportToExcel,
    // workflow functions
    returnStep,
    reject,
    takeOwnership,
    approve,
    moveStep,
  };

  return (
    <EntityContext.Provider
      value={{ actions, state, getEntityState, loadEntitySchema }}
    >
      {children}
    </EntityContext.Provider>
  );
};

export { useEntityContext };

export default EntityContextProvider;
