import { Fragment } from 'react';
import { Card } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

import cx from 'classnames';
import { FormMode } from 'core/formMode';
import {
  ComponentInputProps,
  ComponentReferences,
} from 'sdk/models/ComponentProperties';

import CardSelect from '../FormComponents/CardSelect';
import CardTab from '../FormComponents/CardTab';
import Checkbox from '../FormComponents/Checkbox';
import DatePicker from '../FormComponents/DatePicker';
import File from '../FormComponents/File';
import Grid from '../FormComponents/Grid';
import Html from '../FormComponents/Html';
import MapCoordinate from '../FormComponents/MapCoordinate';
import MapLocation from '../FormComponents/MapLocation';
import MultiSelect from '../FormComponents/MultiSelect';
import Numeric from '../FormComponents/Numeric';
import Permission from '../FormComponents/Permission';
import RichText from '../FormComponents/RichText';
import SingleSelect from '../FormComponents/SingleSelect';
import StaticSelect from '../FormComponents/StaticSelect';
import Switch from '../FormComponents/Switch';
import Text from '../FormComponents/Text';
import TextArea from '../FormComponents/TextArea';

export const getField = (fieldType, props) => {
  const fieldTypes = {
    Text: <Text {...props} />,
    TextArea: <TextArea {...props} />,
    Numeric: <Numeric {...props} />,
    SingleSelect: <SingleSelect {...props} />,
    StaticSelect: <StaticSelect {...props} />,
    CardSelect: <CardSelect {...props} />,
    CardTab: <CardTab {...props} />,
    MultiSelect: <MultiSelect {...props} />,
    MapLocation: <MapLocation {...props} />,
    MapCoordinate: <MapCoordinate {...props} />,
    Grid: <Grid {...props} />,
    Checkbox: <Checkbox {...props} />,
    Switch: <Switch {...props} />,
    Date: <DatePicker {...props} />,
    File: <File {...props} />,
    Html: <Html {...props} />,
    Permission: <Permission {...props} />,
    RichText: <RichText {...props} />,
  };

  return (
    fieldTypes[fieldType] || (
      <div>
        <small>"{fieldType}" гэсэн өгөгдлийн төрөл олдсонгүй.</small>
      </div>
    )
  );
};

export const getFieldValue = (
  data,
  properties?: ComponentInputProps,
  references?: ComponentReferences,
) => {
  if (data) {
    switch (properties?.ui_component) {
      case 'Grid':
      case 'SingleSelect':
      case 'MultiSelect':
        return data[references?.name || ''];
      default:
        return data[properties?.key || ''];
    }
  }

  return undefined;
};

type FieldGroupPropTypes = {
  fields: any;
  layout: any;
  entityKey: string;
  data: any | undefined;
  entityId: number | undefined;
  mode: string;
};

const FormTabFields = ({
  fields,
  layout,
  data,
  entityKey,
  entityId,
  mode,
}: FieldGroupPropTypes) => {
  const {
    formState: { errors },
  } = useFormContext();

  return (
    <Card className="fieldset border-0 mb-4" id={layout.key}>
      <Card.Body className="py-0 px-0">
        <div className={cx('fieldset-body px-0 pb-2')}>
          <>
            {layout?.rows.map((rows, rowIdx) => (
              <div
                id={layout.key}
                key={`${layout.key}-row-${rowIdx}`}
                aria-labelledby={`${layout.key}-row-${rowIdx}`}
                data-parent="#fieldsets"
              >
                <div className="row">
                  {rows?.columns.map(column => {
                    const item = fields[column.fieldKey];
                    const props = {
                      ...item,
                      readOnly:
                        mode === FormMode.readonly ||
                        item?.ui_component_options?.readOnly,
                    };
                    const settings = { ...item?.ui_component_options };
                    // Түр зуур map хийнэ
                    const references = {
                      relatedTableKey:
                        item?.relationshipOptions?.related_entity,
                      name: item?.relationshipOptions?.name,
                      query: item?.relationshipOptions?.filter_where,
                      // relatedFieldKey:
                    };
                    if (column.fieldKey === 'id') {
                      return (
                        <div className="col-md col-12" key={column.fieldKey}>
                          <div className="form-group">
                            <label>Identifier</label>
                            <input
                              type="text"
                              className="form-control"
                              disabled
                              value={entityId}
                            />
                          </div>
                        </div>
                      );
                    }

                    if (!props) {
                      return (
                        <div className="col-md col-12" key={column.fieldKey}>
                          <div className="form-group text-danger">
                            "{column.fieldKey}" гэсэн талбар олдсонгүй
                          </div>
                        </div>
                      );
                    }

                    return (
                      <Fragment key={props.key}>
                        <div className="col-md col-12">
                          {getField(props.ui_component, {
                            entityKey,
                            entityId,
                            name: props.key,
                            defaultValue: getFieldValue(
                              data,
                              props,
                              references,
                            ),
                            description: props.description,
                            props,
                            settings,
                            references,
                            data,
                            mode,
                          })}
                          <span className="text-danger">
                            {errors &&
                              (errors[column.fieldKey]?.message as string)}
                          </span>
                        </div>
                      </Fragment>
                    );
                  })}
                </div>
              </div>
            ))}
          </>
        </div>
      </Card.Body>
    </Card>
  );
};

export default FormTabFields;
