import { useEffect, useState } from 'react';

import { useEntityContext } from '../../../contexts/EntityContext';
import ShowToast from '../../../utils/ShowToast';
import IronBody from '../../Layout/IronBody/IronBody';
import HistoryLoader from '../../Loaders/HistoryLoader';
import TableHistoryList from '../../TableHistoryList/TableHistoryList';

const FormTableHistory = ({
  entityKey,
  entityId,
}: {
  entityKey: string;
  entityId: number;
}) => {
  const [loading, setLoading] = useState(false);
  const {
    getEntityState,
    actions: { fetchTableHistories },
  } = useEntityContext();

  const entityState = getEntityState(entityKey, entityId);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        await fetchTableHistories(entityKey, entityId);
      } catch (error) {
        await ShowToast.error(error as string);
      }
      setLoading(false);
    };
    fetchData();
  }, [fetchTableHistories, entityKey, entityId]);

  return (
    <IronBody>
      <div className="table-responsive">
        {!loading ? (
          <TableHistoryList
            histories={entityState?.histories}
            schema={entityState?.schema}
          />
        ) : (
          <HistoryLoader />
        )}
      </div>
    </IronBody>
  );
};

export default FormTableHistory;
