import { List } from 'antd';

const DashboardList = ({
  data,
  labelField,
  valueField,
}: {
  data: any[];
  labelField: string;
  valueField: string;
}) => {
  return (
    <List
      header={<div>{labelField}</div>}
      bordered
      dataSource={data}
      renderItem={item => <List.Item>{item[valueField]}</List.Item>}
    />
  );
};

export default DashboardList;
