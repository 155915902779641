import { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import './CognitoLogin.scss';
import LoginLayout from 'components/Layout/LoginLayout/LoginLayout';

import ErrorAlert from '../../../components/ErrorAlert/ErrorAlert';
import { useAuthContext } from '../../../contexts/AuthContext';

const WindowTypes = {
  Login: 1,
  Reset: 2,
};

const CognitoLogin = () => {
  const { register, handleSubmit } = useForm();
  const [windowType, setWindowType] = useState(WindowTypes.Login);
  const [error, setError] = useState('');

  const { t } = useTranslation();

  const {
    actions: { login, reset },
    state,
  } = useAuthContext();

  const onSubmit = async (data, event) => {
    event.preventDefault();
    const { username, password } = data;

    login({ password, username });
  };

  const onResetSubmit = async (data, event) => {
    event.preventDefault();
    const { token } = state;
    const { password1, password2 } = data;
    if (password1 !== password2) {
      setError('Давтан оруулсан нууц үг ялгаатай байна!');
      return;
    }

    if (!token) {
      setError('Алдаа гарлаа дахин орлдоно уу!');
      return;
    }

    reset({ password: password1, token });
  };
  useEffect(() => {
    if (state.isResetPasswordRequired) {
      setWindowType(WindowTypes.Reset);
    }
  }, [state]);
  return (
    <LoginLayout>
      <div className="w-100">
        {windowType === WindowTypes.Login && (
          <Form
            className="form-style pt-lg-0 pt-5"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Form.Label className="h3 mb-3">
              Tsum системд тавтай морил
            </Form.Label>
            <Form.Group className="mb-4">
              <Form.Label>Имэйл:</Form.Label>
              <Form.Control
                type="text"
                id="username"
                className="form-control"
                placeholder="Имэйл хаяг"
                {...register('username', { required: true })}
              />
            </Form.Group>
            <div className="mb-5">
              <Form.Group>
                <Form.Label>Нууц үг</Form.Label>
                <Form.Control
                  type="password"
                  id="password"
                  className="form-control"
                  placeholder="password"
                  {...register('password', { required: true })}
                />
              </Form.Group>
              <div>{state?.error && <ErrorAlert error={state?.error} />}</div>
              <div>{error && <ErrorAlert error={error} />}</div>
            </div>
            <Button variant="primary" type="submit" className="w-100 mb-3">
              {t('main.signin')}
            </Button>
            <p className="m-0 letter-spacig-lg text-center">
              <a
                className="text-body"
                href="https://www.jacsys.mn"
                rel="noopener noreferrer"
                target="_blank"
              >
                {t('main.copyright')}
              </a>
            </p>
          </Form>
        )}

        {windowType === WindowTypes.Reset && (
          <Form
            className="form-style pt-lg-0 pt-5"
            onSubmit={handleSubmit(onResetSubmit)}
          >
            <Form.Label className="h3 mb-3">Нууц үг солих</Form.Label>
            <Form.Group className="mb-4">
              <Form.Label>Нууц үг</Form.Label>
              <Form.Control
                type="password"
                id="password1"
                className="form-control"
                placeholder="New password"
                {...register('password1', { required: true })}
              />
            </Form.Group>
            <div className="mb-5">
              <Form.Group>
                <Form.Label>Нууц үг давтах</Form.Label>
                <Form.Control
                  type="password"
                  className="form-control"
                  placeholder="New password"
                  {...register('password2', { required: true })}
                />
              </Form.Group>

              <div className="mb-4">
                {state?.error && <ErrorAlert error={state?.error} />}
              </div>
              <div className="mb-4">
                {error && <ErrorAlert error={error} />}
              </div>
            </div>
            <Button variant="primary" type="submit" className="w-100 mb-3">
              {t('main.signin')}
            </Button>
            <p className="m-0 letter-spacig-lg text-center">
              <a
                className="text-body"
                href="https://www.jacsys.mn"
                rel="noopener noreferrer"
                target="_blank"
              >
                {t('main.copyright')}
              </a>
            </p>
          </Form>
        )}
      </div>
    </LoginLayout>
  );
};

export default CognitoLogin;
