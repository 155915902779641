import React, { memo } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Handle, NodeProps, Position } from 'reactflow';

const StepStyles = {
  step: {
    border: '#666666',
    background: '#F5F5F5',
  },
  success: {
    border: '#82B366',
    background: '#D5E8D4',
  },
  failure: {
    border: '#B85450',
    background: '#F8CECC',
  },
};
const getStyle = data => {
  if (data.outcome === 1) return StepStyles.success;
  if (data.outcome === 2) return StepStyles.failure;
  return StepStyles.step;
};

const StepNode = ({ data }: NodeProps) => {
  const style = getStyle(data.tableData);
  const handleStyle = { minHeight: 1, minWidth: 1, height: 2, width: 2 };
  return (
    <div
      className="p-1 px-2"
      style={{
        border: `1px solid ${style.border}`,
        borderRadius: '5%',
        backgroundColor: style.background,
        fontSize: '11px',
      }}
    >
      {data?.label}
      <Handle
        type="source"
        position={Position.Left}
        id="left"
        style={{ ...handleStyle }}
      />
      <Handle
        type="source"
        position={Position.Top}
        id="top"
        style={{ ...handleStyle }}
      />
      <Handle
        type="source"
        position={Position.Right}
        id="right"
        style={{ ...handleStyle }}
      />
      <Handle
        type="source"
        position={Position.Bottom}
        id="bottom"
        style={{ ...handleStyle }}
      />
    </div>
  );
};

export default memo(StepNode);
