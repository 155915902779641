import React, { useContext } from 'react';

import { Typography } from 'antd';
import { t } from 'i18next';

import { MetadataContext } from 'contexts/MetadataContext';

import Grid from './Grid';
const { Title } = Typography;

const ViewEditor = () => {
  const { state } = useContext(MetadataContext);
  return (
    <>
      <div className="h-50">
        <Title level={5}>{t('settings.grid-settings')}</Title>
        <Grid rowData={state.tableMetadata?.listView?.columnDefs} />
      </div>
      <div className="h-50">
        <Title level={5}>{t('settings.export-settings')} (CSV)</Title>
        <Grid
          rowData={
            state.tableMetadata?.exportSettings?.columnDefs ||
            state.tableMetadata?.listView?.columnDefs
          }
        />
      </div>
    </>
  );
};

export default ViewEditor;
