import { Route, Routes, useLocation, Navigate } from 'react-router-dom';

import PropTypes from 'prop-types';

import HtmlEditor from './components/HtmlEditor/HtmlEditor';
import LayoutWithMenu from './components/Layout/LayoutWithMenu';
import { useAuthContext } from './contexts/AuthContext';
import AppDashboard from './routes/AppDashboard/AppDashboard';
import CognitoForget from './routes/Cognito/CognitoForget/CognitoForget';
import CognitoLogin from './routes/Cognito/CognitoLogin/CognitoLogin';
import Dashboard from './routes/Dashboard';
import DynamicTable from './routes/DynamicTable/DynamciTable';
import Login from './routes/Login/Login';
import MetadataPage from './routes/MetadataPage';
import Report from './routes/Report/Report';

export default function PageRoutes() {
  const { state } = useAuthContext();
  return (
    <Routes location={window.location}>
      <Route path="/auth/login" element={<Login />} />
      <Route path="/auth/email/login" element={<CognitoLogin />} />
      <Route path="/auth/email/forgot" element={<CognitoForget />} />
      <Route
        path="/"
        element={!state.authenticatedUser ? <RedirectToAuth /> : <Dashboard />}
      />
      <Route path="/html-editor" element={<HtmlEditor />} />
      <Route
        path="/apps/report"
        element={!state.authenticatedUser ? <RedirectToAuth /> : <Report />}
      />
      <Route
        path="/apps/:appKey"
        element={
          !state.authenticatedUser ? <RedirectToAuth /> : <AppDashboard />
        }
      />

      <Route
        path="/apps/:appKey/:entityKey/:operation?/:id?"
        element={
          !state.authenticatedUser ? <RedirectToAuth /> : <DynamicTable />
        }
      />

      <Route
        path="/settings/metadata"
        element={
          !state.authenticatedUser ? <RedirectToAuth /> : <MetadataPage />
        }
      />

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

function RedirectToAuth() {
  return <Navigate to="/auth/login" />;
}

function ProtectedRoute({ element, ...props }) {
  const { state } = useAuthContext();

  return (
    <Route
      {...props}
      element={!state.authenticatedUser ? <RedirectToAuth /> : element}
    ></Route>
  );
}

function NotFound() {
  const location = useLocation();

  return (
    <LayoutWithMenu>
      <h3>
        No match for <code>{location.pathname}</code>
      </h3>
    </LayoutWithMenu>
  );
}

ProtectedRoute.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
