import cx from 'classnames';

const LabelComponent = ({
  props,
  children,
  actions,
}: {
  props: {
    readOnly?: boolean;
    validation_rules?: any;
    ui_component?: string;
    key?: string;
    label?: string;
  };
  actions?: [] | any;
  children: any;
}) => (
  <div className="form-group">
    <div
      className={cx(
        'd-flex justify-content-between align-items-baseline align-middle',
      )}
      style={{ height: '2rem', flexDirection: 'row' }}
    >
      <label htmlFor={props.key}>
        {`${props.label}`}
        <span className="text-danger">
          {props.validation_rules?.required && !props?.readOnly && ' *'}
        </span>
      </label>

      {actions}
    </div>
    {children}
  </div>
);

export default LabelComponent;
