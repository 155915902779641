import { useTranslation } from 'react-i18next';

import { Popconfirm } from 'antd';
import { LegacyButtonType } from 'antd/es/button/button';

const PopConfirmation = ({
  title = 'Confirmation',
  description,
  body,
  confirmAction,
  handleClose = () => {},
  confirmType = 'primary',
}: {
  title?: string;
  description: string;
  body: JSX.Element;
  confirmAction: () => void;
  confirmType?: LegacyButtonType;
  handleClose?: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <Popconfirm
      disabled={false}
      title={title}
      description={description}
      onConfirm={() => confirmAction()}
      onCancel={handleClose}
      okType={confirmType}
      okText={t('entity.modal.confirm')}
      cancelText={t('entity.modal.cancel')}
    >
      {body}
    </Popconfirm>
  );
};
export default PopConfirmation;
