const WorkflowInfo = ({
  workflow,
}: {
  workflow: {
    ownerName?: string;
    outcome?: number;
    definition?: any;
    currentStep?: number;
  };
}) => (
  <div className="d-flex align-items-center" data-cy="workflow-info">
    {workflow && workflow.ownerName && (
      <div
        className="ml-1 text-white font-weight-lighter"
        data-cy="workflow-assigned-to"
      >
        Ажилд томилогдсон{' '}
        <strong className="ml-1 font-weight-normal">
          {workflow && workflow.ownerName}
        </strong>
      </div>
    )}
  </div>
);
export default WorkflowInfo;
