import { Card, Button } from 'react-bootstrap';

import './AppMenu.scss';

const AppMenu = ({ app }: { app: any }) => (
  <div className="app-menu">
    <Button
      as="a"
      variant="link"
      className="w-100 p-0 text-decoration-none"
      href={`/apps/${app.key}`}
    >
      <Card key={app.key} className="border-0">
        <Card.Body
          className={`d-flex flex-column position-relative lighten-${app.color}-foreground`}
          style={{
            backgroundColor: `var(--bs-light-${app.color})`,
            height: '170px',
          }}
        >
          <div className="d-flex justify-content-center">
            <div
              className={`menu-icon-background  p-3 mb-4`}
              style={{ backgroundColor: `var(--bs-${app.color}-foreground)` }}
            >
              <i
                className={`las ${app.icon} app-switcher-icon text-decoration-none`}
              />
            </div>
          </div>
          <h5
            className="text-center"
            style={{ color: `var(--bs-${app.color}-foreground)` }}
          >
            {app.name}
          </h5>
        </Card.Body>
      </Card>
    </Button>
  </div>
);

export default AppMenu;
