import { Fragment } from 'react';

import { useDialogManager } from '../../contexts/DialogManagerContext';
import FormDrawer from '../DynamicForm/FormDrawer/FormDrawer';

const DialogManager = () => {
  const { state, closeDialog } = useDialogManager();

  return state.dialogs.map(({ id, params }, index) => (
    <Fragment key={id}>
      <FormDrawer
        show
        handleClose={() => closeDialog(id)}
        entityId={params.entityId}
        entityKey={params.entityKey}
        modalLevel={index}
      />
    </Fragment>
  ));
};

export default DialogManager;
