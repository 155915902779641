import { useContext } from 'react';

import { AppContext } from 'contexts/AppContext';
import './LoginLayout.scss';
const LoginLayout = ({ children }: { children: JSX.Element }) => {
  const {
    state: { settings },
  } = useContext(AppContext);

  return (
    <div className="row m-0">
      <div className="col-lg-7 col-12 banner-side ">
        <div className="m-3 position-fixed d-flex ">
          <div>
            <img src={settings.favicon} />
          </div>
          <div className="h3 mx-2">Tsum system</div>
        </div>
        <div className="w-100 h-100 d-flex justify-content-center align-items-center">
          <img
            width={500}
            className="d-lg-block d-none"
            src="https://jacsys.s3.eu-central-1.amazonaws.com/logo/side-banner.svg"
          />
        </div>
      </div>
      <div className="d-flex p-5 align-items-center col-lg-5 col-12">
        {children}
      </div>
    </div>
  );
};

export default LoginLayout;
