import React, { createContext, useReducer, useContext } from 'react';

import { post } from '../core/fetch';
import AuthActionTypes from './state/AuthActionTypes';
import reducer, { initialState } from './state/AuthState';

interface AuthContextActions {
  state: any;
  actions: {
    logout: () => Promise<void>;
    login: (formData) => Promise<void>;
    reset: (formData) => Promise<void>;
  };
}

export const AuthContext = createContext<AuthContextActions>({
  state: undefined,
  actions: {
    logout: () => Promise.reject(),
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    login: _formData => Promise.reject(),
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    reset: _formData => Promise.reject(),
  },
});

const useAuthContext = () => useContext(AuthContext);

const AuthContextProvider = ({ children }: React.PropsWithChildren<any>) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  /*
   * ACTIONS
   */
  const logout = async () => {
    dispatch({ type: AuthActionTypes.Logout });

    try {
      await post(`/auth/logout`, {});
      dispatch({ type: AuthActionTypes.LogoutSuccess });
      window.location.href = '/';
    } catch (e) {
      dispatch({ type: AuthActionTypes.LogoutFail });
    }
  };

  const login = async formData => {
    dispatch({ type: AuthActionTypes.Login });
    return post(`/auth/email/login`, formData)
      .then(response => {
        if (response.isResetPasswordRequired) {
          dispatch({
            type: AuthActionTypes.LoginWithNewPassword,
            token: response.token,
          });
          return response;
        }
        dispatch({ type: AuthActionTypes.LoginSuccess });
        window.location.href = `/`;
        return response;
      })
      .catch(e => {
        dispatch({
          type: AuthActionTypes.LoginFail,
          error: e.error,
        });
        return e;
      });
  };

  const reset = async formData => {
    dispatch({ type: AuthActionTypes.ResetPassword });
    return post(`/auth/email/reset`, formData)
      .then(response => {
        const { username, password } = response;
        dispatch({ type: AuthActionTypes.ResetPasswordSuccess });
        login({ username, password });
        return response;
      })
      .catch(e => {
        dispatch({ type: AuthActionTypes.ResetPasswordFail, error: e.error });
        return e;
      });
  };

  const actions = { logout, login, reset };

  return (
    <AuthContext.Provider
      value={{
        actions,
        state,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { useAuthContext };
export default AuthContextProvider;
