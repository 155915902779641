import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.scss';
import './utils/i18n';
import App from './App';
import AppContextProvider from './contexts/AppContext';
import AuthContextProvider from './contexts/AuthContext';
import DialogManagerContextProvider from './contexts/DialogManagerContext';
import EntityContextProvider from './contexts/EntityContext';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <AuthContextProvider>
    <AppContextProvider>
      <EntityContextProvider>
        <DialogManagerContextProvider>
          <App />
        </DialogManagerContextProvider>
      </EntityContextProvider>
    </AppContextProvider>
  </AuthContextProvider>,
);
