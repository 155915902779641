const Overlay = ({
  className,
  zIndex,
}: {
  className: string;
  zIndex?: string | number;
}) => (
  <div
    className={`${className} position-fixed left-0 top-0 w-100 h-100 opacity-50`}
    style={{ zIndex: `${zIndex}` }}
  />
);

export default Overlay;
