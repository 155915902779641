import cx from 'classnames';
import PropTypes from 'prop-types';

const WeeklyItemRenderer = ({ item, onItemClick }) => (
  <button
    type="button"
    className={cx('rct-item-content rounded', 'mb-2 p-2 w-100 border-0')}
    style={{ backgroundColor: `var(--bs-${item.color})` }}
    key={item.id}
    onClick={() => onItemClick(item.id)}
  >
    <div className="normal-text text-dark font-weight-bold text-left fw-bold">
      {item.title}
    </div>
    <div className="normal-text text-dark text-left">{item.description}</div>
    <div className="d-flex pt-2">
      <div className="user-name-circle">{item.user_shortname}</div>
      <div className="ms-auto">{item.value}</div>
    </div>
  </button>
);

WeeklyItemRenderer.propTypes = {
  item: PropTypes.object.isRequired,
  onItemClick: PropTypes.func.isRequired,
};

export default WeeklyItemRenderer;
