import i18n from 'i18next';
import Joi from 'joi';
import { each, isObject, uniq } from 'lodash';

const getPropertyRecursive = (obj, property) => {
  let values: Array<any> = [];
  each(obj, (value, key) => {
    if (key === property) {
      values.push(value);
    } else if (isObject(value)) {
      values = values.concat(getPropertyRecursive(value, property));
    }
  });

  return values;
};

const buildSchemaValidation = (
  entitySchema,
  options = { ignoreRequired: false },
) => {
  // const { t } = useTranslation('translations');

  const fieldsInTheForm = entitySchema.formView.formTabs.reduce(
    (prev, fieldset) => {
      const fieldKeys = getPropertyRecursive(fieldset, 'fieldKey');

      return uniq([...prev, ...fieldKeys]);
    },
    [],
  );
  // for Card tab fields
  const cardTabOptionFields: any[] = [];
  for (const key in entitySchema.fields) {
    if (entitySchema.fields[key].ui_component === 'CardTab') {
      entitySchema.fields[key].ui_component_options.options.map(option => {
        option.items.map(item => {
          item.map(i => {
            cardTabOptionFields.push(i.fieldKey);
          });
        });
      });
    }
  }
  const fieldsWithCardTab = fieldsInTheForm.concat(cardTabOptionFields);

  const schemaValidation = Object.keys(entitySchema.fields)
    .filter(itemKey => fieldsWithCardTab.indexOf(itemKey) !== -1)
    .reduce((prevValidation, fieldKey) => {
      const field = entitySchema.fields[fieldKey];

      let joi: any = Joi;
      switch (field.ui_component) {
        case 'Checkbox':
          joi = joi.boolean();
          break;
        case 'Switch':
          joi = joi.boolean();
          break;
        case 'Numeric': {
          const precision = field.ui_component_options?.precision;

          if (precision) {
            joi = joi.number().precision(precision);
          } else {
            joi = joi.number().integer();
          }

          joi = joi.max(9999999999999);
          break;
        }
        case 'MultiSelect':
          joi = Joi.array().items(
            Joi.object({
              label: Joi.any().optional(),
              value: Joi.number(),
            }),
          );
          break;

        case 'Date':
          joi = joi.date();
          break;
        case 'RichText':
          {
            const { language } = field.ui_component_options;

            if (language === 'json') {
              joi = Joi.custom(value => {
                try {
                  JSON.parse(value);
                  return value;
                } catch (e) {
                  throw new Error('json format is invalid');
                }
              });
            } else {
              joi = Joi.string();
            }
          }
          break;
        case 'SingleSelect':
          joi = Joi.object({
            label: Joi.any().optional(),
            value: Joi.number(),
          });
          break;
        case 'StaticSelect':
          joi = Joi.object({
            label: Joi.string(),
            value: Joi.string(),
          });
          break;
        case 'Grid':
          joi = Joi.any();
          break;
        case 'Permission':
          joi = Joi.object();
          break;
        default:
          joi = joi.string();
      }

      joi = Object.keys(field.validation_rules).reduce((currJoi, currKey) => {
        const evalRule = field.validation_rules[currKey];
        const messages = {
          'any.empty': i18n.t('entity.validation.required'),
          'any.required': i18n.t('entity.validation.required'),
          'string.empty': i18n.t('entity.validation.required'),
          'string.required': i18n.t('entity.validation.required'),
          'string.min': `${i18n.t('entity.validation.min-len')}{#limit}`,
          'string.max': `${i18n.t('entity.validation.max-len')}{#limit}`,
          'number.empty': i18n.t('entity.validation.required'),
          'number.min': `${i18n.t('entity.validation.min')}{#limit}`,
          'number.max': `${i18n.t('entity.validation.max')}{#limit}`,
          'number.integer': i18n.t('entity.validation.integer'),
          'number.base': i18n.t('entity.validation.number'),
          'string.pattern.name': i18n.t('entity.validation.pattern'),
          'string.pattern.base': i18n.t('entity.validation.pattern'),
          'string.pattern.invert.base': i18n.t('entity.validation.pattern'),
          'string.pattern.invert.name': i18n.t('entity.validation.pattern'),
        };

        switch (currKey) {
          case 'required':
            return evalRule && !options.ignoreRequired
              ? currJoi.required().messages(messages)
              : currJoi.allow('').allow(null).optional();
          case 'min':
          case 'minLength':
            return currJoi.min(evalRule).messages(messages);
          case 'max':
          case 'maxLength':
            return currJoi.max(evalRule).messages(messages);
          case 'pattern':
            return currJoi.pattern(RegExp(evalRule)).messages(messages);
          default:
            throw new Error(
              `Validation type '${currKey}' not handled by the validator`,
            );
        }
      }, joi);

      joi.error(errors =>
        errors.map(error => ({ message: `first msg${error}` })),
      );
      return {
        ...prevValidation,
        [fieldKey]: joi,
      };
    }, {});
  return Joi.object(schemaValidation);
};

export { buildSchemaValidation, getPropertyRecursive };
