import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { BsPlusCircle, BsPencilSquare, BsEye } from 'react-icons/bs';
import ReactSelect from 'react-select';

import cx from 'classnames';
import { post, get } from 'core/fetch';
import { ComponentInputProps } from 'sdk/models/ComponentProperties';
import { OptionItem } from 'sdk/models/OptionItem';
import { useDebounce } from 'use-debounce';
import { getPropValue } from 'utils/formUtils';

import { useDialogManager } from 'contexts/DialogManagerContext';

import dropDownStyle from '../../ComponentStyle/DropDownStyle';
import LabelComponent from './Label';

const parseVariables = (filterWhere, formData) => {
  if (!filterWhere) return null;

  if (!formData) return filterWhere;

  let query = JSON.stringify(filterWhere);

  const regexp = RegExp(/":[a-z0-9_]{2,}"/g);

  const variables = query.match(regexp);

  if (!variables?.length) return filterWhere;

  variables.forEach(item => {
    const key = item.substring(2, item.length - 1);
    const value = formData[key] || '';

    if (typeof value === 'string') {
      query = query.replace(item, `"${value}"`);
    } else {
      query = query.replace(item, value);
    }
  });

  return JSON.parse(query);
};

const SingleSelect = ({
  name,
  defaultValue,
  props = { readOnly: false },
  settings = {
    limit: 30,
    data: {},
    options: [],
  },
  references,
}: ComponentInputProps) => {
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<Array<OptionItem>>([]);
  const { t } = useTranslation();

  const { limit, options: propOptions, label, condition, value } = settings;
  const componentOptionLabel = label || 'name';
  const componentOptionValue = value || 'id';

  const {
    control,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useFormContext();
  const { getDialog, openDialog } = useDialogManager();

  const dialog = getDialog();
  const foreignKey = dialog?.params?.foreignKey;
  const { readOnly, placeholder } = props;
  const { query, relatedTableKey } = references || {};

  const filterWhere = parseVariables(query, settings.data);

  // Fetch to populate the dropdown with related entities
  const fetchRelatedOptions = async (
    searchText?: string,
    fieldToQuery?: any,
  ) => {
    if (!relatedTableKey) return;
    setLoading(true);

    try {
      const items = await post(`/${relatedTableKey}/search?limit=${limit}`, {
        $where: {
          ...(condition && watch(condition)
            ? { [condition]: watch(condition).value }
            : {}),
          ...(filterWhere ?? {}),
          ...(searchText
            ? { [fieldToQuery]: { $like: `%${searchText}%` } }
            : {}),
          _is_deleted: false,
        },
      });

      setOptions(
        items.map(item => ({
          value: item[componentOptionValue],
          label:
            getPropValue(componentOptionLabel, item) ||
            t('entity.filter.blank'),
        })),
      );

      setLoading(false);
    } catch (err) {
      throw new Error(err as string);
    }
  };

  const fetchSelectedOption = async (
    entity?: string,
    value?: number,
    setValueOptions?: any,
  ) => {
    const response = await get(`/${entity}/${value}`);

    if (!response) return;

    setValue(
      name,
      {
        value: response[componentOptionValue],
        label: response[componentOptionLabel],
      },
      setValueOptions,
    );
  };

  const isDisabled = () => {
    if (readOnly) return true;
    if (condition && !watch(condition)) return true;
    return false;
  };

  useEffect(() => {
    if (condition && !watch(condition)) {
      setValue(name, undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [condition && watch(condition)]);
  useEffect(() => {
    if (propOptions) {
      setOptions(
        propOptions.map(prop => ({
          label: getPropValue(componentOptionLabel, prop),
          value: prop[componentOptionValue],
        })),
      );
    }

    if (foreignKey && foreignKey.fieldName === name) {
      fetchSelectedOption(relatedTableKey, foreignKey.fieldValue, {
        shouldDirty: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [inputText, setInputText] = useState();
  const [searchText] = useDebounce(inputText, 500);

  const transformedDefaultValue = defaultValue
    ? {
        value: defaultValue[componentOptionValue],
        label: defaultValue[componentOptionLabel],
      }
    : undefined;

  useEffect(() => {
    if (searchText || searchText === '') {
      fetchRelatedOptions(searchText, componentOptionLabel);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  const handleInputChange = inputValue => {
    setInputText(inputValue);
  };

  const selectedValue = getValues(name);

  return (
    <LabelComponent
      props={props}
      actions={
        <div>
          {selectedValue?.value && (
            <button
              type="button"
              className="btn btn-link mr-1 btn-sm"
              data-cy="edit-related"
              onClick={() => {
                const entityKey = relatedTableKey;
                const entityId = parseInt(selectedValue.value, 10);

                openDialog({
                  entityKey,
                  entityId,
                  afterSaveCallback: () =>
                    fetchSelectedOption(entityKey, entityId),
                });
              }}
            >
              {readOnly && <BsEye title={t('entity.buttons.view-record')} />}
              {!readOnly && (
                <BsPencilSquare title={t('entity.buttons.edit-record')} />
              )}
            </button>
          )}
          {!readOnly && (
            <button
              type="button"
              data-cy="single-drop-down-create-new"
              className="btn btn-link btn-sm"
              onClick={() => {
                openDialog({
                  entityKey: relatedTableKey,
                });
              }}
            >
              <BsPlusCircle title={t('entity.buttons.create-record')} />
            </button>
          )}
        </div>
      }
    >
      <Controller
        control={control}
        name={name}
        defaultValue={transformedDefaultValue}
        render={({ field }) => (
          <ReactSelect
            {...field}
            styles={{
              // control: (provided, state) => ({
              //   ...provided,
              //   minHeight: dropDownStyle.minHeight,
              //   borderRadius: 0,
              //   boxShadow: state.isFocused && dropDownStyle.focusedBoxShadow,
              //   borderColor: errors[name]
              //     ? dropDownStyle.errorBorderColor
              //     : (state.isFocused && dropDownStyle.focusedBorderColor) ||
              //       dropDownStyle.borderColor,
              //   '&:hover': {
              //     borderColor: dropDownStyle.hoverBorderColor,
              //   },
              // }),
              singleValue: provided => ({
                ...provided,
                ...dropDownStyle.textStyle,
              }),
              placeholder: provided => ({
                ...provided,
                ...dropDownStyle.textStyle,
              }),
              option: (provided, state) => ({
                ...provided,
                ...dropDownStyle.textStyle,
                color: state.isSelected
                  ? dropDownStyle.selectedColor
                  : dropDownStyle.color,
              }),
              menuPortal: base => ({ ...base, zIndex: 9999 }),
            }}
            className={cx('single-dropdown', {
              'is-invalid': errors[name],
            })}
            id={name}
            theme={theme => ({
              ...theme,
              colors: {
                ...theme.colors,
                ...dropDownStyle.themeColors,
              },
            })}
            isDisabled={foreignKey?.fieldName === name || isDisabled()}
            onInputChange={handleInputChange}
            isClearable
            onMenuOpen={() => {
              if (!inputText) {
                fetchRelatedOptions();
              }
            }}
            menuPortalTarget={document.body}
            placeholder={placeholder}
            noOptionsMessage={() =>
              loading ? t('main.loading') : t('entity.control.no-option')
            }
            options={options}
            closeMenuOnSelect={true}
          />
        )}
      />

      {props.description && (
        <small className="form-text text-body-tertiary">
          {props.description}
        </small>
      )}
    </LabelComponent>
  );
};

export default SingleSelect;
