import { useEffect, useState, useContext, createRef } from 'react';
import DatePicker from 'react-datepicker';
import { useFormContext, Controller } from 'react-hook-form';

import cx from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import { normaliseFormat } from 'utils/dateUtils';

import { AppContext } from 'contexts/AppContext';

import s from './DateRangePicker.module.scss';

const DateRangePicker = ({ name, value, uiComponentOptions }) => {
  const {
    formState: { errors },
    control,
    setValue,
  } = useFormContext();
  const {
    state: { settings },
  } = useContext(AppContext);

  const dateRef = Array(2)
    .fill(0)
    .map(() => createRef<any>());
  const [startDate, setStartDate] = useState(moment().add(-7, 'days').toDate());
  const [endDate, setEndDate] = useState(new Date());

  const time = uiComponentOptions?.time;
  const dateFormat = normaliseFormat(
    settings.dateFormat,
    time && settings.timeFormat,
    { moment: false },
  );

  useEffect(() => {
    setValue(name, [moment(startDate).toDate(), moment(endDate).toDate()]);
  }, [name, startDate, endDate, setValue]);

  return (
    <>
      <div className={cx(s.datepicker, 'date-picker-container')}>
        <Controller
          control={control}
          name={`${name}[0]`}
          defaultValue={moment.utc(value || startDate).toDate()}
          render={({ field }) => (
            <DatePicker
              {...field}
              withPortal
              onBlur={field.onBlur}
              className={cx('form-control', {
                'is-invalid': errors[name],
              })}
              dateFormat={dateFormat}
              timeFormat={settings.timeFormat}
              todayButton="Today"
              showTimeInput={time}
              ref={dateRef[0]}
              selected={startDate}
              onChange={date => {
                setStartDate(date);
                field.onChange(date);
              }}
              selectsStart
              startDate={startDate}
              endDate={endDate}
            />
          )}
        />

        <span
          className="form-inline d-flex justify-content-center align-items-center date-picker-icon"
          onClick={() => dateRef[0].current.setFocus()}
          onKeyPress={() => {}}
          role="button"
          tabIndex={0}
        >
          <i className="far fa-calendar-alt" />
        </span>
      </div>

      <div className={cx(s.datepicker, 'date-picker-container mt-1')}>
        <Controller
          control={control}
          name={`${name}[1]`}
          defaultValue={moment.utc(value || endDate).toDate()}
          render={({ field }) => (
            <DatePicker
              {...field}
              withPortal
              onBlur={field.onBlur}
              className={cx('form-control', {
                'is-invalid': errors[name],
              })}
              dateFormat={dateFormat}
              timeFormat={settings.timeFormat}
              todayButton="Today"
              showTimeInput={time}
              ref={dateRef[1]}
              selected={endDate}
              onChange={date => {
                setEndDate(date);
                field.onChange(date);
              }}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
            />
          )}
        />

        <span
          className="form-inline d-flex justify-content-center align-items-center date-picker-icon"
          onClick={() => dateRef[1].current.setFocus()}
          onKeyPress={() => {}}
          role="button"
          tabIndex={0}
        >
          <i className="far fa-calendar-alt" />
        </span>
      </div>
    </>
  );
};

DateRangePicker.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.any,
  uiComponentOptions: PropTypes.shape({
    time: PropTypes.bool,
  }),
};

DateRangePicker.defaultProps = {
  value: null,
  uiComponentOptions: undefined,
};

export default DateRangePicker;
