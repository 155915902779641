import { isArray } from 'lodash';
// transform { bar: 'a', foo: 'b' } into 'bar=a&foo=b'
const serializeQuery = json =>
  Object.keys(json)
    .filter(
      key =>
        (!isArray(json[key]) && json[key]) ||
        (isArray(json[key]) && json[key].length > 0),
    )
    .map(key => `${key}=${encodeURIComponent(json[key])}`)
    .join('&');

const deserializeQuery = query => {
  if (!query) {
    return null;
  }

  let q = decodeURI(query);
  if (q.indexOf('?') === 0) {
    q = q.slice(1);
  }
  const pairs = q.split('&');
  const result = {};
  pairs.forEach(pair => {
    const keyValue = pair.split('=');
    result[keyValue[0]] = decodeURIComponent(keyValue[1] || '');
  });
  return result;
};

export { serializeQuery, deserializeQuery };
