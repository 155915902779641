import { useState, useEffect } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { BsPlusCircle } from 'react-icons/bs';
import ReactSelect from 'react-select';

import { post } from 'core/fetch';
import { ComponentInputProps } from 'sdk/models/ComponentProperties';
import { OptionItem } from 'sdk/models/OptionItem';
import { useDebounce } from 'use-debounce';
import { getPropValue } from 'utils/formUtils';

import { useDialogManager } from 'contexts/DialogManagerContext';

import dropDownStyle from '../../ComponentStyle/DropDownStyle';
import LabelComponent from './Label';

const MultiSelect = ({
  name,
  defaultValue = [],
  props,
  settings = {
    options: [],
    limit: 30,
  },
  references,
}: ComponentInputProps) => {
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<Array<OptionItem>>([]);
  const {
    control,
    // formState: { errors },
  } = useFormContext();
  const { openDialog } = useDialogManager();
  const { t } = useTranslation();

  const { options: propOptions, limit } = settings;

  const componentOptionValue = settings.value || 'id';
  const componentOptionLabel = settings.label || 'name';
  const placeholder = props?.placeholder;
  const { relatedTableKey, query: filterWhere } = references || {};

  const fetchRelatedOptions = async ({
    searchText,
    fieldToQuery,
  }: {
    searchText?: string;
    fieldToQuery?: any;
  }) => {
    if (!relatedTableKey) return;
    setLoading(true);

    try {
      const items = await post(`/${relatedTableKey}/search?limit=${limit}`, {
        $where: {
          ...(filterWhere ?? {}),
          ...(searchText
            ? { [fieldToQuery]: { $like: `%${searchText}%` } }
            : {}),
          _is_deleted: false,
        },
      });

      setOptions(
        items.map(item => ({
          value: item[componentOptionValue],
          label: getPropValue(componentOptionLabel, item) || '(Blank)',
        })),
      );
      setLoading(false);
    } catch (err) {
      throw new Error(err as string);
    }
  };

  useEffect(() => {
    if (propOptions) {
      setOptions(
        propOptions.map(prop => ({
          label: getPropValue(componentOptionLabel, prop),
          value: prop[componentOptionValue],
        })),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const transformedDefaultValue =
    defaultValue && defaultValue.length
      ? defaultValue.map(prop => ({
          value: prop[componentOptionValue],
          label: getPropValue(componentOptionLabel, prop),
        }))
      : undefined;

  const [inputText, setInputText] = useState();
  const [searchText] = useDebounce(inputText, 500);

  useEffect(() => {
    if (searchText || searchText === '') {
      fetchRelatedOptions({ searchText, fieldToQuery: componentOptionLabel });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  const handleInputChange = inputValue => {
    setInputText(inputValue);
  };

  return (
    <LabelComponent
      props={props}
      actions={
        <button
          type="button"
          className="btn btn-link btn-sm"
          onClick={() => {
            openDialog({
              entityKey: relatedTableKey,
            });
          }}
        >
          <BsPlusCircle title={t('entity.buttons.create-record')} />
        </button>
      }
    >
      <Controller
        render={({ field }) => (
          <ReactSelect
            {...field}
            className="multi-dropdown"
            styles={{
              // control: (provided, state) => ({
              //   ...provided,
              //   minHeight: dropDownStyle.minHeight,
              //   marginTop: dropDownStyle.marginTop,
              //   boxShadow: state.isFocused && dropDownStyle.focusedBoxShadow,
              //   borderRadius: 0,
              //   borderColor: errors[name]
              //     ? dropDownStyle.errorBorderColor
              //     : (state.isFocused && dropDownStyle.focusedBorderColor) ||
              //       dropDownStyle.borderColor,
              //   '&:hover': {
              //     borderColor: dropDownStyle.hoverBorderColor,
              //   },
              // }),
              singleValue: provided => ({
                ...provided,
                ...dropDownStyle.textStyle,
              }),
              multiValue: provided => ({
                ...provided,
                ...dropDownStyle.textStyle,
                backgroundColor: dropDownStyle.multiValueBackgroundColor,
              }),
              option: provided => ({
                ...provided,
                ...dropDownStyle.textStyle,
              }),
              placeholder: provided => ({
                ...provided,
                ...dropDownStyle.textStyle,
              }),
              menuPortal: base => ({ ...base, zIndex: 9999 }),
            }}
            theme={theme => ({
              ...theme,
              colors: {
                ...theme.colors,
                ...dropDownStyle.themeColors,
              },
            })}
            onInputChange={handleInputChange}
            onMenuOpen={() => {
              if (!inputText) {
                fetchRelatedOptions({});
              }
            }}
            menuPortalTarget={document.body}
            placeholder={placeholder}
            noOptionsMessage={() =>
              loading ? t('main.loading') : t('entity.control.no-option')
            }
            options={options}
            closeMenuOnSelect={false}
            isMulti
          />
        )}
        control={control}
        defaultValue={transformedDefaultValue}
        name={name}
      />

      {props.description && (
        <small className="form-text text-body-tertiary">
          {props.description}
        </small>
      )}
    </LabelComponent>
  );
};

export default MultiSelect;
