import { useFormContext } from 'react-hook-form';

import cx from 'classnames';
import { ComponentInputProps } from 'sdk/models/ComponentProperties';

import LabelComponent from './Label';

const TextArea = ({
  name,
  defaultValue = '',
  props = { readOnly: false, placeholder: '' },
  settings = { text: false },
}: ComponentInputProps) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const { placeholder, readOnly } = props;
  const { text } = settings;
  const inputProps = {
    ...register(name),
    id: name,
    defaultValue,
    placeholder,
    readOnly,
    className: cx('form-control', {
      'is-invalid': errors[name],
    }),
  };
  return (
    <LabelComponent props={props}>
      <textarea
        rows={4}
        {...inputProps}
        {...(!text ? { maxLength: 255 } : {})}
      />
      {props.description && (
        <small className="form-text text-body-tertiary">
          {props.description}
        </small>
      )}
    </LabelComponent>
  );
};

export default TextArea;
