import { Dropdown, Button, Navbar, NavItem, NavLink } from 'react-bootstrap';
import { MdSettingsInputComponent } from 'react-icons/md';
import { RiShutDownLine } from 'react-icons/ri';
import { Link, useLocation } from 'react-router-dom';

import cx from 'classnames';
import PropTypes from 'prop-types';
import './Nav.scss';

const getEntityLink = (appKey, item) => {
  const view = item.view ? `?view=${item.view}` : '';

  const entityOrCustomPage = item.entityKey
    ? `${item.entityKey}${view}`
    : `pages/${item.page}`;

  return `/apps/${appKey}/${entityOrCustomPage}`;
};

function MobileActionButton({ onClick, children }) {
  return (
    <Button
      variant="primary"
      className="d-flex align-items-center justify-content-between p-3 mx-3 my-2 font-weight-bold"
      onClick={e => {
        e.preventDefault();
        onClick();
      }}
    >
      {children}
    </Button>
  );
}
MobileActionButton.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
MobileActionButton.defaultProps = {
  onClick: () => {},
};

const Nav = ({
  isAdmin,
  loading,
  selectedApp,
  menuItems,
  onLogoutClick,
  onSettingSchemaClick,
  onTap,
}: {
  apps: any[];
  isAdmin: boolean | false;
  loading?: boolean;
  menuItems: any[] | [];
  menuLayout?: string;
  instanceIcon: string | '';
  instanceName: string | '';
  onAppClick: (app: any) => void;
  onLogoutClick: () => void;
  onSettingSchemaClick: () => void;
  selectedApp?: {
    key: string;
    icon: string;
    color: string;
    name: string;
  };
  onTap: () => void;
}) => {
  const location = useLocation();
  return (
    <div className="nav-header px-3 px-lg-4">
      <Navbar
        className={cx(
          'main-navbar d-flex justify-content-between flex-nowrap',
          {
            'bg-gray-x-light': loading,
          },
        )}
        expand="lg"
      >
        {loading || (
          <div className="navbar-items ">
            <div
              className="d-flex  align-items-center flex-nowrap"
              id="basic-navbar-nav"
            >
              {location.pathname !== '/' && (
                <div onClick={onTap}>
                  <i className="las la-bars" />
                </div>
              )}

              {menuItems?.map(dropdown => (
                <Dropdown
                  key={dropdown.key}
                  as={NavItem}
                  className="d-none d-md-block"
                >
                  {dropdown.entityKey ? (
                    <Link
                      className="nav-dropdown p-2"
                      to={getEntityLink(selectedApp?.key, dropdown)}
                      data-cy="menu-item"
                    >
                      {dropdown.label}
                    </Link>
                  ) : (
                    <>
                      <Dropdown.Toggle
                        as={NavLink}
                        className="nav-dropdown"
                        data-cy="menu-item-dropdown"
                      >
                        {dropdown.label}
                        <i className="fas fa-chevron-down nav-dropdown-toggle-icon" />
                      </Dropdown.Toggle>
                      <DropdownList
                        appKey={selectedApp?.key}
                        optionsList={dropdown.item_groups}
                      />
                    </>
                  )}
                </Dropdown>
              ))}
            </div>
          </div>
        )}
        <div className="d-flex align-items-center ">
          {isAdmin && (
            <a
              href="!#"
              className="mr-4 d-block  btn btn-link"
              onClick={e => {
                e.preventDefault();
                onSettingSchemaClick();
              }}
            >
              <MdSettingsInputComponent />
            </a>
          )}

          <a
            href="!#"
            className="business-name ml-4 d-block font-weight-normal"
            onClick={e => {
              e.preventDefault();
              onLogoutClick();
            }}
          >
            <RiShutDownLine size={24} />
          </a>
        </div>
      </Navbar>
    </div>
  );
};

export default Nav;
const DropdownList = ({ optionsList, appKey }) => (
  <Dropdown.Menu>
    {optionsList?.length > 0 &&
      optionsList?.map(option =>
        option.entityKey ? (
          <Dropdown.Item
            bsPrefix="dropdown-container lg"
            as="div"
            key={option.key}
          >
            <Link to={`/apps/${appKey}/${option.entityKey}`}>
              <div className="dropdown-subheading ">
                <i className="far fa-file" />
                <p className="dropdown-subheading-label">{option.label}</p>
              </div>
            </Link>
          </Dropdown.Item>
        ) : (
          <div className="dropdown-group" key={option.key}>
            {option.label && (
              <Dropdown.Header className="dropdown-subheading">
                <i className={!option.icon ? 'far fa-file' : option.icon} />
                <p className="dropdown-subheading-label">{option.label}</p>
              </Dropdown.Header>
            )}
            {option.items.map(item => (
              <Dropdown.Item
                bsPrefix="dropdown-container lg"
                as="div"
                key={item.key}
              >
                <Link to={getEntityLink(appKey, item)}>
                  <div className="icon-background">
                    <i className={`las ${item.icon} menu-icon`} />
                  </div>
                  <span className="mx-3">{item.label}</span>
                </Link>
              </Dropdown.Item>
            ))}
          </div>
        ),
      )}
  </Dropdown.Menu>
);

DropdownList.propTypes = {
  appKey: PropTypes.string,
  optionsList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      labelIcon: PropTypes.string,
      subItems: PropTypes.arrayOf(PropTypes.string),
    }),
  ).isRequired,
};
DropdownList.defaultProps = {
  appKey: '',
};
