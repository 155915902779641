import { useContext } from 'react';
import { MdOutlineDashboard } from 'react-icons/md';

import cx from 'classnames';

import './SidebarMenu.scss';

import { AppContext } from '../../contexts/AppContext';

interface SubMenuType {
  type: string;
  key: string;
  link: string;
  label: string;
  index?: number;
}

enum MenuType {
  GROUP,
  ITEM,
}

const SidebarMenu = ({
  expanded,
  instanceIcon,
  instanceName,
}: {
  expanded?: string;
  instanceIcon?: string;
  instanceName?: string;
}) => {
  const { state } = useContext(AppContext);
  const settingsMenus = ['settings', 'report'];

  const getSubMenus = app => {
    const subMenus: SubMenuType[] = [];
    app.menu.map((menu, menuIndex) =>
      menu.item_groups.map(group =>
        group.items.map((item, itemIndex) => {
          if (itemIndex === 0) {
            subMenus.push({
              type: 'header',
              key: `sub_menu_header_${group.key}`,
              label: menu.label,
              link: '#',
            });
          }

          subMenus.push({
            type: 'menu',
            link: `/apps/${app.key}`,
            key: `sub_menu_item_${item.key}`,
            label: item.label,
            index: itemIndex,
          });
          if (
            menuIndex === app.menu.length - 1 &&
            itemIndex === group.items.length - 1 &&
            app.dashboard
          ) {
            subMenus.push({
              type: 'dashboard',
              link: `/apps/${app.key}`,
              key: `dashboard_item_${item.key}`,
              label: 'Хянах самбар',
              index: itemIndex,
            });
          }

          return item;
        }),
      ),
    );
    return subMenus;
  };

  const menus = [
    { key: 'group-apps', type: MenuType.GROUP, name: 'APPS' },
    ...state.apps.filter(app => !settingsMenus.includes(app.key)),
    { key: 'group-settings', type: MenuType.GROUP, name: 'SETTINGS' },
    ...state.apps.filter(app => settingsMenus.includes(app.key)),
  ];

  return (
    <div
      className={cx(
        expanded === 'menu-expanded' ? 'd-flex' : 'd-md-flex d-none',
        `sidebar-menu ${expanded} flex-shrink-0 widget-box`,
      )}
    >
      {!state.isLoading && (
        <div className="sidebar-links px-3">
          <a href="/" className="text-decoration-none">
            <div className={`header text-black`}>
              <img className="nav-icon" src={instanceIcon} alt={instanceName} />
              <span
                className={expanded === 'menu-collapsed' ? 'd-none' : 'm-2 h5'}
              >
                {instanceName}
              </span>
            </div>
          </a>

          <ul className="main-menu-content">
            {menus.map(app => {
              if (app.type === MenuType.GROUP) {
                return (
                  <li
                    className={`menu-group mt-4 mb-1 ${
                      expanded !== 'menu-expanded' ? 'text-center' : 'text-bold'
                    }`}
                    key={app.key}
                  >
                    {expanded !== 'menu-expanded' && '···'}
                    {expanded === 'menu-expanded' && app.name}
                  </li>
                );
              }

              return (
                <li className="main-menu" key={app.key}>
                  <a
                    href={
                      app.menu.length === 0
                        ? `/apps/${app.key}`
                        : getSubMenus(app).find(item => item.type == 'menu')
                            ?.link || '#'
                    }
                    className={`main-menu-item`}
                    data-toggle="tooltip"
                    data-placement="right"
                    title={app.name}
                  >
                    <div className="icon">
                      <i className={`las ${app.icon}`} />
                    </div>
                    <span className="link hide mx-2">{app.name}</span>
                  </a>

                  {expanded === 'menu-expanded' && (
                    <div
                      className={`sub-menu-content d-block d-md-none ${
                        app.menu.length > 0 ? 'has-menu' : ''
                      }`}
                    >
                      <ul>
                        {getSubMenus(app).map(item => (
                          <SubMenuItem
                            item={item}
                            key={`${item.key}_sub_menu_item_key`}
                          />
                        ))}
                      </ul>
                    </div>
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

export default SidebarMenu;

const SubMenuItem = ({ item }: { item: SubMenuType }) => {
  switch (item.type) {
    case 'menu':
      return (
        <li key={`${item.key}_sub_item_menu`} className="sub-menu">
          <a href={item.link} className="sub-menu-item">
            <i className="las la-circle sub-menu-item-list-style" />
            <span>{item.label}</span>
          </a>
        </li>
      );
    case 'header':
      return (
        <li key={`${item.key}_sub_item_header`}>
          <div className="sub-menu-header">{item.label}</div>
        </li>
      );
    case 'dashboard':
      return (
        <li key={`${item.key}_sub_item_dashboard`}>
          <a href={item.link} className="dashboard">
            <MdOutlineDashboard className="mr-2" />
            <span>{item.label}</span>
          </a>
        </li>
      );
    default:
      return <></>;
  }
};
