import { useEffect, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

import './FormTabs.scss';
import { UIComponents } from 'constants/fieldTypes';

import FormTabFields from './FormTabFields';

type FieldsetsPropTypes = {
  fields: any;
  formTabs: any[];
  data: any | undefined;
  entityKey: string;
  entityId: number | undefined;
  mode: string;
};

const Fieldsets = (props: FieldsetsPropTypes) => {
  const {
    formState: { errors },
  } = useFormContext();
  const [selectedTab, setSelectedTab] = useState<string | null>();

  const getFirstTabKey = () => {
    if (props.formTabs.length > 0) {
      return props.formTabs[0].key;
    }
    return 'main';
  };

  const findCardTab = (field: any, errorField: string | undefined) => {
    if (field.ui_component !== UIComponents.CardTab) return false;

    const result = field.ui_component_options.options.find(option =>
      option.items.find(item => item.find(i => i.fieldKey === errorField)),
    );
    if (!result) return false;
    return true;
  };

  useEffect(() => {
    const errorFields = Object.keys(errors);
    if (errorFields.length) {
      const firstErrorField = errorFields.shift();

      const tab = props.formTabs.find(fieldset =>
        fieldset.rows.find(row =>
          row.columns.find(
            column =>
              column.fieldKey === firstErrorField ||
              findCardTab(props.fields[column.fieldKey], firstErrorField),
          ),
        ),
      );
      if (tab) {
        setSelectedTab(tab.key);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  useEffect(() => {
    setSelectedTab(getFirstTabKey());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Tabs
      activeKey={selectedTab || ''}
      onSelect={eventKey => setSelectedTab(eventKey)}
    >
      {props.formTabs.map(fieldset => (
        <Tab
          title={fieldset.label || fieldset.key}
          key={`${fieldset.key}_tab`}
          eventKey={fieldset.key}
        >
          <FormTabFields layout={fieldset} {...props} key={fieldset.key} />
        </Tab>
      ))}
    </Tabs>
  );
};

export default Fieldsets;
