import { useContext } from 'react';
import { Alert } from 'react-bootstrap';
import { AiFillCheckCircle, AiOutlineCheckCircle } from 'react-icons/ai';
import { BsThreeDots, BsCircle } from 'react-icons/bs';
import { FaTimesCircle } from 'react-icons/fa';
import { HiDotsCircleHorizontal } from 'react-icons/hi';

import { Popover, Steps } from 'antd';
import cx from 'classnames';
import moment from 'moment';
import { normaliseFormat } from 'utils/dateUtils';

import { AppContext } from 'contexts/AppContext';

import ActionLabels from '../../constants/ActionLabels';
import { outcomeLabels } from '../../utils/worflowUtils';

const getOutcomeLabel = (group, approvedLabel, rejectedLabel) => {
  if (group.approvedStep) {
    return approvedLabel || outcomeLabels[1];
  }
  if (group.rejectedStep) {
    return rejectedLabel || outcomeLabels[2];
  }
  return group.stepName;
};

const WorkflowStepper = ({
  workflow,
  noAlert = false,
}: {
  workflow: any;
  noAlert?: boolean;
}) => {
  const {
    state: { settings },
  } = useContext(AppContext);

  const maxWorkflowView = 3;
  const lastItemIndex = workflow.historyGrouped.length - 1;

  const getIcon = (index, group) => {
    const isLastItem = index === lastItemIndex;
    const isCurrentStep = isLastItem && !group.isOutcome;

    if (!isLastItem) {
      return <AiOutlineCheckCircle color="var(--bs-success)" size={24} />;
    }
    if (isCurrentStep) {
      return <BsCircle color="#fabe24" size={24} />;
    }
    if (group.approvedStep) {
      return <AiFillCheckCircle color="var(--bs-success)" size={24} />;
    }
    if (group.rejectedStep) {
      return <FaTimesCircle color="var(--bs-danger)" size={24} />;
    }
    return <BsThreeDots color="#979797" size={24} />;
  };
  return (
    <div className="w-100 mt-3">
      {workflow.outcomeReason && !noAlert && (
        <Alert
          data-cy="alert-workflow-reason"
          style={{ borderRadius: 0 }}
          variant={cx({
            danger: workflow.outcome === 2,
            warning: !workflow.outcome,
          })}
        >
          {workflow.outcomeReason}
        </Alert>
      )}

      {!!workflow.historyGrouped?.length && (
        <>
          {workflow.historyGrouped.length <= maxWorkflowView && (
            <Steps
              current={lastItemIndex}
              items={workflow.historyGrouped.map((group, index) => {
                return {
                  icon: getIcon(index, group),
                  title: getOutcomeLabel(
                    group,
                    workflow?.definition.outcome?.success?.label,
                    workflow?.definition.outcome?.failure?.label,
                  ),
                };
              })}
            />
          )}

          {workflow.historyGrouped.length > maxWorkflowView && (
            <Steps
              current={lastItemIndex}
              items={[
                {
                  icon: getIcon(0, workflow.historyGrouped[0]),
                  title: workflow.historyGrouped[0].stepName,
                },
                {
                  icon: (
                    <HiDotsCircleHorizontal
                      size={24}
                      color="var(--bs-primary)"
                    />
                  ),
                  title: (
                    <Popover
                      trigger="click"
                      content={
                        <Steps
                          direction="vertical"
                          current={lastItemIndex}
                          items={workflow.historyGrouped.map((group, index) => {
                            return {
                              icon: getIcon(index, group),
                              title: (
                                <div>
                                  {getOutcomeLabel(
                                    group,
                                    workflow?.definition.outcome?.success
                                      ?.label,
                                    workflow?.definition.outcome?.failure
                                      ?.label,
                                  )}
                                  {!!group.items?.length &&
                                    group.items.map(historyItem => (
                                      <div
                                        key={`${historyItem.id}${historyItem.date}`}
                                      >
                                        <small className="text-muted">
                                          {moment(historyItem.date)
                                            .local()
                                            .format(
                                              normaliseFormat(
                                                settings.dateFormat,
                                                settings.timeFormat,
                                              ),
                                            )}
                                          {' - '}
                                          <span>
                                            {
                                              ActionLabels[
                                                historyItem.workflow_action
                                              ]
                                            }
                                          </span>
                                          {'  '}
                                          <span className="font-weight-bold">
                                            {historyItem.ownerName ||
                                              historyItem.created_by ||
                                              'BOT'}{' '}
                                          </span>
                                          {historyItem.outcome_reason && (
                                            <span>
                                              - {historyItem.outcome_reason}
                                            </span>
                                          )}
                                        </small>
                                      </div>
                                    ))}
                                </div>
                              ),
                            };
                          })}
                        />
                      }
                    >
                      <div style={{ cursor: 'pointer' }}>
                        {' '}
                        {workflow.historyGrouped.length - 2} Алхам{' '}
                      </div>
                    </Popover>
                  ),
                },
                {
                  icon: getIcon(
                    lastItemIndex,
                    workflow.historyGrouped[lastItemIndex],
                  ),
                  title: getOutcomeLabel(
                    workflow.historyGrouped[lastItemIndex],
                    workflow?.definition.outcome?.success?.label,
                    workflow?.definition.outcome?.failure?.label,
                  ),
                },
              ]}
            />
          )}
        </>
      )}
    </div>
  );
};

export default WorkflowStepper;
