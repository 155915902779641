import { useContext, useEffect, useState } from 'react';

import { CheckOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Space,
} from 'antd';

import TsumEmpty from 'components/TsumEmpty/TsumEmpty';

import {
  WorkflowContext,
  WorkflowContextState,
} from '../WorkflowContextProvider';

type WorkflowNode = {
  key?: string;
  name?: string;
  order?: number;
  outcome?: number;
};

export const NodeProperty: React.FC = () => {
  const {
    flowActions,
    flowState: { selectedNode },
  } = useContext<WorkflowContextState>(WorkflowContext);
  const [form] = Form.useForm();
  const [isCreate, setIsCreate] = useState<boolean>(false);

  useEffect(() => {
    if (!selectedNode) {
      form.resetFields();
      return;
    }
    setIsCreate(false);
    form.setFieldsValue(selectedNode.data.tableData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNode]);

  const onReset = () => {
    flowActions.deselectNode();
    form.resetFields();
  };

  const onSave = values => {
    if (isCreate) {
      flowActions.addNode(values);
      setIsCreate(false);
      return;
    }

    flowActions.updateNodes([
      {
        ...selectedNode,
        data: {
          ...selectedNode.data,
          tableData: { ...selectedNode.data.tableData, ...values },
        },
      },
    ]);
  };

  const onCreate = () => {
    setIsCreate(true);

    form.resetFields();
  };

  const onDelete = () => {
    flowActions.removeNode(selectedNode);
  };

  return (
    <>
      {(selectedNode || isCreate) && (
        <Card>
          <Form
            name="nodeForm"
            form={form}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            style={{ maxWidth: 600 }}
            onFinish={onSave}
            autoComplete="off"
            initialValues={{ items: [{}] }}
          >
            <Form.Item<WorkflowNode>
              label="Түлхүүр"
              name="key"
              rules={[
                {
                  required: true,
                  message: 'Алхамын түлхүүрийг оруулна уу!',
                },
              ]}
            >
              <Input disabled={!isCreate} />
            </Form.Item>
            <Form.Item<WorkflowNode>
              label="Нэр"
              name="name"
              rules={[
                { required: true, message: 'Алхамын нэрийг оруулна уу!' },
              ]}
            >
              <Input />
            </Form.Item>
            {!selectedNode?.data?.tableData?.outcome && (
              <Form.Item<WorkflowNode>
                label="Дараалал"
                name="order"
                rules={[
                  {
                    required: true,
                    message: 'Дарааллын дугаарыг оруулна уу!',
                  },
                ]}
              >
                <InputNumber />
              </Form.Item>
            )}
            {selectedNode?.data?.tableData?.outcome === 1 && (
              <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
                <span className="badge bg-success">Амжилттай төгсгөл</span>
              </Form.Item>
            )}

            {selectedNode?.data?.tableData?.outcome === 2 && (
              <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
                <span className="badge bg-danger">Амжилтгүй төгсгөл</span>
              </Form.Item>
            )}

            <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
              <Space>
                <Button htmlType="submit" icon={<CheckOutlined />}>
                  {isCreate ? 'Үүсгэх' : 'Өөрчлөх'}
                </Button>

                {!isCreate && (
                  <>
                    <Button htmlType="button" onClick={onReset}>
                      Болих
                    </Button>
                    {!selectedNode?.data?.tableData?.outcome && (
                      <Popconfirm
                        title="Үйлдэлийн алхамыг устгах"
                        description="Та энэ алхамыг устгахдаа итгэлтэй байна уу?"
                        onConfirm={onDelete}
                        okText="Тийм"
                        cancelText="Үгүй"
                      >
                        <Button htmlType="button" danger>
                          Устгах
                        </Button>
                      </Popconfirm>
                    )}
                  </>
                )}
              </Space>
            </Form.Item>
          </Form>
        </Card>
      )}

      {!selectedNode && !isCreate && (
        <TsumEmpty
          description={
            <span>
              Та алхам сонгоогүй байна. Хэрэв шинэ алхам үүсгэх бол доорх
              товчийг дарж үүсгэнэ үү
            </span>
          }
        >
          <Button icon={<PlusOutlined />} htmlType="button" onClick={onCreate}>
            Шинэ алхам
          </Button>
        </TsumEmpty>
      )}
    </>
  );
};
