import React, { useContext, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { ApiOutlined } from '@ant-design/icons';
import { Button, Select, Tabs, Tooltip } from 'antd';

import WorkflowContextProvider from 'components/Metadata/WorkflowEditor/WorkflowContextProvider';
import { MetadataFileType } from 'constants/MetadataConstants';
import { MetadataContext } from 'contexts/MetadataContext';

import DocumentEditor from './DocumentEditor/DocumentEditor';
import FormEditor from './FormEditor/FormEditor';
import TableEditor from './TableEditor/TableEditor';
import ViewEditor from './ViewEditor/ViewEditor';
import WorkflowEditor from './WorkflowEditor/WorkflowEditor';

import s from './MetadataDesigner.module.scss';

const MetadataPage = () => {
  const { t } = useTranslation();
  const {
    actions: { fetchList, selectTable, fetchJsonFile },
    state,
  } = useContext(MetadataContext);

  useEffect(() => {
    fetchList();
  }, [fetchList]);

  useEffect(() => {
    fetchJsonFile(MetadataFileType.worfkflowMetadata);
    fetchJsonFile(MetadataFileType.tableMetadata);
  }, [fetchJsonFile, state.tableKey]);

  const restApiEndpoint = `${window.location.origin}/api/api-docs/#/${state.tableKey}`;
  return (
    <div className="px-4">
      <div>
        <Tabs
          size="large"
          type="card"
          centered
          tabBarExtraContent={{
            left: (
              <Row className="pe-5">
                <Col className="d-flex flex-row align-items-center">
                  <div className="px-2">Хүснэгт:</div>
                  <div className="px-2">
                    <Select
                      placeholder="Шилжиж очих алхамаа сонгоно уу"
                      style={{ width: 250 }}
                      options={state?.metadataList.map(item => ({
                        value: item.name,
                        label: item.name,
                      }))}
                      onChange={value => selectTable(value)}
                    />
                  </div>
                  <div>
                    <Tooltip title="API Swagger">
                      <Button
                        icon={<ApiOutlined />}
                        href={restApiEndpoint}
                        target="blank"
                      />
                    </Tooltip>
                  </div>
                </Col>
              </Row>
            ),
          }}
          items={[
            {
              label: 'Талбарууд',
              key: 'table-editor',
              children: (
                <div className={s.tabContainer}>
                  <TableEditor />
                </div>
              ),
              disabled: !state.tableKey,
            },
            {
              label: t('settings.form-layout'),
              key: 'form-layout',
              children: <FormEditor className={s.tabContainer} />,
              disabled: !state.tableKey,
            },
            {
              label: 'Workflow',
              key: 'workflow-tab',
              children: (
                <div className={s.tabContainer}>
                  <WorkflowContextProvider>
                    <WorkflowEditor />
                  </WorkflowContextProvider>
                </div>
              ),
              disabled: !state.tableKey,
            },
            {
              label: t('settings.data-table'),
              key: 'data-table',
              children: (
                <div className={s.tabContainer}>
                  <ViewEditor />
                </div>
              ),
              disabled: !state.tableKey,
            },
            {
              label: 'Бичиг баримт',
              key: 'printable-docs',
              children: (
                <div className={s.tabContainer}>
                  <DocumentEditor />
                </div>
              ),
              disabled: !state.tableKey,
            },
          ]}
        />
      </div>
    </div>
  );
};
export default MetadataPage;
