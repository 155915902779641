import React from 'react';
import { useContext } from 'react';
import './CognitoForget.scss';
import { Card } from 'react-bootstrap';

import { AppContext } from '../../../contexts/AppContext';

const TSUM_API = process.env.REACT_APP_JACSYS_TSUM_API;

const CognitoForget = () => {
  const {
    state: { settings },
  } = useContext(AppContext);
  return (
    <div
      style={{
        backgroundImage: `url(${settings.loginBackgroundImage})`,
      }}
      className="Login d-flex align-items-center justify-content-center flex-column flex-grow-1 m-0 w-100"
    >
      <Card className="login-card">
        <Card.Body className="p-5">
          <img
            className="company-logo"
            src={settings.companyLogo}
            alt={settings.name}
          />
          <h4 className="my-3">{settings.name}</h4>
          <div className="px-4">
            <a
              className="btn btn-block btn-primary"
              href={`${TSUM_API}/auth/login`}
            >
              LOGIN :)
            </a>
          </div>
          <hr className="my-4" />
          <p className="m-0 letter-spacig-lg">
            POWERED BY JACSYS{' | '}
            <a
              className="text-body"
              href="https://www.tsum.de"
              rel="noopener noreferrer"
              target="_blank"
            >
              TSUM.DE
            </a>
          </p>
        </Card.Body>
      </Card>
    </div>
  );
};

export default CognitoForget;
