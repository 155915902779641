import { useFormContext } from 'react-hook-form';

import cx from 'classnames';
import { ComponentInputProps } from 'sdk/models/ComponentProperties';

import LabelComponent from './Label';

const Text = ({
  name,
  defaultValue = '',
  props = { placeholder: '', readOnly: false },
}: ComponentInputProps) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const { placeholder, readOnly, description } = props;
  const inputProps = {
    ...register(name),
    className: cx('form-control', {
      'is-invalid': errors[name],
    }),
    id: name,
    placeholder,
    readOnly,
    defaultValue,
  };
  return (
    <LabelComponent props={props}>
      <input type="text" {...inputProps} />

      {description && (
        <small className="form-text text-body-tertiary">{description}</small>
      )}
    </LabelComponent>
  );
};

export default Text;
