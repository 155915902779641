import { Controller, useFormContext } from 'react-hook-form';

import { Segmented } from 'antd';
import cx from 'classnames';
import { ComponentInputProps } from 'sdk/models/ComponentProperties';

import LabelComponent from './Label';

const CardSelect = ({
  name,
  defaultValue = '',
  props = { readOnly: false, placeholder: '' },
  settings = { options: [] },
}: ComponentInputProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const { options } = settings;
  const { readOnly, placeholder } = props;

  return (
    <LabelComponent props={props}>
      <Controller
        control={control}
        defaultValue={defaultValue || (options?.length && options[0].value)}
        name={name}
        render={({ field }) => {
          return (
            <div
              data-cy={name}
              className={cx({
                'border-danger': errors[name],
                'is-invalid': errors[name],
              })}
            >
              {options && (
                <Segmented
                  {...field}
                  disabled={readOnly}
                  placeholder={placeholder}
                  options={options?.map(option => {
                    return {
                      label: (
                        <div style={{ padding: 4 }}>
                          <img width="100" height="100" src={option.icon} />
                          <div>{option.label}</div>
                        </div>
                      ),
                      value: option.value,
                    };
                  })}
                />
              )}
            </div>
          );
        }}
      />

      {props.description && (
        <small className="form-text text-muted">{props.description}</small>
      )}
    </LabelComponent>
  );
};

export default CardSelect;
