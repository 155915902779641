const dropDownStyle = {
  textStyle: {
    fontSize: '0.9rem',
    fontWeight: 300,
    color: 'var(--bs-gray-dark)',
  },
  themeColors: {
    primary: 'var(--bs-primary)',
    primary25: 'var(--bs-primary-extra-light)',
    text: 'var(--bs-primary)',
  },
  color: 'var(--bs-gray-dark)',
  selectedColor: 'white',
  minHeight: '2.7rem',
  marginTop: '-2px',
  focusedBoxShadow: '0 0 0 0.25rem var(--bs-primary-lightest)',
  borderColor: '#ced4da',
  errorBorderColor: 'red',
  focusedBorderColor: 'var(--bs-primary-mid-light)',
  hoverBorderColor: 'var(--bs-primary-mid-light)',
  multiValueBackgroundColor: 'var(--bs-primary-extra-light)',
};

export default dropDownStyle;
