import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { VscClose } from 'react-icons/vsc';

import cx from 'classnames';

import { FILTER_CRITERIAS } from 'constants/filterCriterias';

import {
  DateRangePickerField,
  DefaultField,
  MultiSelect,
  Numeric,
  WorkflowStepField,
} from './FilterFields';
import FilterPopover from './FilterPopover';

import s from './AppliedFilter.module.scss';

type Props = {
  values: any;
  filterKey: string;
  fieldItem: any;
  workflowDefinition: any;
  removeAppliedFilter: (filterKey: string) => void;
  handleSubmit: () => void;
};

const AppliedFilter = ({
  values,
  filterKey,
  fieldItem,
  workflowDefinition,
  removeAppliedFilter,
  handleSubmit,
}: Props) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const uiComponent = fieldItem.ui_component;
  const { label } = fieldItem;
  const { t } = useTranslation();

  // Setting the default criteria, because some fields have their own structure
  // FILTER_CRITERIAS values
  const criteriaStructure =
    FILTER_CRITERIAS[uiComponent] || FILTER_CRITERIAS.default;
  const defaultCrieria = criteriaStructure[0].value;

  const name = `${filterKey}.value`;
  const fieldItemLabel = fieldItem?.label;

  const fieldComponent = {
    WorkflowStep: (
      <WorkflowStepField
        steps={workflowDefinition?.steps}
        labelForFinalised={workflowDefinition?.outcome?.success.label}
        labelForRejected={workflowDefinition?.outcome?.failure.label}
        name={name}
      />
    ),
    MultiSelect: (
      <MultiSelect
        name={name}
        uiComponentOptions={fieldItem.ui_component_options}
        relationshipOptions={fieldItem.relationshipOptions}
      />
    ),
    SingleSelect: (
      <MultiSelect
        name={name}
        uiComponentOptions={fieldItem.ui_component_options}
        relationshipOptions={fieldItem.relationshipOptions}
      />
    ),
    Checkbox: (
      <MultiSelect
        name={name}
        options={[
          {
            label: fieldItemLabel,
            value: true,
          },
          {
            label: `Not ${fieldItemLabel}`,
            value: false,
          },
        ]}
        uiComponentOptions={fieldItem.ui_component_options}
      />
    ),
    Switch: (
      <MultiSelect
        name={name}
        options={[
          {
            label: fieldItemLabel,
            value: true,
          },
          {
            label: `Not ${fieldItemLabel}`,
            value: false,
          },
        ]}
        uiComponentOptions={fieldItem.ui_component_options}
      />
    ),
    Date: (
      <DateRangePickerField
        name={name}
        uiComponentOptions={fieldItem.ui_component_options}
      />
    ),
    Numeric: <Numeric name={name} />,
    default: <DefaultField name={name} />,
  };

  return (
    <div
      className={cx(s.root, {
        [s.isInvalid]: errors[filterKey],
      })}
    >
      <FilterPopover
        className={s.appliedFilterDropdown}
        id={`applied-filter-field-${filterKey}`}
        onApply={handleSubmit}
        title={
          <button
            type="button"
            className={cx('btn btn-sm btn-light h-100', s.appliedFilter)}
            style={{ borderRight: 0 }}
          >
            <label className={s.label}>{label}:</label>{' '}
            {values || t('entity.filter.none-selected')}
          </button>
        }
      >
        <div className="form-group mb-0">
          {/* <label htmlFor={name}>{fieldItemLabel}</label> */}
          {fieldComponent[uiComponent] || fieldComponent.default}
        </div>

        <input
          type="hidden"
          {...register(`${filterKey}.operator`)}
          value={defaultCrieria}
        />

        <input
          type="hidden"
          {...register(`${filterKey}.uiComponent`)}
          value={uiComponent}
        />
      </FilterPopover>

      <button
        type="button"
        className={cx('btn btn-sm btn-light', s.removeAppliedFilter)}
        style={{ backgroundColor: 'white', borderLeft: 0, color: 'black' }}
        onClick={() => removeAppliedFilter(filterKey)}
        data-cy="remove-applied-filter"
      >
        <VscClose />
      </button>
    </div>
  );
};

AppliedFilter.defaultProps = {
  values: '',
};

export default AppliedFilter;
