import { Fragment, useEffect, useState, memo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FiFilter } from 'react-icons/fi';

import { orderBy } from 'lodash';
import {
  transformFiltersDataBeforeSubmit,
  transformAppliedFilterValues,
} from 'utils/filters';

import { UIComponents } from 'constants/fieldTypes';

import AppliedFilter from './AppliedFilter';
import FilterPopover from './FilterPopover';

import s from './Filters.module.scss';

const Filters = ({
  filterFields,
  workflowDefinition,
  submitFilter,
}: {
  filterFields: any;
  workflowDefinition?: boolean | any;
  submitFilter: (filter: any) => void;
}) => {
  // selected filters = Checkboxes items inside of add filter dropdown
  const [selectedFilters, setSelectedFilters] = useState<Array<string>>([]);
  const { t } = useTranslation();
  // applied filters = Filters that are applied and being displayed
  // at the top of the view
  const [appliedFilters, setAppliedFilters] = useState<Array<string>>([]);
  const [appliedFilterValues, setAppliedFilterValues] = useState({});
  const [displayAppliedFilters, setDisplayAppliedFilters] = useState(false);

  const formMethods = useForm();

  const fields = Object.keys(filterFields)
    .reduce(
      (current: Array<any>, keyName: string) => [
        ...current,
        filterFields[keyName],
      ],
      [],
    )
    .filter(
      field =>
        field.ui_component !== UIComponents.Grid &&
        field.ui_component !== UIComponents.File &&
        field.ui_component !== UIComponents.MapLocation,
    );

  /**
   * This function will set all 'checked' selectedFilters(checkboxes)
   * as appliedFilters
   */
  const applyFilters = () => {
    setDisplayAppliedFilters(true);
    setAppliedFilters([...selectedFilters]);
  };

  /**
   * This function should parse all the data filter
   * set the values for the filters using that parsed data
   * then submit the form.
   */
  const onSubmit = data => {
    const response = transformFiltersDataBeforeSubmit(data, filterFields);

    const formValues = formMethods.getValues();
    const values = transformAppliedFilterValues(filterFields, formValues);

    setAppliedFilterValues(values);
    submitFilter(response);
  };

  /**
   * This function will reset the filterkey value
   * @param {string} filterKey
   */
  const resetValue = filterKey => {
    switch (filterFields[filterKey].ui_component) {
      case 'Date': {
        formMethods.setValue(`${filterKey}.value[0]`, '');
        formMethods.setValue(`${filterKey}.value[1]`, '');
        break;
      }
      default: {
        formMethods.setValue(`${filterKey}.value`, '');
        break;
      }
    }
  };

  const handleSubmit = () => {
    formMethods.handleSubmit(onSubmit)();
  };

  /**
   * This function will remove the applied filter
   * AppliedFilter(dropdown) and selectedFilter(checkbox) should be reset
   * @param {string} filterKey
   */
  const removeAppliedFilter = filterKey => {
    resetValue(filterKey);
    setAppliedFilters([...appliedFilters.filter(item => item !== filterKey)]);
    setSelectedFilters([...selectedFilters.filter(item => item !== filterKey)]);

    handleSubmit();
  };

  /**
   * Effect to find fields with isDefaultFilter and auto-apply
   */
  useEffect(() => {
    const defaultFiltersKeys = (Object.values(filterFields) as any[])
      .filter(filter => filter.ui_component_options?.isDefaultFilter)
      .map(filter => filter.key);

    if (defaultFiltersKeys.length === 0) return;

    setDisplayAppliedFilters(true);
    setSelectedFilters([...defaultFiltersKeys]);
    setAppliedFilters([...defaultFiltersKeys]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={s.root}>
      <FilterPopover
        id="add-filters"
        onApply={applyFilters}
        showDrop
        className="btn btn-link"
        title={
          <span data-cy="filter-toggle">
            <FiFilter size={23} />
          </span>
        }
      >
        <div data-cy="filter-fields-dropdown">
          <label className={s.subtitle}>{t('entity.filter.pick-field')}</label>
          {orderBy(fields, 'label', 'asc').map(field => (
            <div key={field.key}>
              <input
                name={`filter-${field.key}`}
                checked={selectedFilters.includes(field.key)}
                id={`filter-${field.key}`}
                type="checkbox"
                onChange={e => {
                  const values = selectedFilters;
                  const idx = values.indexOf(e.target.value);

                  if (idx !== -1) {
                    values.splice(idx, 1);
                  } else {
                    values.push(e.target.value);
                  }

                  setSelectedFilters([...values]);
                }}
                value={field.key}
              />{' '}
              <label htmlFor={`filter-${field.key}`}>{field.label}</label>
            </div>
          ))}
        </div>
      </FilterPopover>

      {displayAppliedFilters && appliedFilters.length > 0 && (
        <FormProvider {...formMethods}>
          <form
            className={s.form}
            onSubmit={formMethods.handleSubmit(onSubmit)}
          >
            {appliedFilters.map(filterKey => (
              <Fragment key={filterKey}>
                {filterFields[filterKey] && (
                  <AppliedFilter
                    filterKey={filterKey}
                    values={appliedFilterValues[filterKey]}
                    fieldItem={filterFields[filterKey]}
                    workflowDefinition={workflowDefinition}
                    removeAppliedFilter={removeAppliedFilter}
                    handleSubmit={handleSubmit}
                  />
                )}
              </Fragment>
            ))}
          </form>
        </FormProvider>
      )}

      {/* TODO: Add ID to this list of filter items */}
    </div>
  );
};

Filters.defaultProps = {
  workflowDefinition: false,
};

export default memo(Filters);
