import { useFormContext } from 'react-hook-form';

import cx from 'classnames';

const Numeric = ({ name }: { name: string }) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const fieldName = name.replace('.value', '');

  return (
    <>
      <input
        className={cx(`form-control`, {
          'is-invalid': errors[fieldName],
        })}
        {...register(name, {
          pattern: {
            value: /^\d*\.?\d*$/,
            message: 'Invalid number',
          },
        })}
        type="string"
        autoComplete="off"
      />

      {errors[fieldName] && (
        <span className="text-danger">
          {(errors[fieldName] as any).value.message}
        </span>
      )}
    </>
  );
};

export default Numeric;
