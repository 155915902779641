import { FloatButton as AntFloatButton } from 'antd';
import './FloatButton.scss';
import { FloatButtonType } from 'antd/es/float-button/interface';

const FloatButton = ({
  disabled,
  right,
  left,
  top,
  bottom,
  icon,
  onClick,
  ...props
}: {
  disabled?: boolean;
  right?: number;
  left?: number;
  top?: number;
  bottom?: number;
  icon?: JSX.Element;
  size?: string;
  type?: FloatButtonType;
  tooltip?: any;
  onClick?: () => void;
}) => {
  return (
    <AntFloatButton
      icon={icon}
      onClick={onClick}
      className={`${disabled && 'disabled'}`}
      {...props}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        right: right,
        left: left,
        top: top,
        bottom: bottom,
      }}
    />
  );
};

export default FloatButton;
