import moment from 'moment';

import isJson from './isJson';

const toInteger = value => parseInt(value, 10);
/**
 * Preparing data before send to server
 */
const prepareFormData = (fields, data) => {
  const transformedData = { ...data };
  const fieldsArray = Object.entries<any>(transformedData);

  // eslint-disable-next-line no-restricted-syntax
  for (const [fieldKey, fieldValue] of fieldsArray) {
    /*
     * There's no ui_component for reserved fields like workflow fields:
     * workflow_outcome_reason,...
     */

    const field = fields[fieldKey];
    const hasValue = fieldValue !== null && fieldValue !== undefined;

    if (!field) continue;

    switch (field.ui_component) {
      case 'RichText': {
        const { language } = field.ui_component_options;

        if (language === 'json') {
          transformedData[fieldKey] =
            hasValue && isJson(fieldValue) ? fieldValue : null;
        } else {
          transformedData[fieldKey] = hasValue ? fieldValue : null;
        }
        break;
      }
      case 'MultiSelect':
        transformedData[fieldKey] = hasValue
          ? fieldValue.map(item => item.value)
          : [];
        break;
      case 'SingleSelect':
        transformedData[fieldKey] = hasValue
          ? toInteger(fieldValue.value)
          : null;
        break;
      case 'StaticSelect':
        transformedData[fieldKey] = hasValue ? fieldValue.value : null;
        break;
      case 'CardSelect':
        break;
      case 'CardTab':
        break;
      case 'Date': {
        if (!fieldValue) {
          transformedData[fieldKey] = null;
        } else {
          const { time } = field.ui_component_options;

          transformedData[fieldKey] = time
            ? moment(fieldValue).utc().toISOString(true)
            : moment
                .utc(
                  moment(fieldValue)
                    .local(true)
                    .format('YYYY-MM-DDT00:00:00+00:00'),
                )
                .toISOString();
        }

        break;
      }
      case 'Numeric':
        transformedData[fieldKey] = hasValue ? parseFloat(fieldValue) : null;
        break;
      case 'Checkbox':
        break;
      case 'Switch':
        break;
      case 'Permission':
        transformedData[fieldKey] = JSON.stringify(fieldValue || {});
        break;
      default:
        transformedData[fieldKey] =
          hasValue && fieldValue.toString().trim() !== ''
            ? fieldValue.toString().trim() // Removing left and right spaces
            : null;

        break;
    }
  }

  return transformedData;
};

export default prepareFormData;
