import FormTableList from 'components/DynamicForm/FormTableList/FormTableList';
import Paginate from 'components/Paginate/Paginate';
import ViewConstants from 'constants/ViewConstants';
import { useDialogManager } from 'contexts/DialogManagerContext';

import KanbanView from './KanbanView/KanbanView';
import MapView from './MapView/MapView';
import TimelineView from './TimelineView/TimelineView';
import WeeklyView from './WeeklyView/WeeklyView';

const FormViews = ({
  selectedView,
  entityKey,
  schema,
  listData = [],
  pageLimit,
  currentPage = 0,
  total = 0,

  // Functions
  handlePage,
  handleSorting,
  afterSaveCallback,
  afterDeleteCallback,
}: {
  selectedView: any;
  entityKey: string;
  schema: any;
  listData?: any[];
  pageLimit: number;
  currentPage?: number;
  total?: number;
  handlePage: (params: any) => void;
  handleSorting: (params: any) => void;
  afterSaveCallback: (params: any) => void;
  afterDeleteCallback: (params: any) => void;
}) => {
  const { openDialog } = useDialogManager();

  if (selectedView.viewType === ViewConstants.VIEWS.LIST) {
    return (
      <>
        {listData && schema && (
          <FormTableList
            schema={schema}
            data={listData}
            onRowSelected={event => {
              if (!event.node.selected) return;

              openDialog({
                entityKey,
                entityId: event.node.data.id,
                afterSaveCallback,
                afterDeleteCallback,
              });

              // Unselect row selected, so I can re-select the same again
              event.node.setSelected(false);
            }}
            onColumnSorted={handleSorting}
          />
        )}

        {total > 0 && (
          <Paginate
            pageLimit={pageLimit}
            total={total}
            currentPage={currentPage}
            onPageChanged={handlePage}
          />
        )}
      </>
    );
  }

  if (selectedView.viewType === ViewConstants.VIEWS.MAP && listData && schema) {
    return (
      <MapView
        data={listData}
        options={selectedView.viewOptions}
        entityKey={entityKey}
        fields={schema.fields}
        afterSaveCallback={afterSaveCallback}
        afterDeleteCallback={afterDeleteCallback}
      />
    );
  }

  if (selectedView.viewType === ViewConstants.VIEWS.KANBAN && listData) {
    return (
      <KanbanView
        data={listData}
        options={selectedView.viewOptions}
        schema={schema}
        onCardClick={cardId => {
          openDialog({
            entityKey,
            entityId: parseInt(cardId, 10),
            afterSaveCallback,
            afterDeleteCallback,
          });
        }}
      />
    );
  }

  if (selectedView.viewType === ViewConstants.VIEWS.TIMELINE) {
    return (
      <TimelineView
        data={listData}
        options={selectedView.viewOptions}
        onItemClick={itemId => {
          openDialog({
            entityKey,
            entityId: parseInt(itemId, 10),
            afterSaveCallback,
            afterDeleteCallback,
          });
        }}
      />
    );
  }

  if (selectedView.viewType === ViewConstants.VIEWS.WEEKLY) {
    return (
      <WeeklyView
        data={listData}
        options={selectedView.viewOptions}
        onItemClick={itemId => {
          openDialog({
            entityKey,
            entityId: parseInt(itemId, 10),
            afterSaveCallback,
            afterDeleteCallback,
          });
        }}
      />
    );
  }

  return <span>Selected view doesn't exist.</span>;
};

export default FormViews;
