import React, { useEffect, useContext } from 'react';
import ReactFlow, {
  Controls,
  Background,
  ConnectionMode,
  Panel,
  BackgroundVariant,
} from 'reactflow';

import 'reactflow/dist/style.css';
import {
  DeleteFilled,
  PlusOutlined,
  QuestionCircleOutlined,
  ReloadOutlined,
  SaveOutlined,
} from '@ant-design/icons';
import { Button, Card, Col, Row, Spin } from 'antd';
import cx from 'classnames';

import TsumEmpty from 'components/TsumEmpty/TsumEmpty';
import TsumPopconfirm from 'components/TsumPopconfirm/TsumPopconfirm';
import { MetadataFileType } from 'constants/MetadataConstants';
import { MetadataContext } from 'contexts/MetadataContext';

import StartNode from './CustomNodes/StartNode';
import StepNode from './CustomNodes/StepNode';
import {
  WorkflowContext,
  WorkflowContextState,
} from './WorkflowContextProvider';
import { WorkflowProperties } from './WorkflowProperties/WorkflowProperties';
import {
  WorkflowSteps,
  convertToEdges,
  convertToJson,
  convertToNodes,
} from './WorkflowUtils';

import s from './WorkflowEditor.module.scss';

const nodeTypes = {
  startNode: StartNode,
  stepNode: StepNode,
};
const WorkflowEditor = () => {
  const {
    state,
    actions: { createJsonFile, saveJsonFile, fetchJsonFile, deleteJsonFile },
  } = useContext(MetadataContext);
  const { flowActions, flowState } =
    useContext<WorkflowContextState>(WorkflowContext);

  useEffect(() => {
    if (!state?.workflowMetadata) {
      flowActions.setNodes([]);
      flowActions.setEdges([]);
      return;
    }
    const { actions, steps, outcome } = state.workflowMetadata;
    const nodes = convertToNodes({ steps, outcome });
    const edges = convertToEdges({
      actions,
      nodes,
    });
    flowActions.setNodes(nodes);
    flowActions.setEdges(edges);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.workflowMetadata]);

  const onWorkflowLoad = flow => flow.fitView();

  const onNodesChange = changes => {
    if (!changes.length) return;

    const positionedNodes = changes.filter(
      change =>
        change.dragging &&
        change.type === 'position' &&
        change.id !== WorkflowSteps.start,
    );
    if (positionedNodes.length) {
      flowActions.updateNodes(positionedNodes);
    }

    const [firstNode] = changes;
    if (firstNode.type === 'position' && !firstNode.dragging) {
      flowActions.selectNode(firstNode);
    }
  };

  const onEdgesChange = changes => {
    const selectedEdge = changes.find(edge => edge.selected);
    if (selectedEdge) {
      flowActions.selectEdge(selectedEdge);
    }
  };

  const onCreate = () => {
    createJsonFile(MetadataFileType.worfkflowMetadata);
  };

  const onSave = () => {
    const data = convertToJson(flowState.nodes, flowState.edges);
    saveJsonFile({
      tableKey: state.tableKey,
      fileType: MetadataFileType.worfkflowMetadata,
      data,
    });
  };

  const onRefresh = () => {
    fetchJsonFile(MetadataFileType.worfkflowMetadata);
  };

  const onDelete = () => {
    deleteJsonFile(MetadataFileType.worfkflowMetadata, state.tableKey);
  };

  if (!state?.workflowMetadata) {
    return (
      <Card className="h-100">
        <TsumEmpty description="Хүснэгт workflow тодорхойлоогүй байна">
          <TsumPopconfirm
            title="Шинэ workflow тодорхойлох"
            description="Та workflow үүсгэхдээ итгэлтэй байна уу?"
            onConfirm={onCreate}
          >
            <Button icon={<PlusOutlined />}>Workflow үүсгэх</Button>
          </TsumPopconfirm>
        </TsumEmpty>
      </Card>
    );
  }

  return (
    <Spin spinning={state.isLoading}>
      <Row className={cx(s.root, 'h-100')}>
        <Col span={17} className="pe-3">
          <ReactFlow
            snapToGrid={true}
            nodesDraggable
            nodesConnectable={false}
            nodes={flowState.nodes}
            edges={flowState.edges}
            nodeTypes={nodeTypes}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            zoomOnScroll={false}
            zoomOnDoubleClick={false}
            fitView={true}
            attributionPosition="top-right"
            connectionMode={ConnectionMode.Loose}
            onLoad={onWorkflowLoad}
            className="border border-gray h-100"
          >
            <Controls />
            <Background variant={BackgroundVariant.Lines} gap={20} />
            <Panel position="top-left">
              <Button
                icon={<ReloadOutlined />}
                onClick={onRefresh}
                className="me-2"
              ></Button>
              <Button
                type="primary"
                icon={<SaveOutlined />}
                disabled={!flowState.isUpdated}
                onClick={onSave}
              >
                Хадгалах
              </Button>
            </Panel>
            <Panel position="top-right">
              <TsumPopconfirm
                title="Workflow устгах"
                description="Та Workflow-г устгахдаа итгэлтэй байна уу?"
                onConfirm={onDelete}
                icon={<QuestionCircleOutlined />}
              >
                <Button icon={<DeleteFilled />} danger></Button>
              </TsumPopconfirm>
            </Panel>
          </ReactFlow>
        </Col>
        <Col span={7} className={s.size}>
          <WorkflowProperties />
        </Col>
      </Row>
    </Spin>
  );
};

export default WorkflowEditor;
