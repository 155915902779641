import { useEffect, useContext, createRef } from 'react';
import ReactDatePicker from 'react-datepicker';
import { useFormContext, Controller } from 'react-hook-form';

import cx from 'classnames';
import moment from 'moment';
import { ComponentInputProps } from 'sdk/models/ComponentProperties';
import { normaliseFormat } from 'utils/dateUtils';

import { AppContext } from 'contexts/AppContext';

import LabelComponent from './Label';

const DatePicker = ({
  name,
  defaultValue,
  props = { readOnly: false },
  settings = { time: false },
  ...otherProps
}: ComponentInputProps) => {
  const {
    formState: { errors },
    control,
    setValue,
  } = useFormContext();
  const {
    state: { settings: appSettings },
  } = useContext(AppContext);

  const placeholder = props?.placeholder;
  const dateInputRef = createRef<any>();

  const { time } = settings;
  const { readOnly } = props;

  useEffect(() => {
    const date = moment.utc(defaultValue);
    if (date.isValid()) {
      setValue(name, date.toDate());
    }
  }, [setValue, defaultValue, name]);

  return (
    <LabelComponent props={props}>
      <div className="date-picker-container">
        <Controller
          control={control}
          name={name}
          defaultValue={
            defaultValue ? moment.utc(defaultValue).toDate() : undefined
          }
          render={({ field }: { field: any }) => {
            const selected = moment(field.value).isValid()
              ? field.value
              : undefined;

            return (
              <ReactDatePicker
                onChange={field.onChange}
                onBlur={field.onBlur}
                selected={selected}
                className={cx('form-control', {
                  'is-invalid': errors[name],
                })}
                dateFormat={normaliseFormat(
                  appSettings.dateFormat,
                  time && appSettings.timeFormat,
                  { moment: false },
                )}
                readOnly={readOnly}
                timeFormat={appSettings.timeFormat}
                todayButton="Today"
                showTimeInput={time}
                placeholderText={placeholder}
                autoComplete="off"
                ref={dateInputRef}
                name={name}
                {...otherProps}
              />
            );
          }}
        />

        {props.description && (
          <small className="form-text text-body-tertiary">
            {props.description}
          </small>
        )}
        <span
          role="button"
          className="form-inline d-flex justify-content-center align-items-center date-picker-icon"
          onClick={() => dateInputRef.current.setFocus()}
          onKeyPress={e => {
            if (e.keyCode === 13) dateInputRef.current.setFocus();
          }}
          tabIndex={0}
        >
          <i className="far fa-calendar-alt" />
        </span>
      </div>
    </LabelComponent>
  );
};
export default DatePicker;
