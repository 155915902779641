import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import { GrClose } from 'react-icons/gr';

import cx from 'classnames';
const WorkflowReasonModal = ({
  show,
  title,
  body,
  confirmType,
  confirmAction,
  confirmActionText,
  handleClose,
  modalLevel,
}: {
  show?: boolean | false;
  title?: string | 'WorkflowReason';
  body: string;
  confirmType?: string | 'primary';
  confirmAction: (data: any) => void;
  confirmActionText?: string | 'Confirm';
  handleClose: () => void;
  modalLevel?: number | 50;
}) => {
  const {
    formState: { errors },
    register,
    handleSubmit,
  } = useForm();

  const onSubmit = data => {
    confirmAction(data);
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdropClassName={`modal-level-${modalLevel}`}
      className={`modal-level-${modalLevel}`}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header>
          <Modal.Title>
            <h5>{title}</h5>
          </Modal.Title>
          <div
            className="toggle-icon"
            onClick={handleClose}
            onKeyDown={handleClose}
            role="button"
            tabIndex={-1}
          >
            <GrClose />
          </div>
        </Modal.Header>

        <Modal.Body className="p-4">
          {body}

          <div className="form-group">
            <textarea
              className={cx('form-control', {
                'is-invalid': errors.outcome_reason?.message,
              })}
              id="outcome_reason"
              {...register('outcome_reason', { required: true })}
            />

            <span className="text-danger">
              {errors.outcome_reason?.message}
            </span>
          </div>
        </Modal.Body>

        <Modal.Footer className="justify-content-start">
          <button
            className={`btn btn-${confirmType}`}
            type="submit"
            id="saveAndReturn"
          >
            {confirmActionText}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default WorkflowReasonModal;
