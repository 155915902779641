import { useFormContext, Controller } from 'react-hook-form';

import { html } from '@codemirror/lang-html';
import { json, jsonParseLinter } from '@codemirror/lang-json';
import { PostgreSQL, sql } from '@codemirror/lang-sql';
import { lintGutter, lintKeymap, linter } from '@codemirror/lint';
import { EditorView } from '@codemirror/view';
import CodeMirror, { keymap } from '@uiw/react-codemirror';
import cx from 'classnames';
import { ComponentInputProps } from 'sdk/models/ComponentProperties';

import LabelComponent from './Label';

const extension = language => {
  switch (language) {
    case 'json':
      return [
        [
          json(),
          linter(jsonParseLinter()),
          lintGutter(),
          keymap.of([...lintKeymap]),
        ],
      ];
    case 'sql':
      return [
        [
          sql({
            dialect: PostgreSQL,
          }),
          lintGutter(),
          keymap.of([...lintKeymap]),
        ],
      ];
    case 'handlebars':
    case 'html':
    case 'css':
      return [[html(), EditorView.lineWrapping]];

    case 'text':
    default:
      return [[]];
  }
};

const RichText = ({
  name,
  defaultValue,
  props,
  settings = { language: 'text' },
}: ComponentInputProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const { language } = settings;

  return (
    <LabelComponent props={props}>
      <div
        data-cy={name}
        className={cx('border', {
          'border-danger': errors[name],
          'is-invalid': errors[name],
        })}
      >
        <Controller
          control={control}
          name={name}
          defaultValue={
            language === 'json'
              ? JSON.stringify(defaultValue, null, 4)
              : defaultValue?.toString()
          }
          render={({ field }) => (
            <CodeMirror
              height="400px"
              extensions={extension(language)}
              {...field}
            />
          )}
        />
      </div>

      {props.description && (
        <small className="form-text text-body-tertiary">
          {props.description}
        </small>
      )}
    </LabelComponent>
  );
};

export default RichText;
