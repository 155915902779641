import cx from 'classnames';

const FileIcon = ({ type, size }: { type: string; size?: any }) => {
  const icons = {
    'application/pdf': (
      <i className={cx('las la-file-pdf', { [size]: size })} />
    ),
    'image/jpeg': <i className={cx('las la-file-image', { [size]: size })} />,
    'image/png': <i className={cx('las la-file-image', { [size]: size })} />,
    'image/gif': <i className={cx('las la-file-image', { [size]: size })} />,
    'video/mpeg': <i className={cx('las la-file-video', { [size]: size })} />,
    'video/x-msvideo': (
      <i className={cx('las la-file-video', { [size]: size })} />
    ),
    'application/msword': (
      <i className={cx('las la-file-word', { [size]: size })} />
    ),
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': (
      <i className={cx('las la-file-excel', { [size]: size })} />
    ),
  };

  return icons[type] || <i className={cx('las la-file', { [size]: size })} />;
};

export default FileIcon;
