import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Col, Drawer, Form, Row } from 'antd';
import { post, put, get, remove } from 'core/fetch';
import Joi from 'joi';

import Card from 'components/Card/Card';
import RichText from 'components/DynamicForm/FormComponents/RichText';
import Text from 'components/DynamicForm/FormComponents/Text';
import { GraphTypes } from 'constants/GraphConstants';

const GraphEdit = ({
  open,
  handleClose,
  dashboard,
  id,
}: {
  open: boolean;
  handleClose: () => void;
  dashboard: any;
  id?: number;
}) => {
  const { t } = useTranslation();
  const closeDrawer = () => {
    setGraphType(GraphTypes.PIE);
    formMethods.reset();
    handleClose();
  };

  const schema = Joi.object({
    graph_type: Joi.string().required(),
    name: Joi.string().required(),
    label: Joi.string().required(),
    value: Joi.string().required(),
    query: Joi.string().required(),
    mapping: Joi.string(),
  });
  const formMethods = useForm();

  const [graphType, setGraphType] = useState<GraphTypes>(GraphTypes.PIE);
  const [errors, setErrors] = useState<any[]>([]);

  const fetchData = async () => {
    if (!id) return;

    const data = await get(`/graph/${id}`);

    setGraphType(data.graph_type);
    formMethods.setValue(`name`, data.name);
    formMethods.setValue(`label`, data.label);
    formMethods.setValue('value', data.value);
    formMethods.setValue('query', JSON.stringify(data.query));
    formMethods.setValue('mapping', data.mapping.toString());
  };
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const onCreate = async (data: any) => {
    const addedGraph = await post('graph', { ...data });
    let dashboardGraphs;
    if (dashboard.graphs) {
      dashboardGraphs = dashboard.graphs;
      dashboardGraphs.layout.push({ x: 0, y: 0, w: 3, h: 3, i: addedGraph.id });
    } else {
      dashboardGraphs = {
        layout: [{ x: 0, y: 0, w: 3, h: 3, i: addedGraph.id }],
      };
    }

    await put(`/dashboard/${dashboard.id}`, {
      graphs: JSON.stringify(dashboardGraphs),
    });
  };

  const onUpdate = async (data: any) => {
    await put(`/graph/${id}`, {
      ...data,
    });
  };

  const onSubmit = async (data: any) => {
    const final = { ...data, graph_type: graphType };
    const res = schema.validate(final, { abortEarly: false });

    if (res.error) {
      setErrors(res.error.details);
      return;
    }
    let jsonData;
    try {
      jsonData = JSON.parse(res.value.query);
    } catch (error) {
      setErrors([
        {
          context: { key: 'query' },
          custom: 'JSON төрлийн өгөгдөл биш байна',
        },
      ]);
      return;
    }
    try {
      await post(`/${jsonData.entity}/search`, jsonData.query);
    } catch (e) {
      setErrors([
        {
          context: { key: 'query' },
          custom: 'Алдаатай query',
        },
      ]);
      return;
    }

    if (!id) {
      await onCreate(res.value);
    } else {
      await onUpdate(res.value);
    }

    closeDrawer();
  };

  const onDelete = async () => {
    await remove(`/graph/${id}`);

    const newDashboard = dashboard;
    newDashboard.graphs = {
      layout: dashboard.graphs.layout.filter(l => {
        return l.i !== id;
      }),
    };

    await put(`dashboard/${dashboard.id}`, {
      graphs: newDashboard.graphs,
    });

    closeDrawer();
  };

  return (
    <FormProvider {...formMethods}>
      <Form>
        <Drawer
          title={`Дашбоард бүртгэл`}
          placement="right"
          width={1000}
          closable={true}
          onClose={closeDrawer}
          open={open}
          key="right"
          extra={
            <div>
              <button
                className="btn btn-primary mx-3"
                onClick={formMethods.handleSubmit(onSubmit)}
                type="submit"
              >
                {t('entity.modal.save')}
              </button>
              {id && (
                <button onClick={onDelete} className="btn btn-danger mx-3">
                  {t('entity.modal.delete-record')}
                </button>
              )}
            </div>
          }
        >
          <div>
            <div className="d-flex">
              <Card
                type={GraphTypes.PIE}
                graphType={graphType}
                setGraphType={setGraphType}
              />
              <div className="mx-3" />
              <Card
                type={GraphTypes.BAR}
                graphType={graphType}
                setGraphType={setGraphType}
              />
              <div className="mx-3" />
              <Card
                type={GraphTypes.LINE}
                graphType={graphType}
                setGraphType={setGraphType}
              />
              <div className="mx-3" />
              <Card
                type={GraphTypes.LIST}
                graphType={graphType}
                setGraphType={setGraphType}
              />
            </div>
          </div>
          <div>
            <div className="my-4 h3">Үндсэн мэдээлэл</div>

            {Object.values(GraphTypes).includes(graphType || '') && (
              <div>
                <Text
                  name={'name'}
                  props={{
                    label: 'Гарчиг',
                    placeholder: undefined,
                    description: undefined,
                  }}
                />
                <span className="text-danger">
                  {errors.length !== 0 &&
                    errors.filter(e => e.context.key === 'name').length !== 0 &&
                    t('entity.validation.required')}
                </span>
                <Row>
                  <Col span={11}>
                    <Text
                      name={'label'}
                      props={{
                        label: 'Label Field',
                        placeholder: undefined,
                        description: undefined,
                      }}
                    />
                    <span className="text-danger">
                      {errors.length !== 0 &&
                        errors.filter(e => e.context.key === 'label').length !==
                          0 &&
                        t('entity.validation.required')}
                    </span>
                  </Col>
                  <Col span={2}></Col>
                  <Col span={11}>
                    <Text
                      name={'value'}
                      props={{
                        label: 'Value Field',
                        placeholder: undefined,
                        description: undefined,
                      }}
                    />
                    <span className="text-danger">
                      {errors.length !== 0 &&
                        errors.filter(e => e.context.key === 'value').length !==
                          0 &&
                        t('entity.validation.required')}
                    </span>
                  </Col>
                </Row>
              </div>
            )}

            <RichText
              name="query"
              props={{
                label: 'QUERY',
                placeholder: 'query',
                description:
                  'Жишээ нь: {"entity":"test_student","query":{"$where":{"_is_deleted":false},"$select":["name","age"]}}',
              }}
              settings={{
                language: 'json',
              }}
            />
            <RichText
              name="mapping"
              props={{
                label: 'MAPPING',
                placeholder: 'mapping',
              }}
              settings={{
                language: 'text',
              }}
            />

            <span className="text-danger">
              {errors.length !== 0 &&
                errors.filter(e => e.context.key === 'query').length !== 0 &&
                errors.filter(e => e.context.key === 'query')[0].custom}
            </span>
          </div>
        </Drawer>
      </Form>
    </FormProvider>
  );
};
export default GraphEdit;
