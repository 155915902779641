import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';

import Popover from 'components/Popover/Popover';

import s from './FilterPopover.module.scss';

const FilterPopover = ({
  id,
  title,
  className,
  children,
  onApply,
  showDrop,
}) => {
  const [isActive, setIsActive] = useState(false);
  const { t } = useTranslation();

  return (
    <Popover
      id={id}
      toggle={title}
      className={className}
      isActive={isActive}
      setIsActive={setIsActive}
      showDrop={showDrop}
    >
      <div className={s.fields} data-cy="apply-filter-fields">
        {children}
      </div>

      <div className={s.apply}>
        <button
          type="button"
          className="btn btn-light btn-sm mr-3"
          data-cy="apply-filter-btn"
          onClick={() => {
            if (typeof onApply === 'function') {
              onApply();
            }

            setIsActive(false);
          }}
        >
          {t('entity.filter.apply')}
        </button>
      </div>
    </Popover>
  );
};

FilterPopover.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  onApply: PropTypes.func,
  showDrop: PropTypes.bool,
};

FilterPopover.defaultProps = {
  className: '',
  onApply: undefined,
  showDrop: false,
};

export default FilterPopover;
