import {
  FcPieChart,
  FcBarChart,
  FcLineChart,
  FcTodoList,
} from 'react-icons/fc';

import { ConfigProvider } from 'antd';
import { Card as AntCard } from 'antd';

import { GraphTypes } from 'constants/GraphConstants';

const Card = ({
  type,
  graphType,
  setGraphType,
}: {
  type: GraphTypes;
  graphType: GraphTypes;
  setGraphType: (type: GraphTypes) => void;
}) => {
  return (
    <ConfigProvider
      theme={{
        components: {
          Card: {
            colorBgContainer:
              graphType === type ? 'var(--bs-primary-extra-light)' : '',
          },
        },
      }}
    >
      <AntCard hoverable onClick={() => setGraphType(type)}>
        {type === GraphTypes.PIE && <FcPieChart style={{ fontSize: '4rem' }} />}
        {type === GraphTypes.BAR && <FcBarChart style={{ fontSize: '4rem' }} />}
        {type === GraphTypes.LINE && (
          <FcLineChart style={{ fontSize: '4rem' }} />
        )}
        {type === GraphTypes.LIST && (
          <FcTodoList style={{ fontSize: '4rem' }} />
        )}
      </AntCard>
    </ConfigProvider>
  );
};
export default Card;
