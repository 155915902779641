import { createContext, useReducer, useCallback, useEffect } from 'react';

import { get } from '../core/fetch';
import changeThemeColor from '../utils/changeThemeColor';
import reducer, { initialState, ActionTypes } from './state/AppState';

interface AppContextActions {
  fetchApps: () => Promise<void>;
  fetchMe: () => Promise<void>;
  changeMenuItems: (appKey: string | null) => void;
}

interface AppState {
  state: any;
  actions: AppContextActions;
}

export const AppContext = createContext<AppState>({
  state: { settings: undefined },
  actions: {
    fetchApps: () => Promise.reject(),
    fetchMe: () => Promise.reject(),
    changeMenuItems: () => Promise.reject(),
  },
});

const AppContextProvider = ({ children }: { children: any }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const fetchApps = useCallback(async () => {
    dispatch({ type: ActionTypes.FETCH_APPS_REQUEST });
    get(`/app`)
      .then(res =>
        dispatch({ type: ActionTypes.FETCH_APPS_SUCCESS, payload: res }),
      )
      .catch(e => dispatch({ type: ActionTypes.FETCH_APPS_FAIL, payload: e }));
  }, []);

  const fetchMe = useCallback(async () => {
    dispatch({ type: ActionTypes.FETCH_ME_REQUEST });

    get(`/me`)
      .then(res =>
        dispatch({ type: ActionTypes.FETCH_ME_SUCCESS, payload: res }),
      )
      .catch(e => dispatch({ type: ActionTypes.FETCH_ME_FAIL, payload: e }));
  }, []);

  const changeMenuItems = async appKey => {
    const menuItems = state.apps.find(app => app.key === appKey)?.menu;

    dispatch({
      type: ActionTypes.MENU_ITEMS_CHANGED,
      payload: {
        menuItems,
        selectedApp: appKey,
      },
    });
  };

  useEffect(() => {
    changeThemeColor(state.settings.themeColor);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.settings.themeColor]);

  const actions = {
    fetchApps,
    fetchMe,
    changeMenuItems,
  };

  return (
    <AppContext.Provider
      value={{
        actions,
        state,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
