import { BiErrorCircle } from 'react-icons/bi';
import { BsCheck2Circle, BsFillInfoCircleFill } from 'react-icons/bs';
import { toast } from 'react-toastify';

export default class ShowToast {
  static async error(message: string) {
    await toast.error(
      <div>
        <div className="d-flex p-2">
          <div>
            <BiErrorCircle size={30} className="mx-2" />
          </div>
          <div className="mx-2">
            <div className="title">Алдаа гарлаа</div>
            <div>{message}</div>
          </div>
        </div>
      </div>,
    );
  }

  static async success(message: string) {
    await toast.success(
      <div>
        <div className="d-flex p-2">
          <div>
            <BsCheck2Circle size={30} className="mx-2" />
          </div>
          <div className="mx-2">
            <div className="title">Амжилттай</div>
            <div>{message}</div>
          </div>
        </div>
      </div>,
    );
  }

  static async info(message: string) {
    await toast.info(
      <div>
        <div className="d-flex p-2">
          <div>
            <BsFillInfoCircleFill size={30} className="mx-2" />
          </div>
          <div className="mx-2">
            <div className="title">Амжилттай</div>
            <div>{message}</div>
          </div>
        </div>
      </div>,
    );
  }
}
