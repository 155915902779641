// eslint-disable-next-line import/no-extraneous-dependencies
import { Handle, Position } from 'reactflow';

const StartNode = () => (
  <div
    style={{
      background: 'var(--bs-primary)',
      borderRadius: '30%',
      color: 'var(--bs-primary)',
      // display: 'flex',
      // alignItems: 'center',
      // justifyContent: 'center',
      height: '15px',
      width: '15px',
    }}
  >
    <Handle
      type="source"
      position={Position.Right}
      style={{ minHeight: 1, minWidth: 1, height: 2, width: 2 }}
    />
  </div>
);

export default StartNode;
