import { FaInfoCircle } from 'react-icons/fa';

import cx from 'classnames';
import PropTypes from 'prop-types';

import s from './WorkflowMessage.module.scss';

const WorkflowMessage = ({ workflow }) => (
  <div className={s.workflowMessage}>
    {workflow.outcomeReason && (
      <span
        className={cx('alert', {
          'alert-danger': workflow.outcome === 2,
          'alert-warning': !workflow.outcome,
        })}
      >
        <FaInfoCircle />
        <b>{'Шалтгаан: '}</b>
        {workflow.outcomeReason}
      </span>
    )}
  </div>
);

WorkflowMessage.propTypes = {
  workflow: PropTypes.any.isRequired,
};

WorkflowMessage.defaultProps = {};

export default WorkflowMessage;
