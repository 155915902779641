import { Fragment, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { Segmented } from 'antd';
import cx from 'classnames';
import {
  ComponentProps,
  ComponentReferences,
  ComponentSettings,
} from 'sdk/models/ComponentProperties';

import { useEntityContext } from 'contexts/EntityContext';

import { FormMode } from '../../../core/formMode';
import { getField, getFieldValue } from '../FormTabs/FormTabFields';
import LabelComponent from './Label';

const CardTab = ({
  name,
  defaultValue = '',
  props = { readOnly: false, placeholder: '' },
  entityId,
  entityKey,
  settings = { options: [] },
  mode,
  data,
}: {
  ui_component?: string;
  key?: string;
  name: string;
  entityKey?: string;
  entityId?: number;
  defaultValue?: any;
  props: ComponentProps;
  settings?: ComponentSettings;
  references?: ComponentReferences;
  mode: string;
  data: any;
}) => {
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext();
  const { getEntityState } = useEntityContext();

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const entityState = getEntityState(entityKey!, entityId);
  const { options } = settings;
  const [cardValue, setCardValue] = useState<string>(
    defaultValue || (options?.length && options[0].value),
  );

  const { readOnly, placeholder } = props;

  useEffect(() => {
    const errorFields = Object.keys(errors);
    //on error change gard tab
    props.ui_component_options.options.map(option => {
      option.items.map(item => {
        item.map(i => {
          if (i.fieldKey === errorFields[0]) {
            setValue(name, option.value);
            setCardValue(option.value);
          }
        });
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);
  return (
    <div>
      <LabelComponent props={props}>
        <div>
          <Controller
            control={control}
            defaultValue={defaultValue || (options?.length && options[0].value)}
            name={name}
            render={({ field }) => {
              return (
                <div
                  data-cy={name}
                  className={cx({
                    'border-danger': errors[name],
                    'is-invalid': errors[name],
                  })}
                >
                  {options && (
                    <Segmented
                      {...field}
                      onChange={e => {
                        field.onChange(e);
                        setCardValue(e.toString());
                      }}
                      value={cardValue}
                      disabled={readOnly}
                      placeholder={placeholder}
                      options={options?.map(option => {
                        return {
                          label: (
                            <div style={{ padding: 4 }}>
                              <img width="100" height="100" src={option.icon} />
                              <div>{option.label}</div>
                            </div>
                          ),
                          value: option.value,
                        };
                      })}
                    />
                  )}
                </div>
              );
            }}
          />
        </div>

        {props.description && (
          <small className="form-text text-muted">{props.description}</small>
        )}
      </LabelComponent>
      <div className={cx('fieldset-body px-0 pb-2')}>
        <>
          {options?.length &&
            cardValue &&
            options
              .filter(option => option.value == cardValue)[0]
              .items.map((rows, rowIdx) => (
                <div
                  id={'main'}
                  key={`main-row-${rowIdx}`}
                  aria-labelledby={`main-row-${rowIdx}`}
                  data-parent="#fieldsets"
                >
                  <div className="row">
                    {rows?.map(column => {
                      const item = entityState.schema.fields[column.fieldKey];
                      const props = {
                        ...item,
                        readOnly:
                          mode === FormMode.readonly ||
                          item?.ui_component_options?.readOnly,
                      };
                      const settings = {
                        ...item?.ui_component_options,
                      };
                      // Түр зуур map хийнэ
                      const references = {
                        relatedTableKey:
                          item?.relationshipOptions?.related_entity,
                        name: item?.relationshipOptions?.name,
                        query: item?.relationshipOptions?.filter_where,
                        // relatedFieldKey:
                      };
                      if (column.fieldKey === 'id') {
                        return (
                          <div className="col-md col-12" key={column.fieldKey}>
                            <div className="form-group">
                              <label>Identifier</label>
                              <input
                                type="text"
                                className="form-control"
                                disabled
                                value={entityId}
                              />
                            </div>
                          </div>
                        );
                      }

                      if (!props) {
                        return (
                          <div className="col-md col-12" key={column.fieldKey}>
                            <div className="form-group text-danger">
                              "{column.fieldKey}" гэсэн талбар олдсонгүй
                            </div>
                          </div>
                        );
                      }

                      return (
                        <Fragment key={props.key}>
                          <div className="col-md col-12">
                            {getField(props.ui_component, {
                              entityKey,
                              entityId,
                              name: props.key,
                              defaultValue: getFieldValue(
                                data,
                                props,
                                references,
                              ),
                              description: props.description,
                              props,
                              settings,
                              references,
                              data,
                            })}
                            <span className="text-danger">
                              {errors &&
                                (errors[column.fieldKey]?.message as string)}
                            </span>
                          </div>
                        </Fragment>
                      );
                    })}
                  </div>
                </div>
              ))}
        </>
      </div>
    </div>
  );
};

export default CardTab;
