import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { get } from 'core/fetch';
import { ComponentInputProps } from 'sdk/models/ComponentProperties';

import LabelComponent from './Label';

const fetchEntities = async () => {
  try {
    return await get(`/schema`);
  } catch (err) {
    throw new Error(err as any);
  }
};
const fetchApps = async () => {
  try {
    return (await get(`/app`)).apps;
  } catch (err) {
    throw new Error(err as any);
  }
};
const Permission = ({
  name,
  defaultValue = '',
  props,
  settings,
}: ComponentInputProps) => {
  const scope = settings?.scope || '';
  const { register } = useFormContext();
  const [entities, setEntities] = useState<Array<any>>([]);

  const appPrivilegies = [{ key: 'view', label: 'View' }];
  const entityPrivilegies = [
    { key: 'create', label: 'Үүсгэх' },
    { key: 'read', label: 'Харах' },
    { key: 'write', label: 'Өөрчлөх' },
    { key: 'delete', label: 'Устгах' },
    { key: 'comment', label: 'Тайлбар бичих' },
    { key: 'attach', label: 'Файл хавсгарах' },
    { key: 'print', label: 'Хэвлэх' },
    { key: 'export', label: 'Татаж авах' },
  ];
  const context = {
    entity: {
      fetch: fetchEntities,
      privilegies: entityPrivilegies,
    },
    app: {
      fetch: fetchApps,
      privilegies: appPrivilegies,
    },
  };
  const colWidth = `${100 / (entityPrivilegies.length + 1)}%`;
  useEffect(() => {
    let unmounted = false;
    context[scope].fetch().then(items => {
      if (!unmounted) {
        setEntities(items);
      }
    });
    return () => {
      unmounted = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LabelComponent props={props}>
      <table className="table">
        <thead>
          <tr>
            <th style={{ width: colWidth }}>&nbsp;</th>
            {context[scope].privilegies.map(priv => (
              <th
                style={{ width: colWidth }}
                className="text-center"
                key={priv.label}
              >
                {priv.label}
              </th>
            ))}
          </tr>
        </thead>
      </table>
      <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
        <table className="table">
          <tbody>
            {entities.map(entity => (
              <tr key={entity.key}>
                <td style={{ width: colWidth }}>{entity.name}</td>
                {context[scope].privilegies.map(priv => (
                  <td
                    key={`${entity.key}${priv.key}`}
                    style={{ width: colWidth }}
                    className="text-center"
                  >
                    <div className="custom-control custom-checkbox">
                      <input
                        className="custom-control-input"
                        id={`${name}[${entity.key}].${priv.key}`}
                        type="checkbox"
                        {...register(`${name}[${entity.key}].${priv.key}`)}
                        defaultChecked={defaultValue?.[entity.key]?.[priv.key]}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor={`${name}[${entity.key}].${priv.key}`}
                      >
                        &nbsp;
                      </label>
                    </div>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </LabelComponent>
  );
};

export default Permission;
