const RelationshipFields = ['SingleSelect', 'MultiSelect', 'Grid'];

const RelationshipType = {
  OneToMany: 'Many to One',
  ManyToMany: 'Many to Many',
  ManyToOne: 'Many to One',
};

const RelationshipFieldsTypes = {
  SingleSelect: RelationshipType.ManyToOne,
  MultiSelect: RelationshipType.ManyToMany,
  Grid: RelationshipType.OneToMany,
};

const UIComponents = {
  Checkbox: 'Checkbox',
  Switch: 'Switch',
  Date: 'Date',
  File: 'File',
  Grid: 'Grid',
  MapLocation: 'MapLocation',
  MapCoordinate: 'MapCoordinate',
  Numeric: 'Numeric',
  StaticSelect: 'StaticSelect',
  CardSelect: 'CardSelect',
  CardTab: 'CardTab',
  SingleSelect: 'SingleSelect',
  MultiSelect: 'MultiSelect',
  WorkflowStep: 'WorkflowStep',
};

export {
  RelationshipFields,
  RelationshipFieldsTypes,
  RelationshipType,
  UIComponents,
};
