import { Modal } from 'antd';
import { LegacyButtonType } from 'antd/es/button/button';
import { t } from 'i18next';
const ConfirmationModal = ({
  show,
  title = 'Confirmation',
  description,
  confirmAction,
  onCancel = () => {},
  confirmType = 'primary',
}: {
  show: boolean;
  title?: string;
  description?: string;
  confirmAction: () => void;
  confirmType?: LegacyButtonType;
  onCancel?: () => void;
}) => {
  return (
    <Modal
      title={title}
      okType={confirmType}
      open={show}
      onOk={confirmAction}
      onCancel={onCancel}
      okText={t('entity.modal.confirm')}
      cancelText={t('entity.modal.cancel')}
    >
      {description}
    </Modal>
  );
};
export default ConfirmationModal;
