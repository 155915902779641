import { useContext } from 'react';

import { Card, Table, Tag } from 'antd';

import TsumEmpty from 'components/TsumEmpty/TsumEmpty';
import { MetadataContext } from 'contexts/MetadataContext';

const TableEditor = () => {
  const { state } = useContext(MetadataContext);

  const columns = [
    {
      title: 'Нэр',
      dataIndex: 'key',
      key: 'key',
    },
    {
      title: 'Гарчиг',
      dataIndex: 'label',
      key: 'label',
    },
    {
      title: 'Төрөл',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Тохиргоо',
      dataIndex: 'validation_rules',
      key: 'validation_rules',
      render: (_, col) => {
        if (!col) return <></>;
        return (
          <>
            {col.required && (
              <Tag bordered={false} color="processing">
                Required
              </Tag>
            )}
            {col.pattern && (
              <Tag bordered={false} color="magenta">
                ${col.pattern}
              </Tag>
            )}
          </>
        );
      },
    },
  ];

  if (!state.tableKey)
    return (
      <Card className="h-100">
        <TsumEmpty
          description={<span>Засах боломжтой хүснэгтээ сонгоно уу</span>}
        ></TsumEmpty>
      </Card>
    );

  return (
    <Table
      columns={columns}
      className="h-100"
      dataSource={state.tableMetadata?.columns}
    />
  );
};

export default TableEditor;
