import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { Tabs } from 'antd';
import cx from 'classnames';

import { RelationshipFields } from '../../../constants/fieldTypes';

import s from './FormLayout.module.scss';

const GridItems = ({ columns }: { columns: any[] }) => (
  <div className="d-flex">
    {columns.map(column => (
      <span className="d-flex flex-column flex-fill" key={column.headerName}>
        <span className={s.gridHeader}>{column.headerName}</span>
        <span>{column.field}</span>
      </span>
    ))}
  </div>
);

const FormView = ({ fields, formView }: { fields: any; formView: any }) => {
  return (
    <div className={s.root}>
      <Tabs
        defaultActiveKey="1"
        type="card"
        items={formView.formTabs.map(fieldset => {
          return {
            label: fieldset.label || fieldset.key,
            key: fieldset.key,
            children: (
              <div key={fieldset.key} className={cx(s.fieldset)}>
                {fieldset.rows
                  .map((row, idx) => ({ ...row, idx }))
                  .map(row => (
                    <Row className="mb-2" key={row.idx}>
                      {row.columns.map(column => {
                        const field = fields.find(
                          f => f.key === column.fieldKey,
                        );

                        return (
                          <Col
                            className={cx(
                              s.fieldKey,
                              s[field.type],
                              {
                                [s.multiline]: field.type === 'TextArea',
                              },
                              {
                                [s.readonly]: field.readOnly,
                              },
                              {
                                [s.time]: field.time,
                              },
                              {
                                [s.precision]: field.precision,
                              },
                              {
                                [s.json]: field.language === 'json',
                              },
                            )}
                            key={column.fieldKey}
                          >
                            {field.type === 'Grid' &&
                            field.type_options?.listView?.columnDefs ? (
                              <GridItems
                                columns={field.type_options.listView.columnDefs}
                              />
                            ) : (
                              <div>
                                {(RelationshipFields.includes(field.type) && (
                                  <Link
                                    to={`/settings/metadata/${field.relationshipOptions.related_entity}`}
                                  >
                                    {field.label}
                                  </Link>
                                )) ||
                                  field.label}

                                {field.required && (
                                  <span className="text-danger"> *</span>
                                )}
                              </div>
                            )}
                          </Col>
                        );
                      })}
                    </Row>
                  ))}
              </div>
            ),
          };
        })}
      />
    </div>
  );
};

export default FormView;
