import React, { useContext, useEffect, useState, useCallback } from 'react';
import { NavDropdown, Dropdown, ButtonGroup, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FaFileExport } from 'react-icons/fa';
import { MdOutlineRefresh, MdMenu } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';

import cx from 'classnames';
import { kebabCase, isEmpty } from 'lodash';

import FormViews from '../../components/DynamicForm/Views/FormViews';
import Filters from '../../components/Filters/Filters';
import LayoutWithMenu from '../../components/Layout/LayoutWithMenu';
import EntityPageLoader from '../../components/Loaders/EntityPageLoader';
import MetaTags from '../../components/MetaTags/MetaTags';
import ENTITY from '../../constants/ViewConstants';
import { AppContext } from '../../contexts/AppContext';
import { useDialogManager } from '../../contexts/DialogManagerContext';
import { EntityContext } from '../../contexts/EntityContext';
import { getViewGroupBy } from '../../utils/formUtils';
import { Messages } from '../../utils/messages';
import { serializeQuery } from '../../utils/objectTransformer';
import ShowToast from '../../utils/ShowToast';

import s from './DynamicTable.module.scss';

const DynamicTable = () => {
  const {
    actions: {
      fetchAllByEntityName,
      resetEntityStates,
      exportToExcel,
      setEntityView,
    },
    getEntityState,
  } = useContext(EntityContext);

  const defaultPageLimit = 15;
  const defaultMapViewEntityLimit = 10000;
  const defaultGroupByPageLimit = 1000;
  const {
    appKey,
    entityKey,
    id: entityId,
    operation,
  } = useParams() as {
    appKey: string;
    entityKey: string;
    operation: string;
    id: string;
  };

  const entityState = getEntityState(entityKey);
  const { t } = useTranslation();
  const { state } = useContext(AppContext);

  const appName = state.apps.find(app => app.key === appKey)?.name;

  if ((window as any).Cypress) {
    (window as any).entityAction = entityState;
    (window as any).entityState = entityState;
  }

  const entitySchema = entityState?.schema;
  const [currentPage, setCurrentPage] = useState(0);
  const [currentFilter, setCurrentFilter] = useState(undefined);
  const [currentFilterRelated, setCurrentFilterRelated] = useState(undefined);
  const [currentGroupBy, setCurrentGroupBy] = useState(undefined);
  const [currentSort, setCurrentSort] = useState(undefined);
  const [pageLimit, setPageLimit] = useState(defaultPageLimit);
  const history = useNavigate();

  const availablePrintableDocs = entitySchema?.printableDocuments?.filter(
    p => p.use_in_grid,
  );

  const filterCriterionQuery = currentFilter
    ? `${encodeURIComponent(
        JSON.stringify({
          $where: currentFilter,
          $withRelated: currentFilterRelated,
        }),
      )}`
    : '';

  const { openDialog } = useDialogManager();

  useEffect(() => {
    resetEntityStates(entityKey);

    const groupByFromView = getViewGroupBy(entityState?.view);

    fetchAllByEntityName(
      entityKey,
      groupByFromView && groupByFromView.length > 0
        ? defaultGroupByPageLimit
        : pageLimit,
      0,
      undefined,
      undefined,
      undefined,
      groupByFromView,
    );

    setCurrentFilter(undefined);
    setCurrentGroupBy(undefined);
    setCurrentPage(0);
    setCurrentFilterRelated(undefined);
    setCurrentSort(undefined);
    setPageLimit(defaultPageLimit);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityKey]);

  const submitFilter = ({ $withRelated, $where }) => {
    setCurrentFilter($where);
    setCurrentFilterRelated($withRelated);
    setCurrentPage(0);

    fetchAllByEntityName(
      entityKey,
      pageLimit,
      0,
      currentSort,
      $where,
      $withRelated,
      currentGroupBy,
    );
  };

  const handleRefresh = () => {
    fetchAllByEntityName(
      entityKey,
      pageLimit,
      currentPage,
      currentSort,
      currentFilter,
      currentFilterRelated,
      currentGroupBy,
    );
  };

  const handleExport = () => {
    exportToExcel(entityKey, currentFilter, currentFilterRelated);
  };

  const handlePage = async pageParams => {
    setCurrentPage(pageParams.number);
    setPageLimit(pageParams.limit);
    fetchAllByEntityName(
      entityKey,
      pageParams.limit,
      pageParams.number,
      currentSort,
      currentFilter,
      currentFilterRelated,
    );
  };

  const handleSorting = async sort => {
    setCurrentSort(sort);
    fetchAllByEntityName(
      entityKey,
      pageLimit,
      currentPage,
      sort,
      currentFilter,
      currentFilterRelated,
    );
  };

  const handleGroupBy = async groupBy => {
    setPageLimit(defaultGroupByPageLimit);
    setCurrentGroupBy(groupBy);
    setCurrentSort(undefined);
    setCurrentPage(0);

    fetchAllByEntityName(
      entityKey,
      defaultGroupByPageLimit,
      undefined,
      undefined,
      currentFilter,
      currentFilterRelated,
      groupBy,
    );
  };

  const afterSaveCallback = useCallback(
    async ({ id }) => {
      await ShowToast.success(Messages.EntitySaved);
      fetchAllByEntityName(
        entityKey,
        pageLimit,
        currentPage,
        currentSort,
        currentFilter,
        currentFilterRelated,
        currentGroupBy,
      );

      resetEntityStates(entityKey, id);
    },
    [
      currentFilter,
      currentFilterRelated,
      currentPage,
      currentSort,
      entityKey,
      fetchAllByEntityName,
      pageLimit,
      resetEntityStates,
      currentGroupBy,
    ],
  );

  const afterDeleteCallback = useCallback(
    async ({ id }) => {
      await ShowToast.success(Messages.EntityDeleted);
      fetchAllByEntityName(
        entityKey,
        pageLimit,
        currentPage,
        currentSort,
        currentFilter,
        currentFilterRelated,
        currentGroupBy,
      );

      resetEntityStates(entityKey, id);
    },
    [
      currentFilter,
      currentFilterRelated,
      currentPage,
      currentSort,
      entityKey,
      fetchAllByEntityName,
      pageLimit,
      resetEntityStates,
      currentGroupBy,
    ],
  );

  useEffect(() => {
    if (operation === 'edit' && entityId) {
      openDialog({
        entityKey,
        entityId: parseInt(entityId, 10),
        afterSaveCallback,
        afterDeleteCallback,
      });
    } else if (operation === 'create') {
      openDialog({
        entityKey,
        afterSaveCallback,
        afterDeleteCallback,
      });
    }
  }, [
    appKey,
    entityKey,
    history,
    entityId,
    openDialog,
    operation,
    afterDeleteCallback,
    afterSaveCallback,
  ]);

  const isLoading =
    !entitySchema || !entityState?.listData || entityState.isLoading;

  return (
    <LayoutWithMenu>
      <div
        className={cx('px-3 px-lg-4 py-3 py-lg-5 d-flex', s.root)}
        data-cy="entity-list-container"
      >
        {(isLoading && <EntityPageLoader className="w-100" />) || (
          <>
            {entitySchema?.name && <MetaTags entityName={entitySchema.name} />}
            <div className="w-100 d-flex flex-column">
              <Row
                data-cy="entity-table-wrapper"
                className={cx(
                  'mb-4 p-4 align-items-center widget-box',
                  s.header,
                  s.headerMain,
                )}
              >
                <div className={(s.leftActions, s.pagePath)}>
                  <h4>{entitySchema?.name}</h4>
                  <div className="d-inline-flex align-items-center">
                    <a
                      href={`/apps/${appKey}`}
                      style={{ textDecoration: 'none' }}
                    >
                      {appName}
                    </a>
                    <span>{' • '}</span>
                    <div
                      className={cx(
                        'mb-0',
                        {
                          'mr-4': entitySchema?.views?.length > 0,
                        },
                        s.name,
                      )}
                      data-cy="page-title"
                    >
                      {entitySchema?.name}
                    </div>
                  </div>
                </div>
                <div className={s.rightActions}>
                  <button
                    type="button"
                    className={cx('btn btn-primary btn', s.actionButton)}
                    disabled={entityState.formIsBlocked}
                    data-cy="create"
                    onClick={e => {
                      e.preventDefault();

                      openDialog({
                        entityKey,
                        afterSaveCallback,
                        afterDeleteCallback,
                      });
                    }}
                  >
                    <i className="las la-plus-circle mr-2" />
                    {t('entity.buttons.create')}
                  </button>

                  <Dropdown as={ButtonGroup}>
                    <Dropdown.Toggle data-cy="btn-export" variant="light">
                      <FaFileExport className="mr-2" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={handleExport}>
                        <i className="fa fa-file-excel mr-0" />
                        {t('entity.buttons.export-csv')}
                      </Dropdown.Item>
                      {availablePrintableDocs?.length > 0 && (
                        <Dropdown.Divider />
                      )}
                      {availablePrintableDocs.map(doc => (
                        <Dropdown.Item
                          disabled={isLoading}
                          key={doc.id}
                          href={`/api/${entityKey}/doc/${
                            doc.id
                          }?${serializeQuery({
                            criterion: filterCriterionQuery,
                            limit: isEmpty(filterCriterionQuery)
                              ? 1000
                              : undefined,
                          })}`}
                          target="_blank"
                        >
                          <i className="fa fa-file-pdf mr-1" /> {doc.name}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Row>
              <div className="p-4 widget-box">
                <Row
                  data-cy="entity-table-wrapper"
                  className={cx('align-items-center', s.header)}
                >
                  <div className={cx('pb-4', s.leftActions)}>
                    {entitySchema && !isEmpty(entitySchema.filterFields) && (
                      <Filters
                        filterFields={entitySchema.filterFields}
                        workflowDefinition={entitySchema.workflow?.definition}
                        submitFilter={submitFilter}
                      />
                    )}
                  </div>
                  <div className={cx('pb-4', s.rightActions)}>
                    {entitySchema?.views && entityState.view && (
                      <NavDropdown
                        data-cy="dropdown-entity-view"
                        className={s.entityViewNav}
                        title={<MdMenu size={23} />}
                        id="nav-entity-views"
                        onSelect={eventKey => {
                          const view = entitySchema.views.find(
                            v => v.name === eventKey,
                          );

                          setEntityView(entityKey, view);

                          switch (view.viewType) {
                            case ENTITY.VIEWS.LIST: {
                              setPageLimit(defaultPageLimit);
                              setCurrentGroupBy(undefined);
                              fetchAllByEntityName(entityKey, defaultPageLimit);
                              break;
                            }
                            case ENTITY.VIEWS.MAP: {
                              setPageLimit(defaultMapViewEntityLimit);
                              setCurrentGroupBy(undefined);
                              fetchAllByEntityName(
                                entityKey,
                                defaultMapViewEntityLimit,
                              );
                              break;
                            }
                            case ENTITY.VIEWS.WEEKLY: {
                              setPageLimit(defaultMapViewEntityLimit);
                              setCurrentGroupBy(undefined);
                              setCurrentSort(undefined);
                              setCurrentPage(0);
                              fetchAllByEntityName(
                                entityKey,
                                defaultMapViewEntityLimit,
                              );
                              break;
                            }

                            case ENTITY.VIEWS.TIMELINE:
                            case ENTITY.VIEWS.KANBAN: {
                              handleGroupBy(getViewGroupBy(view));
                              break;
                            }

                            default:
                              throw new Error('ViewType is not implemented');
                          }
                        }}
                      >
                        {entitySchema.views.map(view => (
                          <NavDropdown.Item
                            key={kebabCase(view.name)}
                            eventKey={view.name}
                            className="align-items-center d-flex"
                          >
                            <div>{ENTITY.VIEWS_ICONS[view.viewType]} </div>
                            <div className="mx-2">{view.name}</div>
                          </NavDropdown.Item>
                        ))}
                      </NavDropdown>
                    )}
                    <button
                      type="button"
                      className="btn btn-link p-0"
                      data-cy="refresh"
                      onClick={handleRefresh}
                    >
                      <MdOutlineRefresh size={23} />
                    </button>
                  </div>
                </Row>

                {!isLoading &&
                  entitySchema &&
                  entityState &&
                  entityState.view && (
                    <FormViews
                      selectedView={entityState.view}
                      entityKey={entityKey}
                      schema={entityState.schema}
                      listData={entityState.listData}
                      pageLimit={pageLimit}
                      currentPage={currentPage}
                      total={entityState.total}
                      handlePage={handlePage}
                      handleSorting={handleSorting}
                      afterSaveCallback={afterSaveCallback}
                      afterDeleteCallback={afterDeleteCallback}
                    />
                  )}
              </div>
            </div>
          </>
        )}
      </div>
    </LayoutWithMenu>
  );
};

export default DynamicTable;
