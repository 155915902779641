import { Controller, useFormContext } from 'react-hook-form';

import { Editor } from '@tinymce/tinymce-react';
import cx from 'classnames';
import { ComponentInputProps } from 'sdk/models/ComponentProperties';

import LabelComponent from './Label';

const Html = ({
  name,
  defaultValue = '',
  props = { readOnly: false },
}: ComponentInputProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const { readOnly, description } = props;

  return (
    <LabelComponent props={props}>
      <div data-cy={name} className={cx({ 'is-invalid': errors[name] })}>
        <Controller
          control={control}
          name={name}
          defaultValue={defaultValue}
          render={({ field }) => (
            <Editor
              value={field.value}
              disabled={readOnly}
              init={{
                directionality: 'ltr',
                height: 250,
                forced_root_block: false,
                paste_data_images: true,
                // eslint-disable-next-line no-unused-vars
                images_upload_handler(blobInfo, success) {
                  const file = blobInfo.blob();
                  const base64 = blobInfo.base64();
                  success(`data:${file.type};base64,${base64}`);
                },
                content_css: '/htmlEditor.css',
                plugins: [
                  'advlist autolink lists link image charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste code help wordcount',
                ],
                toolbar:
                  'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help | image | code',
              }}
              onEditorChange={field.onChange}
              onBlur={field.onBlur}
            />
          )}
        />

        {description && (
          <small className="form-text text-body-tertiary">{description}</small>
        )}
      </div>
    </LabelComponent>
  );
};

export default Html;
