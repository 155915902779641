import ViewConstants from 'constants/ViewConstants';

const getPropValue = (propPathOrName, obj) => {
  const names = propPathOrName.split('.');
  return names.reduce((prev, currKey) => {
    if (!prev) return undefined;

    return prev[currKey];
  }, obj);
};

const getViewGroupBy = view => {
  const groupBy: Array<string> = [];

  if (!view) {
    return undefined;
  }

  if (view.viewOptions?.groupBy) {
    const { sourceType, sourceField } = view.viewOptions.groupBy;

    if (sourceType === 'workflow') {
      groupBy.push('_workflow_current_step');
    }

    if (sourceField) {
      groupBy.push(sourceField);
    }
  }

  return groupBy;
};

const getDefaultView = (schema, currState?: any) => {
  if (currState?.view) return currState?.view;

  const view =
    schema.views?.length > 0
      ? /* schema.views?.find(item => item.isDefault) || */ schema.views[0]
      : {
          name: 'Default list',
          viewType: ViewConstants.VIEWS.LIST,
        };

  const groupBy: Array<string> = [];
  if (view.viewOptions?.groupBy) {
    const { sourceType, sourceField } = view.viewOptions.groupBy;

    if (sourceType === 'workflow') {
      groupBy.push('_workflow_current_step');
    }

    if (sourceField) {
      groupBy.push(sourceField);
    }
  }

  return view;
};

const getStateName = (entityKey, entityId) => {
  if (!entityKey) throw new Error('Reducer requires entityKey');

  if (!entityId) return entityKey;

  return `${entityKey}_${entityId}`;
};

export { getPropValue, getDefaultView, getViewGroupBy, getStateName };
