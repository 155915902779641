import ContentLoader from 'react-content-loader';

const CommentsLoader = ({
  rows,
  radius,
  ...props
}: {
  width: number | string | undefined;
  rows: number | undefined;
  radius: number | undefined;
}) => {
  const comments: any[] = [];
  const numberOfRows = rows || 0;
  const OFFSET = 83;
  for (let i = 0; i < numberOfRows; i += 1) {
    comments.push(
      <circle key={1 + i * 4} cx="20" cy={36 + i * OFFSET} r="20" />,
      <rect
        rx={radius}
        ry={radius}
        key={2 + i * 4}
        x="48"
        y={19 + i * OFFSET}
        width="221"
        height="17"
      />,
      <rect
        rx={radius}
        ry={radius}
        key={3 + i * 4}
        x="48"
        y={52 + i * OFFSET}
        width="221"
        height="17"
      />,
    );
  }
  const height = OFFSET * numberOfRows;
  return (
    <ContentLoader viewBox={`0 0 270 ${height}`} height={height} {...props}>
      {comments}
    </ContentLoader>
  );
};

CommentsLoader.defaultProps = {
  width: 270,
  radius: 3,
  rows: 4,
};

export default CommentsLoader;
