import { useContext, useEffect, useState } from 'react';

import { CheckOutlined, DeleteFilled, PlusOutlined } from '@ant-design/icons';
import { Button, Card, Form, Input, Select, Space } from 'antd';

import TsumEmpty from 'components/TsumEmpty/TsumEmpty';
import TsumPopconfirm from 'components/TsumPopconfirm/TsumPopconfirm';

import {
  WorkflowContext,
  WorkflowContextState,
} from '../WorkflowContextProvider';

type Action = {
  key?: string;
  name?: string;
  from?: string[];
  to?: string;
};

export const ActionProperty: React.FC = () => {
  const [selectedAction, setSelectedAction] = useState<Action | null>();
  const [selectedSteps, setSelectedSteps] = useState<any[]>([]);
  const [isCreate, setIsCreate] = useState<boolean>(false);
  const { flowActions, flowState } =
    useContext<WorkflowContextState>(WorkflowContext);

  const [form] = Form.useForm();

  useEffect(() => {
    flowActions.fetchSteps();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flowState.nodes]);

  useEffect(() => {
    flowActions.fetchActions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flowState.edges]);

  const fromSteps = flowState.steps.filter(
    step => !selectedSteps.includes(step.key) && !step.outcome,
  );

  const toSteps = flowState.steps.filter(
    step => !selectedSteps.includes(step.key),
  );

  const onSelectAction = action => {
    setSelectedAction(action);
    setIsCreate(false);
    form.setFieldsValue(action);
  };

  const onReset = () => {
    setIsCreate(false);
    setSelectedAction(undefined);
    form.resetFields();
  };

  const onCreate = () => {
    setSelectedSteps([]);
    setIsCreate(true);
    form.resetFields();
  };

  const onFinish = values => {
    if (isCreate) {
      const action = { ...values, key: `to${values.to}` };
      flowActions.addAction(action);
      setIsCreate(false);
      setSelectedAction(action);
      return;
    }

    flowActions.updateAction(values);
  };

  const onDelete = () => {
    flowActions.removeAction(selectedAction);
    setSelectedAction(null);
  };

  return (
    <div>
      <Card className="h-50 overflow-auto mb-3">
        {flowState.actions.map(action => (
          <Button
            type={selectedAction?.key === action.key ? 'primary' : 'dashed'}
            key={`btn_${action.key}`}
            className="m-2"
            onClick={() => onSelectAction(action)}
            ghost={selectedAction?.key === action.key}
          >
            {action.name}
          </Button>
        ))}
      </Card>
      <div className="h-50">
        {(selectedAction || isCreate) && (
          <Form
            name="actionForm"
            form={form}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            style={{ maxWidth: 600 }}
            onFinish={onFinish}
            autoComplete="off"
            initialValues={{ items: [{}] }}
          >
            <Card
              size="small"
              className="h-100"
              title={selectedAction?.name || ''}
              extra={
                <TsumPopconfirm
                  title="Үйлдэлийг устгах"
                  description="Та энэ үйлдлийг устгахдаа итгэлтэй байна уу?"
                  onConfirm={onDelete}
                >
                  <Button
                    htmlType="button"
                    icon={<DeleteFilled />}
                    danger
                    size="small"
                  ></Button>
                </TsumPopconfirm>
              }
            >
              <Form.Item<Action> label="Дугаар" name="key">
                <Input disabled />
              </Form.Item>
              <Form.Item<Action>
                label="Нэр"
                name="name"
                rules={[
                  { required: true, message: 'Үйлдэлийн нэрийг оруулна уу!' },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item<Action>
                name="from"
                label="Алхамууд (from)"
                rules={[
                  { required: true, message: 'Эхлэх алхамийг сонгоно уу!' },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (value && value.includes(getFieldValue('to'))) {
                        const step = flowState.steps.find(
                          step => step.key === getFieldValue('to'),
                        );

                        return Promise.reject(
                          new Error(
                            `Алхам өөрлүүгээ шилжих боломжгүй! ${step.name}->${step.name}`,
                          ),
                        );
                      }

                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Select
                  mode="multiple"
                  placeholder="Үйлдэл эхлэх алхамуудыг сонгоно уу"
                  value={selectedSteps}
                  onChange={setSelectedSteps}
                  options={fromSteps.map(item => ({
                    value: item.key,
                    label: item.name,
                  }))}
                />
              </Form.Item>
              <Form.Item
                name="to"
                label="Алхам (to)"
                rules={[
                  {
                    required: true,
                    message: 'Шилжиж очих алхамыг сонгоно уу!',
                  },
                  ({}) => ({
                    validator(_, value) {
                      if (
                        isCreate &&
                        value &&
                        flowState.actions.find(action => action.to === value)
                      ) {
                        const step = flowState.steps.find(
                          step => step.key === value,
                        );
                        return Promise.reject(
                          new Error(
                            `"${step.name}" гэсэн алхамруу шилжих үйлдэл бүртгэлтэй байна`,
                          ),
                        );
                      }

                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Select
                  placeholder="Шилжиж очих алхамаа сонгоно уу"
                  value={selectedSteps}
                  onChange={setSelectedSteps}
                  disabled={!isCreate}
                  options={toSteps.map(item => ({
                    value: item.key,
                    label: item.name,
                  }))}
                />
              </Form.Item>
              <Form.Item wrapperCol={{ span: 16, offset: 8 }}>
                <Space>
                  <Button htmlType="submit" icon={<CheckOutlined />}>
                    {isCreate ? 'Үүсгэх' : 'Өөрчлөх'}
                  </Button>
                  <Button htmlType="button" onClick={onReset}>
                    Болих
                  </Button>
                </Space>
              </Form.Item>
            </Card>
          </Form>
        )}
        {!selectedAction && !isCreate && (
          <Card className="h-100">
            <TsumEmpty
              description={
                <span>
                  Та үйлдэл сонгоогүй байна. Хэрэв үйлдэл үүсгэх бол доорх
                  товчийг дарж үүсгэнэ үү
                </span>
              }
            >
              <Button icon={<PlusOutlined />} onClick={onCreate}>
                Шинэ үйлдэл
              </Button>
            </TsumEmpty>
          </Card>
        )}
      </div>
    </div>
  );
};
