import { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import ReactSelect from 'react-select';

import cx from 'classnames';
import { ComponentInputProps } from 'sdk/models/ComponentProperties';

import LabelComponent from './Label';

const StaticSelect = ({
  name,
  defaultValue = '',
  props = { readOnly: false, placeholder: '' },
  settings = { options: [] },
}: ComponentInputProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const { options } = settings;
  const { readOnly, placeholder } = props;

  const transformedDefaultValue = useMemo(
    () =>
      defaultValue
        ? {
            value: defaultValue,
            label: defaultValue,
          }
        : undefined,
    [defaultValue],
  );

  return (
    <LabelComponent props={props}>
      <Controller
        control={control}
        defaultValue={transformedDefaultValue}
        name={name}
        render={({ field }) => {
          return (
            <ReactSelect
              {...field}
              styles={{
                control: provided => ({
                  ...provided,
                  borderColor: errors[name] ? 'red' : '#ced4da',
                  minHeight: '3rem',
                }),
                menuPortal: base => ({ ...base, zIndex: 9999 }),
              }}
              className={cx('single-dropdown', { 'is-invalid': errors[name] })}
              isDisabled={readOnly}
              isClearable
              menuPortalTarget={document.body}
              placeholder={placeholder}
              options={options}
              id={field.name}
              closeMenuOnSelect={true}
            />
          );
        }}
      />

      {props.description && (
        <small className="form-text text-muted">{props.description}</small>
      )}
    </LabelComponent>
  );
};

export default StaticSelect;
