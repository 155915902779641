import { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FiMinusCircle } from 'react-icons/fi';

import './File.scss';
import { InboxOutlined } from '@ant-design/icons';
import { Upload } from 'antd';
import { ComponentInputProps } from 'sdk/models/ComponentProperties';

import { useEntityContext } from '../../../contexts/EntityContext';
import FileIcon from '../../FileIcon/FileIcon';
import PopConfirmation from '../PopConfirmation/PopConfirmation';
import LabelComponent from './Label';

const { Dragger } = Upload;

const File = ({
  name,
  entityKey,
  entityId = 0,
  defaultValue = '',
  props = { readOnly: false },
  settings = { accept: '' },
}: ComponentInputProps) => {
  const [progress, setProgress] = useState(0);
  const [entityValue, setEntityValue] = useState(defaultValue || undefined);
  const { accept } = settings;
  const { readOnly } = props;
  const [file, setFile] = useState<any>();
  const {
    actions: { insertFileUpload },
  } = useEntityContext();

  const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];

  const { register, unregister, getValues, setValue } = useFormContext();

  const { t } = useTranslation();

  const handleFileUpload = async ({ file }) => {
    const data = new FormData();

    data.append('files', file);

    const previousValue = getValues(name);
    setValue(name, undefined);
    setProgress(0);

    const response = await insertFileUpload(
      entityKey || '',
      name,
      data,
      event => {
        setProgress(Math.round((95 * event.loaded) / event.total));
      },
    );
    setProgress(100);
    setFile(file);
    if (!response) {
      setValue(name, previousValue);
      return false;
    }

    setValue(name, JSON.stringify(response));
    return true;
  };

  const deleteFile = async () => {
    setValue(name, '');
    setEntityValue(undefined);
  };

  useEffect(() => {
    register(name);
    setValue(name, defaultValue ? JSON.stringify(defaultValue) : '');
    return () => {
      unregister(name);
    };
  }, [register, unregister, name, setValue, defaultValue]);

  return (
    <LabelComponent props={props}>
      {!readOnly && (
        <>
          <Dragger
            showUploadList={false}
            accept={accept}
            maxCount={1}
            name="file"
            multiple={false}
            customRequest={handleFileUpload}
          >
            {file ? (
              <div key={file.name} className="text-center mb-3">
                {(!validImageTypes.includes(file.type) && (
                  <div>
                    <FileIcon type={file.type} />
                    &nbsp;{file.name}
                  </div>
                )) || (
                  <img
                    className="thumbnailImage"
                    src={URL.createObjectURL(file)}
                    alt={file.name}
                  />
                )}
              </div>
            ) : (
              <>
                <div className="pr-3">
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                </div>
                <p className="ant-upload-text">
                  {t('entity.attachment.click-here')}
                </p>
              </>
            )}
          </Dragger>

          <div className="progress">
            <div
              className="progress-bar progress-bar-info progress-bar-striped"
              role="progressbar"
              aria-valuenow={progress}
              aria-valuemin={0}
              aria-valuemax={100}
              style={{ width: `${progress}%` }}
            >
              {progress}%
            </div>
          </div>
        </>
      )}
      {entityValue && (
        <div className="mt-2 d-flex align-items-center justify-content-between">
          <div>
            <FileIcon type={entityValue.mimetype} />
            <a
              href={
                entityValue.isPublic
                  ? entityValue.url
                  : `/api/${entityKey}/${entityId}/storage/${name}`
              }
              className="ml-2 imageLink"
              target="_blank"
              rel="noopener noreferrer"
            >
              {entityValue.name}
            </a>
          </div>
          {!readOnly && (
            <div>
              <PopConfirmation
                title={t('entity.modal.deleting-confirm-title')}
                description={t('entity.modal.deleting-confirm-file-message')}
                body={
                  <button type="button" className="btn btn-sm">
                    <FiMinusCircle className="text-danger" />
                  </button>
                }
                confirmAction={deleteFile}
              />
            </div>
          )}
        </div>
      )}
    </LabelComponent>
  );
};

export default File;
