import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { LuWorkflow, LuNetwork } from 'react-icons/lu';
import { useParams } from 'react-router-dom';

import moment from 'moment';

import ActionLabels from '../../../../constants/ActionLabels';
import { useEntityContext } from '../../../../contexts/EntityContext';
import { getBadgeColor, getOutcomeLabel } from '../../../../utils/worflowUtils';
import WorkflowStepper from '../../../WorkflowStepper/WorkflowStepper';

type IWorkflowRenderer = {
  colDef: any;
  value: any;
  api: any;
  node: any;
  data: {
    id?: number;
    _workflow_definition?: string;
    _workflow_outcome?: number;
    ui_component_options: {
      readOnly?: boolean;
    };
  };
};

const WorkflowRenderer = (props: IWorkflowRenderer) => {
  const params = useParams<{ entityKey: string }>();
  const { loadEntitySchema } = useEntityContext();
  const { _workflow_definition, _workflow_outcome, id } = props.data;
  const [workflow, setWorkflow] = useState<any>(null);
  const [showWorkflow, setShowWorkflow] = useState(false);
  const entityKey =
    props.colDef.cellRendererParams?.entityKey || params.entityKey;
  const emptyValue = <span />;

  if (!_workflow_definition) return emptyValue;

  const wfDef = JSON.parse(_workflow_definition);
  const stepNumber = parseInt(props.value, 10);
  const step = wfDef.steps.find(item => item.order === stepNumber);

  if (!step) return emptyValue;

  const toggleWorkflow = async () => {
    if (!workflow) {
      const schema = await loadEntitySchema(entityKey, id);
      setWorkflow(schema.workflow);
    }
    props.node.setRowHeight(!showWorkflow ? 80 : 45);
    props.api.onRowHeightChanged();
    setShowWorkflow(!showWorkflow);
  };

  moment.locale(process.env.REACT_APP_LANG);
  const renderOutcomeMessage = () => (
    <div className="border-left pl-3">
      {workflow.historyGrouped[workflow.historyGrouped.length - 1].items.map(
        historyItem => (
          <div
            key={`${historyItem.id}${historyItem.date}`}
            className="line-height-initial"
          >
            Энэ бүртгэл <span>{ActionLabels[historyItem.workflow_action]}</span>
            {' : '}
            <span className="font-weight-bold">
              {historyItem.ownerName || historyItem.created_by || 'BOT'}{' '}
            </span>{' '}
            {moment(historyItem.date).local().fromNow()}
          </div>
        ),
      )}
    </div>
  );

  return (
    <>
      <div className="align-items-center">
        <Button
          className="no-shadow"
          variant="link"
          onClick={async ev => {
            ev.stopPropagation();
            await toggleWorkflow();
          }}
        >
          {showWorkflow && workflow ? <LuWorkflow /> : <LuNetwork />}
        </Button>
        <span
          className={`badge ${getBadgeColor(_workflow_outcome, stepNumber)}`}
        >
          {_workflow_outcome
            ? getOutcomeLabel(wfDef, _workflow_outcome)
            : step.name}
        </span>
      </div>
      {showWorkflow && workflow && (
        <div className="d-flex align-items-center">
          <WorkflowStepper workflow={workflow} noAlert />
          {_workflow_outcome && renderOutcomeMessage()}
        </div>
      )}
    </>
  );
};

export default WorkflowRenderer;
