import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
  AiOutlineHistory,
  AiOutlineComment,
  AiOutlineInfoCircle,
} from 'react-icons/ai';
import { ImAttachment } from 'react-icons/im';

import cx from 'classnames';

<AiOutlineComment size={20} />;

import PopConfirmExit from 'components/PopConfirmExit/PopConfirmExit';

import { useDialogManager } from '../../../contexts/DialogManagerContext';
import { useEntityContext } from '../../../contexts/EntityContext';
import { FormMode } from '../../../core/formMode';
import ButtonLoader from '../../Loaders/ButtonLoader';
import WorkflowInfo from '../../WorkflowInfo/WorkflowInfo';
import WorkflowStepper from '../../WorkflowStepper/WorkflowStepper';
import FormActionBar from '../FormActionBar/FormActionBar';
import FormDrawerBody from '../FormDrawerBody/FormDrawerBody';

import s from './FormDrawer.module.scss';

const FormDrawer = ({
  show,
  handleClose,
  entityId,
  entityKey,
  modalLevel,
}: {
  show?: boolean;
  entityId: number;
  entityKey: string;
  handleClose: () => void;
  modalLevel: number;
}) => {
  const { getEntityState } = useEntityContext();
  const { t } = useTranslation();
  const { getDialog, closeDialog } = useDialogManager();

  const [actionBarRef, setActionBarRef] = useState(null);

  const [mainTab, setMainTab] = useState('details');

  const onChangeMainTab = changedMainTab => {
    setMainTab(changedMainTab);
  };
  const entityState = getEntityState(entityKey, entityId);

  const createOrEditMode = entityId ? FormMode.edit : FormMode.create;
  const formMode = entityState?.formData?._workflow_outcome
    ? FormMode.readonly
    : createOrEditMode;

  const entitySchema = entityState?.schema;

  const onHide = () => {
    const dialog = getDialog();

    if (
      dialog.params.beforeHideCallback &&
      !dialog.params.beforeHideCallback()
    ) {
      return false;
    }

    handleClose();

    return true;
  };

  return (
    <Modal
      enforceFocus={false}
      animation={true}
      show={show}
      onHide={onHide}
      size="xl"
      backdrop="static"
      backdropClassName={`modal-level-${modalLevel}`}
      className={cx(`modal-level-${modalLevel}`, 'modal-right')}
    >
      <div className={cx(s.header, 'entity-content-padding')}>
        <PopConfirmExit
          onConfirm={() => closeDialog()}
          body={
            <button
              type="button"
              className={cx(`btn-close`, s.closeButton)}
              aria-label="Close"
              data-cy="btn-close-window"
              onClick={onHide}
            >
              <span className="sr-only">{t('entity.modal.close')}</span>
            </button>
          }
        />

        <div className="d-flex justify-content-between align-items-center w-100 flex-wrap">
          <div>
            <FormActionBar onRefLoaded={el => setActionBarRef(el)} />
          </div>

          {formMode !== FormMode.create && (
            <div className="mx-5 d-flex align-items-center">
              <div
                className={cx(s.icon, mainTab === 'details' && s.iconActive)}
                onClick={() => onChangeMainTab('details')}
              >
                <AiOutlineInfoCircle size={30} />
              </div>
              {entitySchema?.allowComments && (
                <div
                  className={cx(s.icon, mainTab === 'comments' && s.iconActive)}
                  onClick={() => onChangeMainTab('comments')}
                >
                  <AiOutlineComment size={30} />
                </div>
              )}
              {entitySchema?.hasAttachments && (
                <div
                  className={cx(
                    s.icon,
                    mainTab === 'attachments' && s.iconActive,
                  )}
                  onClick={() => onChangeMainTab('attachments')}
                >
                  <ImAttachment size={30} />
                </div>
              )}
              <div
                className={cx(s.icon, mainTab === 'history' && s.iconActive)}
                onClick={() => onChangeMainTab('history')}
              >
                <AiOutlineHistory size={30} />
              </div>
            </div>
          )}
        </div>
      </div>

      <div className={s.subHeader}>
        {!entityState?.isLoading ? (
          <div className="w-100">
            <h4 className="m-0">
              {entityId
                ? `${entityId}. ${entityState?.schema?.name}  (${t(
                    'entity.modal.edit',
                  )})`
                : `${entityState?.schema?.name} (${t('entity.modal.create')})`}
            </h4>

            <div className="d-flex align-items-center h6">
              {entityState?.schema?.hasWorkflow &&
                formMode !== FormMode.create && (
                  <WorkflowStepper
                    workflow={entityState?.schema?.workflow}
                    noAlert
                  />
                )}
            </div>

            {entityState?.schema?.workflow?.currentStep && (
              <WorkflowInfo workflow={entityState?.schema.workflow} />
            )}
          </div>
        ) : (
          <>
            <ButtonLoader width={300} />
            <div className="d-flex">
              <ButtonLoader width={30} className="mr-2" />
              <ButtonLoader width={50} />
            </div>
          </>
        )}
      </div>

      <FormDrawerBody
        entityKey={entityKey}
        entityId={entityId}
        actionBarRef={actionBarRef}
        mainTab={mainTab}
      />
    </Modal>
  );
};

export default FormDrawer;
