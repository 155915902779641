import { useEffect, useState } from 'react';

import { Line } from '@ant-design/plots';

import DashboardBar from 'components/DashboardBar/DashboardBar';
import DashboardPie from 'components/DashboardPie/DashboardPie';
import { GraphTypes } from 'constants/GraphConstants';

import { post } from '../../core/fetch';
import DashboardList from 'components/DashboardList/DashboardList';

const DashboardGraph = ({
  graphId,
  appendPadding,
  radius,
}: {
  graphId: number;
  appendPadding?: number;
  radius?: number;
}) => {
  const [graphData, setGraphData] = useState<any>();

  const fetchGraph = async () => {
    const result = await post(`/custom/get-graph`, { graphId });
    setGraphData(result);
  };
  useEffect(() => {
    fetchGraph();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {graphData && (
        <>
          <div className="h6">{graphData.graph.name}</div>
          {graphData.graph.graph_type === GraphTypes.PIE && (
            <DashboardPie
              appendPadding={appendPadding}
              data={graphData.data}
              angleField={graphData.graph.value}
              colorField={graphData.graph.label}
              radius={radius}
            />
          )}
          {graphData.graph.graph_type === GraphTypes.BAR && (
            <DashboardBar
              data={graphData.data}
              xField={graphData.graph.label}
              yField={graphData.graph.value}
            />
          )}
          {graphData.graph.graph_type === GraphTypes.LINE && (
            <Line
              data={graphData.data}
              xField={graphData.graph.label}
              yField={graphData.graph.value}
            />
          )}
          {graphData.graph.graph_type === GraphTypes.LIST && (
            <DashboardList
              data={graphData.data}
              labelField={graphData.graph.label}
              valueField={graphData.graph.value}
            />
          )}
        </>
      )}
    </>
  );
};
export default DashboardGraph;
