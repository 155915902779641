import { useFormContext } from 'react-hook-form';

import cx from 'classnames';
import { ComponentInputProps } from 'sdk/models/ComponentProperties';

import LabelComponent from './Label';

const Numeric = ({
  name,
  defaultValue = '',
  props = { readOnly: false },
}: ComponentInputProps) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const placeholder = props?.placeholder;
  const { readOnly } = props;

  const inputProps = {
    ...register(name),
    className: cx('form-control', { 'is-invalid': errors[name] }),
    placeholder,
    id: name,
    readOnly,
    defaultValue,
  };
  return (
    <LabelComponent props={props}>
      <input type="number" {...inputProps} />
      {props.description && (
        <small className="form-text text-body-tertiary">
          {props.description}
        </small>
      )}
    </LabelComponent>
  );
};

export default Numeric;
