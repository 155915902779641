import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ReactSelect from 'react-select';

const WorkflowStepField = ({
  name,
  defaultValue,
  steps,
  labelForFinalised,
  labelForRejected,
}: {
  name: string;
  defaultValue?: any;
  steps: any[];
  labelForFinalised?: string;
  labelForRejected?: string;
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const { t } = useTranslation();

  const options = [
    ...steps.map(workflowStep => ({
      label: workflowStep.name,
      value: {
        _workflow_current_step: workflowStep.order,
        _workflow_outcome: null,
      },
    })),
    {
      label: labelForFinalised,
      value: { _workflow_outcome: 1 },
    },
    {
      label: labelForRejected,
      value: { _workflow_outcome: 2 },
    },
  ];

  return (
    <Controller
      render={({ field }) => (
        <ReactSelect
          {...field}
          styles={{
            control: provided => ({
              ...provided,
              borderColor: errors[name] ? 'red' : '#ced4da',
              borderRadius: 0,
              minHeight: '3rem',
            }),
            menuPortal: base => ({ ...base, zIndex: 9999 }),
          }}
          menuPortalTarget={document.body}
          placeholder={t('entity.filter.select')}
          options={options}
          id={name}
          closeMenuOnSelect
          isMulti
        />
      )}
      control={control}
      defaultValue={defaultValue}
      name={name}
    />
  );
};

WorkflowStepField.defaultProps = {
  steps: [],
  defaultValue: '',
  labelForFinalised: 'Approved',
  labelForRejected: 'Rejected',
};

export default WorkflowStepField;
