import { Position, MarkerType } from 'reactflow';
const WORKFLOW_OUTCOMES = {
  success: 1,
  failure: 2,
};

export enum WorkflowSteps {
  start = 'start',
  success = 'success',
  failure = 'failure',
}

export const generateEdge = action => {
  return {
    id: `to${action.to}`,
    data: action,
    source: action.from,
    target: action.to,
    label: action.name,
    labelBgPadding: [8, 4],
    labelBgBorderRadius: 4,
  };
};

export const convertToNodes = ({ steps, outcome }) => {
  const stepsNodes = steps.map(step => ({
    id: step.key,
    data: {
      label: step.name,
      isStep: true,
      tableData: {
        key: step.key,
        name: step.name,
        order: step.order,
        ownershipDetails: step.ownershipDetails,
      },
    },
    position: step.position,
    type: 'stepNode',
    targetPosition: Position.Left,
    sourcePosition: Position.Right,
  }));

  const outcomes = Object.entries(outcome).map(
    ([key, value]: [key: string, value: any]) => {
      const outcomeValue = WORKFLOW_OUTCOMES[key];

      return {
        id: key,
        type: 'stepNode',
        data: {
          label: value.label,
          tableData: {
            key,
            name: value.label,
            outcome: outcomeValue,
          },
        },
        targetPosition: Position.Left,
        position: value.position,
      };
    },
  );
  const nodes = [
    {
      id: WorkflowSteps.start,
      type: 'startNode',
      data: { label: 'Start' },
      position: { x: 0, y: 0 },
      sourcePosition: Position.Right,
    },
    ...stepsNodes,
    ...outcomes,
  ];
  return nodes;
};

export const convertToEdges = ({ actions, nodes }) => {
  const firstStepId = (nodes[1] || {}).id;
  const lines: any[] = [
    {
      id: `to`,
      source: WorkflowSteps.start,
      target: firstStepId,
      label: 'Шинэ',
      targetHandle: 'left',
    },
  ];

  for (const action of actions) {
    for (const connection of action.connections) {
      lines.push({
        ...connection,
        id: `${connection.source}to${connection.target}`,
        label: action.name,
        markerEnd: { type: MarkerType.ArrowClosed },
      });
    }
  }

  return lines;
};

export const convertToActions = edges => {
  const actionEdges = edges
    .filter(edge => edge.source !== WorkflowSteps.start)
    .map(edge => ({
      key: edge.id,
      name: edge.label,
      from: [edge.source],
      to: edge.target,
    }));
  const actions: any[] = [];
  for (const edge of actionEdges) {
    const action = actions.find(action => action.to === edge.to);
    if (action) {
      action.from = [...action.from, ...edge.from];
    } else {
      actions.push(edge);
    }
  }
  return actions;
};

export const convertToSteps = nodes => {
  const steps = nodes
    .filter(node => node.id !== WorkflowSteps.start)
    .map(node => ({
      ...node.tableData,
      key: node.id,
      name: node.data.label,
      position: node.position,
      type: 'stepNode',
      outcome: node.data.isStep ? null : node.id,
    }));
  return steps;
};

const convertToStep = step => {
  switch (step) {
    case 'success':
      return { outcome: 1 };
    case 'failure':
      return { outcome: 2 };
    default:
      return { step };
  }
};

export const convertToJson = (nodes, edges) => {
  const actions = convertToActions(edges);

  const steps = nodes
    .filter(node => node.data.isStep)
    .map(node => ({
      ...node.data.tableData,
      key: node.id,
      name: node.data.label,
      position: node.position,
      ownershipDetails: {
        userRoles: [],
        userGroups: [],
      },
    }));

  const successNode = nodes.find(node => node.id === 'success');
  const failureNode = nodes.find(node => node.id === 'failure');
  const outcome = {
    success: {
      label: successNode?.data?.label,
      position: successNode?.position,
    },
    failure: {
      label: failureNode?.data?.label,
      position: failureNode?.position,
    },
  };

  return {
    actions: actions.map(action => {
      const connections = edges
        .filter(
          edge =>
            edge.target === action.to && action.from.includes(edge.source),
        )
        .map(edge => ({
          source: edge.source,
          target: edge.target,
          sourceHandle: edge.sourceHandle,
          targetHandle: edge.targetHandle,
          type: edge.type,
        }));
      return {
        ...action,
        to: {
          ...convertToStep(action.to),
        },
        connections,
      };
    }),
    steps,
    outcome,
  };
};
