import { memo, useMemo, useState } from 'react';

import 'react-calendar-timeline/lib/Timeline.css';
import cx from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import { getPropValue } from 'utils/formUtils';

import WeeklyItemRenderer from './WeekylyItemRenderer';
import WeeklyPager from './WeekyPager';

import s from './WeeklyView.module.scss';

const transformWeeklyViewItems = (data, options) =>
  data.map(item => ({
    id: item.id,
    title: options.title
      .map(prop => getPropValue(prop, item) || '')
      .join(' ')
      .trim(),
    description: options.description
      .map(prop => getPropValue(prop, item) || '')
      .join(' ')
      .trim(),
    user_fullname: getPropValue(`${options.user}.full_name`, item),
    user_shortname:
      getPropValue(`${options.user}.last_name`, item).charAt(0) +
      getPropValue(`${options.user}.first_name`, item).charAt(0),
    value: getPropValue(options.value, item),
    date: getPropValue(options.date, item),
    color: getPropValue(options.color, item),
  }));

const TotalValue = ({ records }) => {
  const total = records.reduce((accum, item) => accum + item.value, 0);
  return (
    <>
      {total > 0 && (
        <div className="text-center text-gray px-1 font-weight-bold bg-light border-top">
          {total}
        </div>
      )}
    </>
  );
};

TotalValue.propTypes = {
  records: PropTypes.array.isRequired,
};

const WeeklyView = ({ data, options, onItemClick }) => {
  const [weeks, setWeeks] = useState([]);

  const items = useMemo(
    () => transformWeeklyViewItems(data, options),
    [data, options],
  );

  const handlePageChanged = ({ weekdays }) => {
    setWeeks(weekdays);
  };

  return (
    <div className="border-top border-gray-dark pt-3">
      <WeeklyPager onPageChanged={handlePageChanged} />
      <div className="w-100 px-3">
        <div className={cx('row border-left border-gray-light', s.weekly)}>
          {weeks.map((weekday: any) => (
            <div
              className="col w-100 border-right border-gray-light"
              key={weekday.format('YYYYMMDD')}
            >
              <div className="text-center">
                <div>{weekday.format('dddd')}</div>
                <h4 className="font-weight-bold text-dark py-1">
                  {weekday.format('D')}
                </h4>
              </div>
              <div className="border-top pt-3 border-gray-light">
                {items
                  .filter(
                    item =>
                      moment(item.date).format('YYYY.MM.DD') ===
                      weekday.format('YYYY.MM.DD'),
                  )
                  .map(item => (
                    <WeeklyItemRenderer
                      item={item}
                      onItemClick={onItemClick}
                      key={item.id}
                    />
                  ))}
                <TotalValue
                  records={items.filter(
                    item =>
                      moment(item.date).format('YYYY.MM.DD') ===
                      weekday.format('YYYY.MM.DD'),
                  )}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

WeeklyView.propTypes = {
  data: PropTypes.array.isRequired,
  options: PropTypes.shape({
    title: PropTypes.array.isRequired,
    description: PropTypes.array.isRequired,
    user: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    value: PropTypes.string,
    color: PropTypes.string,
  }).isRequired,
  onItemClick: PropTypes.func.isRequired,
};

export default memo(WeeklyView);
