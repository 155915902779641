import { useContext, useEffect } from 'react';

import { Card, Form, Input, Radio } from 'antd';

import TsumEmpty from 'components/TsumEmpty/TsumEmpty';

import {
  WorkflowContext,
  WorkflowContextState,
} from '../WorkflowContextProvider';

type WorkflowEdge = {
  direction?: string;
  name?: string;
  sourceHandle?: string;
  targetHandle?: string;
  type?: string;
};

export const EdgeProperty: React.FC = () => {
  const {
    flowActions,
    flowState: { selectedEdge },
  } = useContext<WorkflowContextState>(WorkflowContext);
  const [form] = Form.useForm();

  useEffect(() => {
    if (!selectedEdge) {
      form.resetFields();
      return;
    }
    form.setFieldsValue({
      ...selectedEdge,
      direction: `${selectedEdge.source}->${selectedEdge.target}`,
      name: selectedEdge.label,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEdge]);

  const update = value => {
    flowActions.updateEdge({
      ...selectedEdge,
      ...value,
    });
  };

  return (
    <>
      {selectedEdge && (
        <Card className="h-100">
          <Form
            name="edgeForm"
            form={form}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            style={{ maxWidth: 600 }}
            autoComplete="off"
            initialValues={{ items: [{}] }}
          >
            <Form.Item<WorkflowEdge> label="Холбоос" name="direction">
              <Input readOnly />
            </Form.Item>
            <Form.Item<WorkflowEdge> label="Нэр" name="name">
              <Input readOnly />
            </Form.Item>
            <Form.Item<WorkflowEdge> label="Алхам (from)" name="sourceHandle">
              <Radio.Group
                size="small"
                onChange={e => update({ sourceHandle: e.target.value })}
              >
                <Radio.Button value="right"> Баруун </Radio.Button>
                <Radio.Button value="left"> Зүүн </Radio.Button>
                <Radio.Button value="top"> Дээд </Radio.Button>
                <Radio.Button value="bottom"> Доод </Radio.Button>
              </Radio.Group>
            </Form.Item>
            <Form.Item<WorkflowEdge> label="Алхам (to)" name="targetHandle">
              <Radio.Group
                size="small"
                onChange={e => update({ targetHandle: e.target.value })}
              >
                <Radio.Button value="right"> Баруун </Radio.Button>
                <Radio.Button value="left"> Зүүн </Radio.Button>
                <Radio.Button value="top"> Дээд </Radio.Button>
                <Radio.Button value="bottom"> Доод </Radio.Button>
              </Radio.Group>
            </Form.Item>
            <Form.Item<WorkflowEdge> label="Төрөл" name="type">
              <Radio.Group
                size="small"
                onChange={e => update({ type: e.target.value })}
              >
                <Radio.Button value="default"> bezier </Radio.Button>
                <Radio.Button value="step"> step </Radio.Button>
                <Radio.Button value="smoothstep"> smoothstep </Radio.Button>
                <Radio.Button value="straight"> straight </Radio.Button>
              </Radio.Group>
            </Form.Item>
          </Form>
        </Card>
      )}
      {!selectedEdge && (
        <TsumEmpty
          description={
            <span>
              Та алхам сонгоогүй байна. Хэрэв шинэ алхам үүсгэх бол доорх
              товчийг дарж үүсгэнэ үү
            </span>
          }
        ></TsumEmpty>
      )}
    </>
  );
};
