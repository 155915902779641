import './TableHistoryList.scss';
import TableHistoryListItem from '../TableHistoryListItem/TableHistoryListItem';

const TableHistoryList = ({
  histories,
  schema,
}: {
  histories?: any[];
  schema: any;
}) => {
  if (!histories?.length) {
    return <h6>there is no history to show</h6>;
  }

  return (
    <div className="TableHistoryList">
      {histories.map(history => (
        <div className="history" key={history.id}>
          <TableHistoryListItem history={history} schema={schema} />
        </div>
      ))}
    </div>
  );
};

export default TableHistoryList;
