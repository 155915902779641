import { getDefaultView, getStateName } from 'utils/formUtils';

const ActionTypes = {
  FETCH_TABLE_FAILURE: 'An error ocorred when tried to load this page.',

  RESET_TABLE_DATA_REQUEST: '[Table] Reset Data Request',
  RESET_TABLE_FORM_DATA_REQUEST: '[Table] Reset Form Data Request',
  RESET_TABLE_STATES_REQUEST: '[Table] Reset States Success',

  FETCH_ALL_BY_TABLE_KEY_REQUEST: '[Table] Fetch All By Key Request',
  FETCH_ALL_BY_TABLE_KEY_SUCCESS: '[Table] Fetch All By Key Success',

  FETCH_ALL_GROUPED_BY_TABLE_KEY_SUCCESS:
    '[Table] Fetch All Grouped By Key Success',

  FETCH_ONE_BY_TABLE_KEY_REQUEST: '[Table] Fetch One By Key Request',
  FETCH_ONE_BY_TABLE_KEY_SUCCESS: '[Table] Fetch One By Key Success',

  SET_TABLE_VIEW: '[Table] Set View',

  FETCH_TABLE_ATTACHMENT_REQUEST: '[Table] Fetch Attachment Request',
  FETCH_TABLE_ATTACHMENT_SUCCESS: '[Table] Fetch Attachment Success',

  CREATE_TABLE_ATTACHMENT_REQUEST: '[Table] Insert Attachment Request',
  CREATE_TABLE_ATTACHMENT_SUCCESS: '[Table] Insert Attachment Success',
  CREATE_TABLE_ATTACHMENT_FAIL: '[Table] Insert Attachment Fail',

  DELETE_TABLE_ATTACHMENT_REQUEST: '[Table] Delete Attachment Request',
  DELETE_TABLE_ATTACHMENT_SUCCESS: '[Table] Delete Attachment Success',
  DELETE_TABLE_ATTACHMENT_FAIL: '[Table] Delete Attachment Fail',

  FETCH_TABLE_HISTORY_REQUEST: '[Table] Fetch History Request',
  FETCH_TABLE_HISTORY_SUCCESS: '[Table] Fetch History Success',

  FETCH_TABLE_COMMENT_REQUEST: '[Table] Fetch Comment Request',
  FETCH_TABLE_COMMENT_SUCCESS: '[Table] Fetch Comment Success',

  CREATE_TABLE_COMMENT_REQUEST: '[Table] Insert Comment Request',
  CREATE_TABLE_COMMENT_SUCCESS: '[Table] Insert Comment Success',
  CREATE_TABLE_COMMENT_FAIL: '[Table] Insert Comment Fail',

  CREATE_TABLE_FILE_UPLOAD_REQUEST: '[File] Insert File Upload Request',
  CREATE_TABLE_FILE_UPLOAD_SUCCESS: '[File] Insert File Upload Success',
  CREATE_TABLE_FILE_UPLOAD_FAIL: '[File] Insert File Upload Fail',

  CREATE_TABLE_REQUEST: '[Table] Create Request',
  CREATE_TABLE_SUCCESS: '[Table] Create Success',
  CREATE_TABLE_FAIL: '[Table] Create Fail',

  UPDATE_TABLE_REQUEST: '[Table] Update Request',
  UPDATE_TABLE_FAIL: '[Table] Delete Fail',
  UPDATE_TABLE_SUCCESS: '[Table] Update Success',

  DELETE_TABLE_REQUEST: '[Table] Delete Request',
  DELETE_TABLE_SUCCESS: '[Table] Delete Success',
  DELETE_TABLE_FAIL: '[Table] Delete Fail',

  EXPORT_REQUEST: '[Table] Export Request',
  EXPORT_SUCCESS: '[Table] Export Success',
  EXPORT_FAIL: '[Table] Export Fail',

  /** ********* WORKFLOW ACTIONS ********** */

  CREATE_TABLE_WORKFLOW_REQUEST: '[Table] Create Workflow Request',
  CREATE_TABLE_WORKFLOW_SUCCESS: '[Table] Create Workflow Success',
  CREATE_TABLE_WORKFLOW_FAIL: '[Table] Create Workflow Fail',

  TABLE_NEXT_STEP_REQUEST: '[Table] Next Step Request',
  TABLE_NEXT_STEP_SUCCESS: '[Table] Next Step Success',
  TABLE_NEXT_STEP_FAIL: '[Table] Next Step Fail',

  TABLE_RETURN_STEP_REQUEST: '[Table] Return Step Request',
  TABLE_RETURN_STEP_SUCCESS: '[Table] Return Step Success',
  TABLE_RETURN_STEP_FAIL: '[Table] Return Step Fail',

  TABLE_REJECT_REQUEST: '[Table] Reject Request',
  TABLE_REJECT_SUCCESS: '[Table] Reject Success',
  TABLE_REJECT_FAIL: '[Table] Reject Fail',

  TABLE_TAKE_OWNERSHIP_REQUEST: '[Table] Take Ownership Request',
  TABLE_TAKE_OWNERSHIP_SUCCESS: '[Table] Take Ownership Success',
  TABLE_TAKE_OWNERSHIP_FAIL: '[Table] Take Ownership Fail',

  TABLE_APPROVE_REQUEST: '[Table] Approve Request',
  TABLE_APPROVE_SUCCESS: '[Table] Approve Success',
  TABLE_APPROVE_FAIL: '[Table] Approve Fail',

  FETCH_AVAILABLE_WORKFLOW_STEPS_REQUEST:
    '[Table] Available Workflow Steps Request',
  FETCH_AVAILABLE_WORKFLOW_STEPS_SUCCESS:
    '[Table] Available Workflow Steps Success',

  /** ********* END WORKFLOW ACTIONS ********** */
};

const initialState = {
  // isLoading: false,
  // error: null,
  // schema: undefined,
  // data: undefined,
  // created: false,
  // updated: false,
  // deleted: false,
  // id: null,
};

const reducer = (state, action) => {
  const stateKey = getStateName(
    action.payload?.entityKey,
    action.payload?.entityId,
  );

  switch (action.type) {
    case ActionTypes.FETCH_TABLE_FAILURE:
    case ActionTypes.TABLE_NEXT_STEP_FAIL:
    case ActionTypes.TABLE_RETURN_STEP_FAIL:
    case ActionTypes.TABLE_REJECT_FAIL:
    case ActionTypes.TABLE_TAKE_OWNERSHIP_FAIL:
    case ActionTypes.TABLE_APPROVE_FAIL:
    case ActionTypes.UPDATE_TABLE_FAIL: {
      const entityPayload = {
        [stateKey]: {
          ...state[stateKey],
          isLoading: false,
          error: action.payload.error,
        },
      };

      return {
        ...state,
        ...entityPayload,
      };
    }

    case ActionTypes.RESET_TABLE_DATA_REQUEST: {
      const entityPayload = {
        [stateKey]: {
          ...state[stateKey],
          listData: undefined,
          formData: undefined,
          schema: undefined,
        },
      };

      return {
        ...state,
        ...entityPayload,
      };
    }

    case ActionTypes.RESET_TABLE_FORM_DATA_REQUEST: {
      const entityPayload = {
        [stateKey]: {
          ...state[stateKey],
          formData: undefined,
          workflow: undefined,
        },
      };

      return {
        ...state,
        ...entityPayload,
      };
    }

    case ActionTypes.RESET_TABLE_STATES_REQUEST: {
      const entityPayload = {
        [stateKey]: {
          ...state[stateKey],
          workflow: undefined,
          created: false,
          updated: false,
          deleted: false,
          error: undefined,
          success: undefined,
          schema: undefined,
        },
      };

      return {
        ...state,
        ...entityPayload,
      };
    }

    case ActionTypes.FETCH_ALL_BY_TABLE_KEY_REQUEST: {
      const entityPayload = {
        [stateKey]: {
          ...state[stateKey],

          // UI works much better without this loading every request.
          // server-side sort seems to not work as well.
          isLoading: false,
          error: null,
          listData: [],
          total: 0,
        },
      };

      return {
        ...state,
        ...entityPayload,
      };
    }

    case ActionTypes.FETCH_ALL_BY_TABLE_KEY_SUCCESS: {
      const {
        data: { results, total },
        schema,
      } = action.payload;

      const view = getDefaultView(schema);
      const currentView = state[stateKey]?.view;

      const entityPayload = {
        [stateKey]: {
          ...state[stateKey],
          isLoading: false,
          listData: results,
          total,
          schema,
          view: currentView || view,
        },
      };

      return {
        ...state,
        ...entityPayload,
      };
    }

    case ActionTypes.FETCH_ALL_GROUPED_BY_TABLE_KEY_SUCCESS: {
      const { data, schema } = action.payload;

      const view = getDefaultView(schema);
      const currentView = state[stateKey]?.view;

      const entityPayload = {
        [stateKey]: {
          ...state[stateKey],
          isLoading: false,
          listData: data,
          schema,
          view: currentView || view,
        },
      };

      return {
        ...state,
        ...entityPayload,
      };
    }

    case ActionTypes.FETCH_ONE_BY_TABLE_KEY_REQUEST: {
      const entityPayload = {
        [stateKey]: {
          ...state[stateKey],
          isLoading: true,
          error: null,
          formData: undefined,
          schema: undefined,
        },
      };

      return {
        ...state,
        ...entityPayload,
      };
    }

    case ActionTypes.FETCH_ONE_BY_TABLE_KEY_SUCCESS: {
      const { data, schema } = action.payload;

      const entityPayload = {
        [stateKey]: {
          ...state[stateKey],
          isLoading: false,
          formData: data,
          schema,
        },
      };

      return {
        ...state,
        ...entityPayload,
      };
    }

    case ActionTypes.SET_TABLE_VIEW: {
      const { view } = action.payload;

      const entityPayload = {
        [stateKey]: {
          ...state[stateKey],
          view,
        },
      };

      return {
        ...state,
        ...entityPayload,
      };
    }

    case ActionTypes.FETCH_TABLE_HISTORY_REQUEST: {
      const entityPayload = {
        [stateKey]: {
          ...state[stateKey],
          error: null,
        },
      };

      return {
        ...state,
        ...entityPayload,
      };
    }

    case ActionTypes.FETCH_TABLE_HISTORY_SUCCESS: {
      const entityPayload = {
        [stateKey]: {
          ...state[stateKey],
          histories: action.payload.response,
        },
      };

      return {
        ...state,
        ...entityPayload,
      };
    }

    case ActionTypes.FETCH_TABLE_COMMENT_REQUEST: {
      const entityPayload = {
        [stateKey]: {
          ...state[stateKey],
          error: null,
        },
      };

      return {
        ...state,
        ...entityPayload,
      };
    }

    case ActionTypes.FETCH_TABLE_COMMENT_SUCCESS: {
      const entityPayload = {
        [stateKey]: {
          ...state[stateKey],
          comments: action.payload.response,
        },
      };

      return {
        ...state,
        ...entityPayload,
      };
    }

    case ActionTypes.TABLE_NEXT_STEP_REQUEST:
    case ActionTypes.TABLE_RETURN_STEP_REQUEST:
    case ActionTypes.TABLE_REJECT_REQUEST:
    case ActionTypes.TABLE_TAKE_OWNERSHIP_REQUEST:
    case ActionTypes.TABLE_APPROVE_REQUEST:
    case ActionTypes.UPDATE_TABLE_REQUEST: {
      const entityPayload = {
        [stateKey]: {
          ...state[stateKey],
          error: null,
          updated: false,
          success: undefined,
        },
      };

      return {
        ...state,
        ...entityPayload,
      };
    }

    case ActionTypes.TABLE_NEXT_STEP_SUCCESS:
    case ActionTypes.TABLE_RETURN_STEP_SUCCESS:
    case ActionTypes.TABLE_REJECT_SUCCESS:
    case ActionTypes.TABLE_TAKE_OWNERSHIP_SUCCESS:
    case ActionTypes.TABLE_APPROVE_SUCCESS:
    case ActionTypes.UPDATE_TABLE_SUCCESS: {
      const entityPayload = {
        [stateKey]: {
          ...state[stateKey],
          updated: true,
        },
      };
      return {
        ...state,
        ...entityPayload,
      };
    }

    case ActionTypes.CREATE_TABLE_WORKFLOW_REQUEST:
    case ActionTypes.CREATE_TABLE_REQUEST: {
      const entityPayload = {
        [stateKey]: {
          ...state[stateKey],
          error: null,
          created: false,
          success: undefined,
        },
      };

      return {
        ...state,
        ...entityPayload,
      };
    }

    case ActionTypes.CREATE_TABLE_WORKFLOW_SUCCESS:
    case ActionTypes.CREATE_TABLE_SUCCESS: {
      const entityPayload = {
        [stateKey]: {
          ...state[stateKey],
          created: true,
          entityId: action.payload?.response?.id,
        },
      };

      return {
        ...state,
        ...entityPayload,
      };
    }

    case ActionTypes.CREATE_TABLE_WORKFLOW_FAIL:
    case ActionTypes.CREATE_TABLE_FAIL: {
      const entityPayload = {
        [stateKey]: {
          ...state[stateKey],
          error: action.payload.error,
          created: false,
        },
      };

      return {
        ...state,
        ...entityPayload,
      };
    }

    case ActionTypes.FETCH_AVAILABLE_WORKFLOW_STEPS_REQUEST: {
      const entityPayload = {
        [stateKey]: {
          ...state[stateKey],
          isLoading: true,
          error: null,
          workflow: undefined,
        },
      };

      return {
        ...state,
        ...entityPayload,
      };
    }

    case ActionTypes.FETCH_AVAILABLE_WORKFLOW_STEPS_SUCCESS: {
      const entityPayload = {
        [stateKey]: {
          ...state[stateKey],
          isLoading: false,
          workflow: action.payload.response,
        },
      };

      return {
        ...state,
        ...entityPayload,
      };
    }

    case ActionTypes.FETCH_TABLE_ATTACHMENT_REQUEST: {
      const entityPayload = {
        [stateKey]: {
          ...state[stateKey],
          error: null,
        },
      };

      return {
        ...state,
        ...entityPayload,
      };
    }

    case ActionTypes.FETCH_TABLE_ATTACHMENT_SUCCESS: {
      const entityPayload = {
        [stateKey]: {
          ...state[stateKey],
          attachments: action.payload.response,
        },
      };

      return {
        ...state,
        ...entityPayload,
      };
    }

    case ActionTypes.CREATE_TABLE_ATTACHMENT_REQUEST: {
      const entityPayload = {
        [stateKey]: {
          ...state[stateKey],
          error: null,
        },
      };

      return {
        ...state,
        ...entityPayload,
      };
    }

    case ActionTypes.CREATE_TABLE_ATTACHMENT_SUCCESS: {
      const entityPayload = {
        [stateKey]: {
          ...state[stateKey],
        },
      };

      return {
        ...state,
        ...entityPayload,
      };
    }

    case ActionTypes.CREATE_TABLE_ATTACHMENT_FAIL: {
      const entityPayload = {
        [stateKey]: {
          ...state[stateKey],
          error: action.payload.error || action.payload.e,
        },
      };

      return {
        ...state,
        ...entityPayload,
      };
    }

    case ActionTypes.DELETE_TABLE_ATTACHMENT_REQUEST: {
      const entityPayload = {
        [stateKey]: {
          ...state[stateKey],
          error: null,
        },
      };

      return {
        ...state,
        ...entityPayload,
      };
    }

    case ActionTypes.DELETE_TABLE_ATTACHMENT_SUCCESS: {
      const entityPayload = {
        [stateKey]: {
          ...state[stateKey],
        },
      };

      return {
        ...state,
        ...entityPayload,
      };
    }

    case ActionTypes.DELETE_TABLE_ATTACHMENT_FAIL: {
      const entityPayload = {
        [stateKey]: {
          ...state[stateKey],
          error: action.payload.error,
        },
      };

      return {
        ...state,
        ...entityPayload,
      };
    }

    case ActionTypes.CREATE_TABLE_COMMENT_REQUEST: {
      const entityPayload = {
        [stateKey]: {
          ...state[stateKey],
          error: null,
        },
      };

      return {
        ...state,
        ...entityPayload,
      };
    }

    case ActionTypes.CREATE_TABLE_COMMENT_SUCCESS: {
      const entityPayload = {
        [stateKey]: {
          ...state[stateKey],
        },
      };

      return {
        ...state,
        ...entityPayload,
      };
    }

    case ActionTypes.CREATE_TABLE_COMMENT_FAIL: {
      const entityPayload = {
        [stateKey]: {
          ...state[stateKey],
          error: action.payload.e,
        },
      };

      return {
        ...state,
        ...entityPayload,
      };
    }

    case ActionTypes.CREATE_TABLE_FILE_UPLOAD_REQUEST: {
      const entityPayload = {
        [stateKey]: {
          ...state[stateKey],
          error: null,
        },
      };

      return {
        ...state,
        ...entityPayload,
      };
    }

    case ActionTypes.CREATE_TABLE_FILE_UPLOAD_SUCCESS: {
      const entityPayload = {
        [stateKey]: {
          ...state[stateKey],
          isLoading: false,
          success: true,
        },
      };

      return {
        ...state,
        ...entityPayload,
      };
    }

    case ActionTypes.CREATE_TABLE_FILE_UPLOAD_FAIL: {
      const entityPayload = {
        [stateKey]: {
          ...state[stateKey],
          isLoading: false,
          error: action.payload.error,
        },
      };

      return {
        ...state,
        ...entityPayload,
      };
    }

    case ActionTypes.DELETE_TABLE_REQUEST: {
      const entityPayload = {
        [stateKey]: {
          ...state[stateKey],
          error: null,
          deleted: false,
        },
      };

      return {
        ...state,
        ...entityPayload,
      };
    }

    case ActionTypes.DELETE_TABLE_SUCCESS: {
      const entityPayload = {
        [stateKey]: {
          ...state[stateKey],
          deleted: true,
        },
      };

      return {
        ...state,
        ...entityPayload,
      };
    }

    case ActionTypes.DELETE_TABLE_FAIL: {
      const entityPayload = {
        [stateKey]: {
          ...state[stateKey],
          error: action.payload.e,
        },
      };

      return {
        ...state,
        ...entityPayload,
      };
    }

    case ActionTypes.EXPORT_REQUEST: {
      const entityPayload = {
        [stateKey]: {
          ...state[stateKey],
          error: null,
        },
      };

      return {
        ...state,
        ...entityPayload,
      };
    }

    case ActionTypes.EXPORT_SUCCESS: {
      const entityPayload = {
        [stateKey]: {
          ...state[stateKey],
          error: null,
        },
      };

      return {
        ...state,
        ...entityPayload,
      };
    }

    case ActionTypes.EXPORT_FAIL: {
      const entityPayload = {
        [stateKey]: {
          ...state[stateKey],
          isLoading: false,
          error: action.payload.error || action.payload.e,
        },
      };

      return {
        ...state,
        ...entityPayload,
      };
    }

    default:
      throw new Error('Action could not be found.');
  }
};

export { initialState, ActionTypes, getStateName };

export default reducer;
