import {
  FaList,
  FaMapMarkerAlt,
  FaThLarge,
  FaRegCalendarAlt,
  FaCalendarWeek,
} from 'react-icons/fa';

const VIEWS = Object.freeze({
  LIST: 'listView',
  MAP: 'mapView',
  KANBAN: 'kanbanBoardView',
  TIMELINE: 'timelineView',
  WEEKLY: 'weeklyView',
});

const VIEWS_ICONS = {
  [VIEWS.LIST]: <FaList />,
  [VIEWS.MAP]: <FaMapMarkerAlt />,
  [VIEWS.KANBAN]: <FaThLarge />,
  [VIEWS.TIMELINE]: <FaRegCalendarAlt />,
  [VIEWS.WEEKLY]: <FaCalendarWeek />,
};

export default { VIEWS, VIEWS_ICONS };
