import { Pie } from '@ant-design/plots';

const DashboardPie = ({
  appendPadding,
  data,
  angleField,
  colorField,
  radius,
}: {
  appendPadding?: number;
  data: any;
  angleField: string;
  colorField: string;
  radius?: number;
}) => {
  return (
    <Pie
      appendPadding={appendPadding || 10}
      data={data}
      angleField={angleField}
      colorField={colorField}
      radius={radius || 0.9}
      interactions={[
        {
          type: 'element-active',
        },
      ]}
      label={{
        type: 'inner',
        offset: '-30%',
        content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
        style: {
          fontSize: 14,
          textAlign: 'center',
        },
      }}
    />
  );
};

export default DashboardPie;
