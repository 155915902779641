export default Object.freeze({
  Login: '[Auth] Login',
  LoginSuccess: '[Auth] Login Success',
  LoginWithNewPassword: '[Auth] Login with New Password',
  LoginFail: '[Auth] Login Fail',
  Logout: '[Auth] Logout',
  LogoutSuccess: '[Auth] Logout Success',
  LogoutFail: '[Auth] Logout Fail',
  ForgotPassword: '[Auth] Forgot Password',
  ForgotPasswordSuccess: '[Auth] Forgot Password Success',
  ForgotPasswordFail: '[Auth] Forgot Password Fail',
  ResetPassword: '[Auth] Forgot Password',
  ResetPasswordSuccess: '[Auth] Forgot Password Success',
  ResetPasswordFail: '[Auth] Forgot Password Fail',
});
