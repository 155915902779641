import React from 'react';

import { Table } from 'antd';

const Grid = ({ rowData }: { rowData: any[] }) => {
  if (!rowData.length) {
    return <span>Өгөгдөл байхгүй байна</span>;
  }

  return (
    <Table
      columns={[
        { title: 'Баганын нэр', key: 'headerName', dataIndex: 'headerName' },
        { title: 'Баганын фийлд', key: 'field', dataIndex: 'field' },
        { title: 'Ангилах боломжтой', key: 'sortable', dataIndex: 'sortable' },
      ]}
      dataSource={rowData}
      className="h-100"
    />
  );
};

export default Grid;
