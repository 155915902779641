import { initReactI18next } from 'react-i18next';

import i18n from 'i18next';

import { translation_en } from '../assets/locales/en/translation';
import { translation_mn } from '../assets/locales/mn/translation';

const LANG = process.env.REACT_APP_LANG;
i18n.use(initReactI18next).init({
  debug: true,
  lng: LANG,
  interpolation: {
    escapeValue: false,
  },
  resources: {
    mn: {
      translation: translation_mn,
    },
    en: {
      translation: translation_en,
    },
  },
});

export default i18n;
