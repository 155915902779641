import React, { createContext, useCallback, useReducer } from 'react';

import ShowToast from 'utils/ShowToast';

import { MetadataFileType } from 'constants/MetadataConstants';

import { get, post, put, remove } from '../core/fetch';
import reducer, { initialState, ActionTypes } from './state/MetadataState';

interface MetadataContextActions {
  fetchList: () => Promise<void>;
  selectTable: (tableKey: string) => void;
  fetchJsonFile: (fileType: string) => Promise<void>;
  saveJsonFile: ({
    tableKey,
    fileType,
    data,
  }: {
    tableKey?: string;
    fileType: string;
    data: any;
  }) => Promise<void>;
  createJsonFile: (fileType: string) => Promise<void>;
  deleteJsonFile: (fileType: string, tableKey?: string) => Promise<void>;
}

interface MetadataContextState {
  actions: MetadataContextActions;
  state: {
    isLoading: boolean;
    metadataList: any[];
    tableMetadata: any | null;
    workflowMetadata: any | null;
    tableKey?: string;
    error: null | string;
  };
}

export const MetadataContext = createContext<MetadataContextState>({
  actions: {
    fetchList: () => Promise.reject(),
    selectTable: () => {},
    fetchJsonFile: () => Promise.reject(),
    saveJsonFile: () => Promise.reject(),
    createJsonFile: () => Promise.reject(),
    deleteJsonFile: () => Promise.reject(),
  },
  state: initialState,
});

const MetadataContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const fetchList = useCallback(async () => {
    dispatch({
      type: ActionTypes.FETCH_METADATA_LIST_REQUEST,
      payload: [],
    });
    get(`/metadata`)
      .then(response =>
        dispatch({
          type: ActionTypes.FETCH_METADATA_LIST_SUCCESS,
          payload: response,
        }),
      )
      .catch(e =>
        dispatch({
          type: ActionTypes.FETCH_METADATA_LIST_FAILURE,
          payload: {
            e,
          },
        }),
      );
  }, [dispatch]);

  const selectTable = (tableKey: string) => {
    dispatch({
      type: ActionTypes.SELECT_METADATA_TABLE,
      payload: tableKey,
    });
  };

  const fetchJsonFile = useCallback(
    async fileType => {
      if (!state.tableKey) {
        dispatch({
          type: ActionTypes.CLEAR_JSON_METADATA_FILES,
        });
        return;
      }

      dispatch({
        type: ActionTypes.FETCH_JSON_METADATA_REQUEST,
      });

      get(`/metadata/${state.tableKey}/${fileType}`)
        .then(response => {
          dispatch({
            type: ActionTypes.FETCH_JSON_METADATA_SUCCESS,
            payload: {
              ...(fileType === MetadataFileType.tableMetadata
                ? { tableMetadata: response }
                : { workflowMetadata: response }),
            },
          });
        })
        .catch(e =>
          dispatch({
            type: ActionTypes.FETCH_JSON_METADATA_FAILURE,
            payload: {
              tableKey: state.tableKey,
              e,
            },
          }),
        );
    },
    [state.tableKey],
  );

  const saveJsonFile = useCallback(
    async ({
      tableKey,
      fileType,
      data,
    }: {
      tableKey?: string;
      fileType: string;
      data: any;
    }) => {
      dispatch({
        type: ActionTypes.SAVE_JSON_METADATA_REQUEST,
      });
      put(`/metadata/${tableKey}/${fileType}`, data)
        .then(response => {
          dispatch({
            type: ActionTypes.SAVE_JSON_METADATA_SUCCESS,
            payload: {
              ...(fileType === MetadataFileType.tableMetadata
                ? { tableMetadata: response }
                : { workflowMetadata: response }),
            },
          });
          ShowToast.success(`${fileType} амжилттай шинэчлэгдлээ`);
        })
        .catch(e => {
          dispatch({
            type: ActionTypes.SAVE_JSON_METADATA_FAILURE,
            payload: {
              e,
            },
          });
          ShowToast.error(`${fileType} өөрчлөх алдаа:${e.error}`);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch],
  );

  const createJsonFile = useCallback(
    async (fileType: string) => {
      if (!state.tableKey) {
        return;
      }

      dispatch({
        type: ActionTypes.CREATE_JSON_METADATA_REQUEST,
      });
      post(`/metadata/${state.tableKey}/${fileType}`, {})
        .then(response => {
          dispatch({
            type: ActionTypes.CREATE_JSON_METADATA_SUCCESS,
            payload: {
              ...(fileType === MetadataFileType.tableMetadata
                ? { tableMetadata: response }
                : { workflowMetadata: response }),
            },
          });
          ShowToast.success(`${fileType} амжилттай үүслээ`);
        })
        .catch(e => {
          dispatch({
            type: ActionTypes.CREATE_JSON_METADATA_FAILURE,
            payload: {
              e,
            },
          });
          ShowToast.error(`${fileType} үүсгэх алдаа:${e.error}`);
        });
    },
    [state.tableKey],
  );

  const deleteJsonFile = useCallback(
    async (fileType: string, tableKey?: string) => {
      dispatch({
        type: ActionTypes.DELETE_JSON_METADATA_REQUEST,
      });

      remove(`/metadata/${tableKey}/${fileType}`)
        .then(() => {
          dispatch({
            type: ActionTypes.DELETE_JSON_METADATA_SUCCESS,
            payload: {
              ...(fileType === MetadataFileType.tableMetadata
                ? { tableMetadata: null }
                : { workflowMetadata: null }),
            },
          });
          ShowToast.success(`${fileType} амжилттай устлаа`);
        })
        .catch(e => {
          dispatch({
            type: ActionTypes.DELETE_JSON_METADATA_FAILURE,
            payload: {
              e,
            },
          });
          ShowToast.error(`${fileType} устгах алдаа:${e.error}`);
        });
    },
    [dispatch],
  );

  const actions: MetadataContextActions = {
    fetchList,
    selectTable,
    fetchJsonFile,
    saveJsonFile,
    createJsonFile,
    deleteJsonFile,
  };

  return (
    <MetadataContext.Provider value={{ actions, state }}>
      {children}
    </MetadataContext.Provider>
  );
};

export default MetadataContextProvider;
