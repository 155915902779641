import React, { useContext } from 'react';

import { MetadataContext } from 'contexts/MetadataContext';

import FormView from './FormLayout';
import FormLayoutWithWorkflow from './FormLayoutWithWorkflow';

const FormEditor = props => {
  const { state } = useContext(MetadataContext);
  if (state.tableMetadata?.hasWorkflow) {
    return (
      <div {...props}>
        <FormLayoutWithWorkflow schema={state.tableMetadata} />
      </div>
    );
  }

  return (
    <div {...props}>
      <FormView
        formView={state.tableMetadata?.formView}
        fields={state.tableMetadata?.columns}
      />
    </div>
  );
};

export default FormEditor;
