import { useFormContext, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CreatableSelect from 'react-select/creatable';

import dropDownStyle from '../../ComponentStyle/DropDownStyle';

const DefaultField = ({ name }: { name: string }) => {
  const {
    // formState: { errors },
    control,
  } = useFormContext();
  const { t } = useTranslation();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <CreatableSelect
          {...field}
          formatCreateLabel={string => string}
          isMulti
          styles={{
            // control: (provided, state) => ({
            //   ...provided,
            //   minHeight: dropDownStyle.minHeight,
            //   marginTop: dropDownStyle.marginTop,
            //   boxShadow: state.isFocused && dropDownStyle.focusedBoxShadow,
            //   borderRadius: 0,
            //   borderColor: errors[name]
            //     ? dropDownStyle.errorBorderColor
            //     : (state.isFocused && dropDownStyle.focusedBorderColor) ||
            //       dropDownStyle.borderColor,
            //   '&:hover': {
            //     borderColor: dropDownStyle.hoverBorderColor,
            //   },
            // }),
            singleValue: provided => ({
              ...provided,
              ...dropDownStyle.textStyle,
            }),
            multiValue: provided => ({
              ...provided,
              ...dropDownStyle.textStyle,
              backgroundColor: dropDownStyle.multiValueBackgroundColor,
            }),
            option: provided => ({
              ...provided,
              ...dropDownStyle.textStyle,
            }),
            placeholder: provided => ({
              ...provided,
              ...dropDownStyle.textStyle,
            }),
            menuPortal: base => ({ ...base, zIndex: 99999 }),
          }}
          theme={theme => ({
            ...theme,
            colors: {
              ...theme.colors,
              ...dropDownStyle.themeColors,
            },
          })}
          options={[
            {
              label: t('entity.filter.blank'),
              value: '[BLANK]',
            },
          ]}
          placeholder={t('entity.filter.select')}
          onBlur={field.onBlur}
          onChange={field.onChange}
          menuPortalTarget={document.body}
          id={name}
        />
      )}
    />
  );
};

export default DefaultField;
