import { useState, useEffect, useContext } from 'react';

import buildRelatedGridOptions from 'utils/buildRelatedGridOptions';

import { AgGrid } from 'components/ThirdLibraries';
import { AppContext } from 'contexts/AppContext';

import s from './FormTableList.module.scss';

const FormTableList = ({
  schema,
  data: entityData,
  onRowSelected,
  onColumnSorted,
}: {
  schema: any;
  data: any[];
  onRowSelected: (event) => void;
  onColumnSorted: (sort) => void;
}) => {
  const [gridApi, setGridApi] = useState<any>(null);

  const {
    state: { settings },
  } = useContext(AppContext);

  const onGridReady = api => {
    setGridApi(api);
  };

  const onSortChanged = params => {
    if (params.columnApi) {
      const sortModel = params.columnApi
        .getColumnState()
        .find(s => s.sort != null);
      const sort = {};
      if (sortModel) {
        sort[sortModel.colId] = sortModel.sort;
      }
      onColumnSorted(sort);
    }
  };

  useEffect(() => {
    if (gridApi) {
      gridApi.setRowData(entityData);
    }
  }, [entityData, gridApi]);

  const agGridOptions = schema.listView;
  if (!agGridOptions) {
    return <span>Ачааллаж байна...</span>;
  }

  const { columnDefs, defaultColDef, suppressHorizontalScroll } =
    buildRelatedGridOptions(agGridOptions, schema, settings);

  return (
    <AgGrid
      rowData={entityData || []}
      className={s.grid}
      columnDefs={columnDefs}
      onGridReady={onGridReady}
      onCellClicked={onRowSelected}
      onSortChanged={onSortChanged}
      getRowNodeId={({ data }) => {
        return data.id;
      }}
      rowStyle={{
        background: 'white',
        // height: '5.25rem',
        // lineHeight: '3.25rem',
        // borderColor: 'rgba(85, 52, 230, 0.2)',
      }}
      defaultColDef={defaultColDef}
      suppressHorizontalScroll={suppressHorizontalScroll}
    />
  );
};

export default FormTableList;
