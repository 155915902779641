import React, { useContext, useEffect, useState } from 'react';
import { WidthProvider, Responsive } from 'react-grid-layout';
import { AiOutlineEdit } from 'react-icons/ai';
import { useParams, useNavigate } from 'react-router-dom';

import { PlusOutlined, SaveOutlined } from '@ant-design/icons';
import { FloatButton as AntFloatButton, Card } from 'antd';
import cx from 'classnames';
import { post, put } from 'core/fetch';

import DashboardGraph from 'components/DashboardGraph/DashboardGraph';
import FloatButton from 'components/FloatButton/FloatButton';
import GraphEdit from 'components/GraphEdit/GraphEdit';
import TsumEmpty from 'components/TsumEmpty/TsumEmpty';

import LayoutWithMenu from '../../components/Layout/LayoutWithMenu';
import DashboardLoader from '../../components/Loaders/DashboardLoader';
import { AppContext } from '../../contexts/AppContext';

import s from './AppDashboard.module.scss';

const ResponsiveReactGridLayout = WidthProvider(Responsive);
const AppDashboard = () => {
  const heightOffset = 234;
  const { appKey } = useParams();
  const history = useNavigate();

  const [open, setOpen] = useState<boolean>(false);
  const [selectedGraphId, setSelectedGraphId] = useState<number>();
  const [dashboard, setDashboard] = useState<any>();
  const [layout, setLayout] = useState<any[]>([]);
  const [isLayoutChange, setIsLayoutChange] = useState<boolean>(false);
  const {
    state: { apps, isLoadingDashboard, me },
  } = useContext(AppContext);

  const selectedApp = apps.find(app => app.key === appKey);

  const clearState = () => {
    setLayout([]);
    setDashboard(undefined);
    setSelectedGraphId(undefined);
    setOpen(false);
  };

  const onLayoutChange = (layout: any) => {
    setLayout(layout);
    setIsLayoutChange(true);
  };

  const fetchData = async () => {
    clearState();
    const result = await post('/dashboard/search', {
      $where: {
        _is_deleted: false,
        name: selectedApp.dashboard.name,
      },
    });

    if (!result.length) {
      navigateToEntity();
      return;
    }

    setDashboard(result[0]);

    if (result[0].graphs) {
      const data = result[0].graphs;

      setLayout(data.layout);
    }
  };

  const saveDashboard = async () => {
    if (isLayoutChange) {
      await put(`/dashboard/${dashboard.id}`, {
        graphs: `{"layout": ${JSON.stringify(layout)}}`,
      });
      setIsLayoutChange(false);
    }
  };

  const navigateToEntity = () => {
    const [firstMenuItem] = selectedApp.menu;

    if (firstMenuItem.entityKey) {
      history(`/apps/${selectedApp.key}/${firstMenuItem.entityKey}`);
    } else if (
      firstMenuItem.item_groups?.length &&
      firstMenuItem.item_groups[0].items.length
    ) {
      const { entityKey } = firstMenuItem.item_groups[0].items[0];
      history(`/apps/${selectedApp.key}/${entityKey}`);
    }
  };

  useEffect(() => {
    if (selectedApp?.dashboard) {
      fetchData();
    }

    if (
      isLoadingDashboard === false &&
      selectedApp?.menu?.length &&
      !selectedApp.dashboard
    ) {
      navigateToEntity();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedApp]);

  return (
    <LayoutWithMenu>
      <div className={cx('px-3 px-lg-4')} data-cy="app-dashboard-container">
        {isLoadingDashboard && (
          <DashboardLoader
            width={window.innerWidth - 60}
            height={window.innerHeight - heightOffset}
          />
        )}

        {layout && layout.length !== 0 ? (
          <React.Fragment>
            <ResponsiveReactGridLayout
              isDraggable={me?.isAdmin || false}
              isResizable={me?.isAdmin || false}
              isDroppable={me?.isAdmin || false}
              rowHeight={60}
              compactType={'horizontal'}
              onResizeStop={onLayoutChange}
              onDragStop={onLayoutChange}
            >
              {layout.map(item => (
                <div key={item.i} data-grid={item} className={s.graph}>
                  {me?.isAdmin && (
                    <div
                      className={cx('position-fixed p-2 bg-dark edit', s.edit)}
                      style={{ zIndex: '2' }}
                      onClick={() => {
                        setSelectedGraphId(item.i);
                        setOpen(true);
                      }}
                    >
                      <AiOutlineEdit color="white" size={20} />
                    </div>
                  )}
                  <DashboardGraph graphId={item.i} />
                </div>
              ))}
            </ResponsiveReactGridLayout>
          </React.Fragment>
        ) : (
          <Card className={s.emptyCard}>
            <TsumEmpty
              description={
                <span>
                  Одоогоор Дашбоард бүртгэлгүй байна. Та шинээр бүртгэнэ үү.
                </span>
              }
            ></TsumEmpty>
          </Card>
        )}
      </div>
      {me?.isAdmin && (
        <AntFloatButton.Group style={{ right: 80 }}>
          <FloatButton icon={<PlusOutlined />} onClick={() => setOpen(true)} />

          <FloatButton
            disabled={!isLayoutChange}
            size="large"
            icon={<SaveOutlined />}
            onClick={saveDashboard}
          />
        </AntFloatButton.Group>
      )}
      {dashboard && me?.isAdmin && (
        <GraphEdit
          open={open}
          dashboard={dashboard}
          id={selectedGraphId}
          handleClose={() => {
            setOpen(false);
            fetchData();
          }}
        />
      )}
    </LayoutWithMenu>
  );
};

export default AppDashboard;
