import MetadataDesigner from 'components/Metadata/MetadataDesigner';
import MetadataContextProvider from 'contexts/MetadataContext';

import LayoutWithMenu from '../components/Layout/LayoutWithMenu';

const MetadataPage = () => {
  return (
    <MetadataContextProvider>
      <LayoutWithMenu>
        <MetadataDesigner />
      </LayoutWithMenu>
    </MetadataContextProvider>
  );
};
export default MetadataPage;
