import PropTypes from 'prop-types';

const FileRenderer = props => {
  const {
    value: { url, isPublic, mimetype },
    colDef,
  } = props;

  if (!isPublic) {
    return 'Нууц файл';
  }
  if (!['image/gif', 'image/jpeg', 'image/png'].includes(mimetype)) {
    return 'Файл';
  }

  return (
    <>
      <div className="align-items-center">
        <img src={url} alt={colDef.headerName} style={{ height: '40px' }} />
      </div>
    </>
  );
};

FileRenderer.propTypes = {
  colDef: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default FileRenderer;
