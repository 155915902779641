import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import moment from 'moment';
import PropTypes from 'prop-types';

import 'moment/locale/mn';
import 'moment/locale/en-au';

const WeekControl = {
  previous: 'previous',
  next: 'next',
  today: 'today',
};

const WeeklyPager = ({ onPageChanged }) => {
  moment.locale(process.env.REACT_APP_LANG);
  const { t } = useTranslation();

  const [startDate, setStartDate] = useState<any>(moment().startOf('isoWeek'));
  const [title, setSetTitle] = useState('');

  const changeDate = weekControl => {
    let start = startDate;

    switch (weekControl) {
      case WeekControl.previous:
        start.add(-7, 'days');
        break;
      case WeekControl.next:
        start.add(7, 'days');
        break;
      case WeekControl.today:
        start = moment().startOf('isoWeek');
        break;
      default:
    }
    const end = moment(start).endOf('isoWeek');

    const startMonth = start.format('MMMM YYYY');
    const endMonth = end.format('MMMM YYYY');
    setSetTitle(
      startMonth === endMonth ? startMonth : `${startMonth} - ${endMonth}`,
    );
    setStartDate(start);

    const firstDay = moment(start);
    const weekdays: any[] = [];
    for (let i = 0; i < 7; i += 1) {
      weekdays.push(moment(firstDay.day(i + 1)));
    }
    onPageChanged({ start, end, weekdays });
  };

  const onClickToday = () => {
    changeDate(WeekControl.today);
  };

  const onClickPrevious = () => {
    changeDate(WeekControl.previous);
  };

  const onClickNext = () => {
    changeDate(WeekControl.next);
  };

  useEffect(() => {
    onClickToday();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="pb-4">
      <button type="button" className="btn btn-light" onClick={onClickToday}>
        {t('entity.main.today')}
      </button>
      <button
        type="button"
        className="btn btn-link pr-1 ml-2"
        onClick={onClickPrevious}
      >
        <i className="la la-chevron-left" />
      </button>
      <button type="button" className="btn btn-link pl-1" onClick={onClickNext}>
        <i className="la la-chevron-right" />
      </button>
      <span className="pl-2">{title}</span>
    </div>
  );
};

WeeklyPager.propTypes = {
  onPageChanged: PropTypes.func.isRequired,
};

export default WeeklyPager;
