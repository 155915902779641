import { useState, useEffect, useCallback, useMemo } from 'react';
import { Card, Button, Dropdown } from 'react-bootstrap';
import { createPortal } from 'react-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  FaSpinner,
  FaUserCheck,
  FaChevronLeft,
  FaChevronRight,
  FaThumbsUp,
  FaThumbsDown,
} from 'react-icons/fa';
import { IoMdPrint } from 'react-icons/io';

import { joiResolver } from '@hookform/resolvers/joi';
import cx from 'classnames';
import { each, isEmpty } from 'lodash';

import { useAuthContext } from '../../../contexts/AuthContext';
import { useDialogManager } from '../../../contexts/DialogManagerContext';
import { useEntityContext } from '../../../contexts/EntityContext';
import { FormMode } from '../../../core/formMode';
import usePortal from '../../../hooks/usePortal';
import prepareFormData from '../../../utils/prepareFormData';
import { getOutcomeLabel } from '../../../utils/worflowUtils';
import FormLoader from '../../Loaders/FormLoader';
import Overlay from '../../Overlay/Overlay';
import WorkflowMessage from '../../WorkflowMessage/WorkflowMessage';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import Fieldsets from '../FormTabs/FormTabs';
import PopConfirmation from '../PopConfirmation/PopConfirmation';
import WorkflowReasonModal from '../WorkflowReason/WorkflowReasonModal';

import s from './FormMain.module.scss';

const FormActions = ({
  entityKey,
  entityId,
  mode,
  schema,
  menuActions,
  loading,
}: {
  entityKey: string;
  entityId?: number;
  mode: string;
  schema?: any;
  loading?: any;
  menuActions: {
    move: (arg: any) => void;
    save: () => void;
    delete: () => void;
    takeOwnership: () => void;
  };
}) => {
  const availablePrintableDocs = schema?.printableDocuments?.filter(
    p => !p.use_in_grid,
  );
  const { t } = useTranslation();
  const { getEntityState } = useEntityContext();
  const entityState = getEntityState(entityKey, entityId);
  const { actions } = useEntityContext();
  const { getDialog, closeDialog } = useDialogManager();
  const workflow = schema?.workflow;
  const currentStep = useMemo(
    () =>
      workflow?.definition?.steps
        ? workflow.definition.steps.find(
            step => step.order === workflow.currentStep,
          )
        : undefined,
    [workflow],
  );

  const confirmDelete = async () => {
    if (!entityId) return;
    const response = await actions.deleteEntity(entityKey, entityId);
    if (response?.error) return;
    const dialog = getDialog();
    if (dialog) {
      closeDialog(dialog.id);
      dialog.params.afterDeleteCallback({
        ...response,
        ...{ id: entityId },
      });
    }
  };
  return (
    <div className={s.actionBarContainer}>
      <div className="d-flex align-items-center">
        <Button
          type="button"
          className="m-1"
          data-cy="save"
          disabled={mode === FormMode.readonly || !!loading}
          onClick={menuActions.save}
        >
          {loading ? (
            <FaSpinner color="white" className="fa-spin mr-2" />
          ) : (
            <i className="far fa-save mr-2" />
          )}
          {t('entity.modal.save')}
        </Button>

        {mode !== FormMode.create && (
          <>
            {entityState?.schema?.hasWorkflow && (
              <Dropdown>
                <Dropdown.Toggle
                  data-cy="actions-dropdown"
                  disabled={schema?.workflow?.availableSteps?.length === 0}
                  variant={cx({
                    'outline-success': workflow.outcome === 1,
                    'outline-danger': workflow.outcome === 2,
                    'outline-warning': !workflow.outcome,
                  })}
                  id="dropdown-basic"
                  className="btn btn-outline-dark"
                >
                  {getOutcomeLabel(workflow.definition, workflow.outcome) ||
                    currentStep?.name}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {schema.workflow.availableSteps.map(action => (
                    <Dropdown.Item
                      data-cy={`action-${action.key}`}
                      key={action.key}
                      disabled={loading}
                      className="d-flex align-items-center"
                      onClick={() => menuActions.move(action)}
                    >
                      {action.to.takeOwnership && (
                        <FaUserCheck className="mr-2" />
                      )}

                      {action.to.reasonRequired && action.to.step && (
                        <FaChevronLeft className="mr-2" />
                      )}

                      {!action.to.reasonRequired && action.to.step && (
                        <FaChevronRight className="mr-2" />
                      )}

                      {action.to.outcome === 1 && (
                        <FaThumbsUp className="mr-2" />
                      )}
                      {action.to.outcome === 2 && (
                        <FaThumbsDown className="mr-2" />
                      )}

                      <span style={{ margin: '0 1rem' }}>{action.name}</span>
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            )}

            {availablePrintableDocs?.length > 0 && (
              <Dropdown>
                <Dropdown.Toggle
                  id="btn-print"
                  data-cy="print"
                  variant="light"
                  className="m-1"
                  disabled={loading}
                >
                  <IoMdPrint
                    className="ml-2"
                    title={t('entity.buttons.print')}
                  />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {availablePrintableDocs.map(doc => (
                    <Dropdown.Item
                      disabled={loading}
                      key={doc.id}
                      href={`/api/${entityKey}/${entityId}/doc/${doc.id}`}
                      target="_blank"
                    >
                      {doc.name}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            )}
          </>
        )}
      </div>
      {mode === FormMode.edit && (
        <div>
          <PopConfirmation
            title={t('entity.modal.deleting-confirm-title')}
            description={t('entity.modal.deleting-confirm-message')}
            confirmType="danger"
            body={
              <Button
                type="button"
                className="m-1 btn btn-danger text-truncate"
                data-cy="delete"
                onClick={menuActions.delete}
              >
                {loading ? (
                  <FaSpinner color="white" className="fa-spin mr-2" />
                ) : (
                  <i className="far fa-delete mr-2" />
                )}
                {t('entity.modal.delete-record')}
              </Button>
            }
            confirmAction={confirmDelete}
          />
        </div>
      )}
      {entityState?.schema?.hasWorkflow &&
        entityState?.schema?.workflow?.outcomeReason && (
          <div className="mb-3">
            <WorkflowMessage workflow={entityState?.schema?.workflow} />
          </div>
        )}
    </div>
  );
};

const WrapperComponent = ({
  children,
}: {
  children: React.ReactNode | React.ReactNode[];
}) => (
  <Card className="m-4 border-0 shadow-sm">
    <Card.Body>{children}</Card.Body>
  </Card>
);

const FormMain = ({
  entityState,
  formMode,
  entityId,
  entityKey,
  entityActionBarRef,
}: {
  entityState?: any;
  formMode?: any;
  entityActionBarRef?: any;
  entityId?: number;
  entityKey: string;
}) => {
  const target = usePortal(entityActionBarRef);
  const { getDialog, closeDialog, setDialogParam } = useDialogManager();

  const {
    state: { authenticatedUser },
  } = useAuthContext();

  const { actions } = useEntityContext();
  const { t } = useTranslation();

  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [showReasonModal, setShowReasonModal] = useState(false);
  const [showRejectReasonModal, setShowRejectReasonModal] = useState(false);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [stepTo, setStepTo] = useState();

  const formMethods = useForm({
    resolver: joiResolver(entityState?.schema?.formValidation),
  });

  const {
    formState: { dirtyFields },
  } = formMethods;

  const closeForm = useCallback(() => {
    const isDirty = Object.keys(dirtyFields).length > 0;
    // const isDirty = true;
    if (isDirty) {
      return false;
    }

    closeDialog();
    return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dirtyFields]);

  useEffect(() => {
    setDialogParam({
      beforeHideCallback: closeForm,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dirtyFields]);

  if (entityState?.isLoading) {
    return (
      <WrapperComponent>
        <FormLoader />
      </WrapperComponent>
    );
  }

  const entityFormSubmit = async (
    data: any,
    overrideAction: string | undefined = undefined,
    step: string | undefined = undefined,
  ) => {
    const transformedData = prepareFormData(entityState?.schema?.fields, data);
    const action = overrideAction ? actions[overrideAction] : actions[formMode];

    setLoadingSubmit(true);
    const response = await action(entityKey, transformedData, entityId, step);
    setLoadingSubmit(false);

    if (response === undefined) {
      // CLEMPE - error discoverability (developer is not returning fetch response)
      throw new Error('System could not determine fetch response');
    }

    // TODO: this will solve 90% of the cases, just just when server doesn't return a payload with error
    if (response?.error) {
      return;
    }

    const dialog = getDialog();
    if (dialog) {
      closeDialog(dialog.id);
      if (dialog.params?.afterSaveCallback) {
        dialog.params.afterSaveCallback({
          ...{ id: entityId }, // when updating, add entity id.
          ...response,
        });
      }
    }
  };

  const validateForm = async (formSchema, data) => {
    const resolver = await joiResolver(formSchema);

    const { values, errors } = await resolver(data, null, {
      shouldUseNativeValidation: false,
      fields: {},
    });
    if (!isEmpty(errors)) {
      each(Object.keys(errors), key => {
        formMethods.setError(key, errors[key]);
      });

      return undefined;
    }

    return values;
  };

  const save = async () => {
    if (formMode === FormMode.edit) {
      if (entityState?.schema.hasWorkflow) {
        const values = await validateForm(
          entityState?.schema.draftValidation,
          formMethods.getValues(),
        );

        if (!values) {
          return;
        }

        // draft save
        entityFormSubmit(values);
        return;
      }
      formMethods.handleSubmit(data => {
        entityFormSubmit(data);
      })();
      return;
    }
    formMethods.handleSubmit(data => entityFormSubmit(data))();
  };

  const takeOwnership = async () => {
    const values = await validateForm(
      entityState?.schema.draftValidation,
      formMethods.getValues(),
    );

    if (!values) {
      return;
    }

    entityFormSubmit(
      {
        ...values,
        workflow_owner: authenticatedUser.user_id,
      },
      'takeOwnership',
    );
  };

  const move = async ({ key, to }) => {
    if (to.takeOwnership) {
      takeOwnership();
      return;
    }

    const values = await validateForm(
      to.fullValidationRequired || to.outcome === 1
        ? entityState?.schema.formValidation
        : entityState?.schema.draftValidation,
      formMethods.getValues(),
    );

    if (!values) {
      return;
    }

    if (to.outcome === 1) {
      setStepTo(key);
      setShowApproveModal(true);
      return;
    }

    if (to.outcome === 2) {
      setStepTo(key);
      setShowRejectReasonModal(true);
      return;
    }

    if (to.reasonRequired) {
      setStepTo(key);
      setShowReasonModal(true);
      return;
    }

    formMethods.handleSubmit(data => entityFormSubmit(data, 'moveStep', key))();
  };

  const menuActions = {
    save,
    delete: () => {},
    takeOwnership,
    move,
  };

  const portalElement = createPortal(
    <>
      <div className="d-flex align-items-center">
        <FormActions
          entityKey={entityKey}
          entityId={entityId}
          mode={formMode}
          // formData={entityState?.formData}
          schema={entityState?.schema}
          menuActions={menuActions}
          loading={loadingSubmit}
        />
      </div>
    </>,
    target,
  );

  const modals = (
    <>
      {showApproveModal && (
        <ConfirmationModal
          title={t('entity.modal.approving-confirm-title')}
          show={showApproveModal}
          description={t('entity.modal.approving-confirm-message')}
          onCancel={() => setShowApproveModal(false)}
          confirmAction={() => {
            const data = formMethods.getValues();
            entityFormSubmit(data, 'moveStep', stepTo);
            setShowApproveModal(false);
          }}
        />
      )}

      {showReasonModal && (
        <WorkflowReasonModal
          show={showReasonModal}
          handleClose={() => {
            setShowReasonModal(false);
          }}
          title={t('entity.modal.more-info-title')}
          body={t('entity.modal.more-info-message')}
          confirmActionText={t('entity.modal.submit')}
          confirmAction={reasonData => {
            const data = formMethods.getValues();

            setShowReasonModal(false);
            entityFormSubmit({ ...data, ...reasonData }, 'moveStep', stepTo);
          }}
        />
      )}
      {showRejectReasonModal && (
        <WorkflowReasonModal
          show={showRejectReasonModal}
          handleClose={() => {
            setShowRejectReasonModal(false);
          }}
          title={t('entity.modal.rejecting-confirm-title')}
          body={t('entity.modal.rejecting-confirm-message')}
          confirmActionText={t('entity.modal.reject')}
          confirmAction={reasonData => {
            const data = formMethods.getValues();
            setShowRejectReasonModal(false);
            entityFormSubmit({ ...data, ...reasonData }, 'moveStep', stepTo);
          }}
        />
      )}
    </>
  );

  return (
    <div className={s.entityFormContainer}>
      {loadingSubmit && <Overlay className={'bg-white'} />}
      <FormProvider {...formMethods}>
        {entityState?.schema && (
          <Fieldsets
            fields={entityState.schema.fields}
            formTabs={entityState.schema.formView.formTabs}
            data={entityState.formData}
            entityKey={entityKey}
            entityId={entityId}
            mode={formMode}
          />
        )}
      </FormProvider>
      {portalElement}
      {modals}
    </div>
  );
};

export default FormMain;
