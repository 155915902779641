const ActionTypes = {
  FETCH_ACTION_LIST: '[Workflow] Fetch Action List',
  FETCH_STEP_LIST: '[Workflow] Fetch Step List',

  SET_NODE_LIST: '[Workflow] Set Node List',
  UPDATE_NODE_LIST: '[Workflow] Update Node List',
  SELECT_NODE: '[Workflow] Select Node',
  DESELECT_NODE: '[Workflow] Deselect Node',

  SET_EDGE_LIST: '[Workflow] Set Edge List',
  UPDATE_EDGE_LIST: '[Workflow] Update Edge List',
  SELECT_EDGE: '[Workflow] Select Edge',
  DESELECT_EDGE: '[Workflow] Deselect Edge',

  CHANGE_TAB: '[Workflow] Change Tab',

  FAILURE: '[Workflow] Failure',
};

const initialState = {
  error: null,
  steps: [],
  actions: [],
  nodes: [],
  edges: [],
  selectedNode: null,
  selectedEdge: null,
  selectedTab: null,
  isUpdated: false,
};

const reducer = (flowState, flowAction) => {
  switch (flowAction.type) {
    case ActionTypes.FETCH_ACTION_LIST: {
      return {
        ...flowState,
        error: null,
        actions: flowAction.payload,
      };
    }
    case ActionTypes.FETCH_STEP_LIST: {
      return {
        ...flowState,
        error: null,
        steps: flowAction.payload,
      };
    }

    case ActionTypes.SET_NODE_LIST: {
      return {
        ...flowState,
        error: null,
        nodes: flowAction.payload,
        isUpdated: false,
      };
    }

    case ActionTypes.UPDATE_NODE_LIST: {
      return {
        ...flowState,
        error: null,
        nodes: flowAction.payload,
        isUpdated: true,
      };
    }

    case ActionTypes.SET_EDGE_LIST: {
      return {
        ...flowState,
        error: null,
        edges: flowAction.payload,
        isUpdated: false,
      };
    }

    case ActionTypes.SELECT_NODE: {
      return {
        ...flowState,
        error: null,
        selectedNode: flowAction.payload,
        selectedTab: 'node-property',
      };
    }

    case ActionTypes.DESELECT_NODE: {
      return {
        ...flowState,
        error: null,
        selectedNode: null,
      };
    }

    case ActionTypes.UPDATE_EDGE_LIST: {
      return {
        ...flowState,
        error: null,
        edges: flowAction.payload,
        isUpdated: true,
      };
    }

    case ActionTypes.SELECT_EDGE: {
      return {
        ...flowState,
        error: null,
        selectedEdge: flowAction.payload,
        selectedTab: 'connection-property',
      };
    }

    case ActionTypes.CHANGE_TAB: {
      return {
        ...flowState,
        error: null,
        selectedTab: flowAction.payload,
      };
    }

    case ActionTypes.FAILURE: {
      return {
        ...flowState,
        error: flowAction.payload,
      };
    }

    default:
      throw new Error();
  }
};

export { initialState, ActionTypes };

export default reducer;
