import { Column } from '@ant-design/plots';

const DashboardBar = ({
  data,
  xField,
  yField,
}: {
  data: any;
  xField: string;
  yField: string;
}) => {
  return (
    <Column
      data={data}
      xField={xField}
      yField={yField}
      label={{
        position: 'middle',

        style: {
          fill: '#FFFFFF',
          opacity: 0.6,
        },
      }}
      xAxis={{
        label: {
          autoHide: true,
          autoRotate: false,
        },
      }}
    />
  );
};
export default DashboardBar;
