export const FILTER_CRITERIA_CONTAINS = 'CONTAINS';
export const FILTER_CRITERIA_CONTAINS_LABEL = 'Contains';
export const FILTER_CRITERIA_CONTAINS_OBJECT = {
  label: FILTER_CRITERIA_CONTAINS_LABEL,
  value: FILTER_CRITERIA_CONTAINS,
  getFilterValue: value => ({
    $like: `%${value}%`,
  }),
};
export const FILTER_CRITERIA_NOT_CONTAINS = 'NOT_CONTAINS';
export const FILTER_CRITERIA_NOT_CONTAINS_LABEL = 'Not Contains';
export const FILTER_CRITERIA_NOT_CONTAINS_OBJECT = {
  label: FILTER_CRITERIA_NOT_CONTAINS_LABEL,
  value: FILTER_CRITERIA_NOT_CONTAINS,
  getFilterValue: value => ({
    $nlike: `%${value}%`,
  }),
};
export const FILTER_CRITERIA_EQUALS = 'EQUALS';
export const FILTER_CRITERIA_EQUALS_LABEL = 'Equals';
export const FILTER_CRITERIA_EQUALS_OBJECT = {
  label: FILTER_CRITERIA_EQUALS_LABEL,
  value: FILTER_CRITERIA_EQUALS,
  getFilterValue: value => ({
    $eq: value,
  }),
};
export const FILTER_CRITERIA_NOT_EQUALS = 'NOT_EQUALS';
export const FILTER_CRITERIA_NOT_EQUALS_LABEL = 'Not Equals';
export const FILTER_CRITERIA_NOT_EQUALS_OBJECT = {
  label: FILTER_CRITERIA_NOT_EQUALS_LABEL,
  value: FILTER_CRITERIA_NOT_EQUALS,
  getFilterValue: value => ({
    $ne: value,
  }),
};
export const FILTER_CRITERIA_GREATER_THAN = 'GREATER_THAN';
export const FILTER_CRITERIA_GREATER_THAN_LABEL = 'Greater Than';
export const FILTER_CRITERIA_GREATER_THAN_OBJECT = {
  label: FILTER_CRITERIA_GREATER_THAN_LABEL,
  value: FILTER_CRITERIA_GREATER_THAN,
  getFilterValue: value => ({
    $gt: value,
  }),
};
export const FILTER_CRITERIA_LESS_THAN = 'LESS_THAN';
export const FILTER_CRITERIA_LESS_THAN_LABEL = 'Less Than';
export const FILTER_CRITERIA_LESS_THAN_OBJECT = {
  label: FILTER_CRITERIA_LESS_THAN_LABEL,
  value: FILTER_CRITERIA_LESS_THAN,
  getFilterValue: value => ({
    $lt: value,
  }),
};
export const FILTER_CRITERIA_IN_RANGE = 'IN_RANGE';
export const FILTER_CRITERIA_IN_RANGE_LABEL = 'In Range';
export const FILTER_CRITERIA_IN_RANGE_OBJECT = {
  label: FILTER_CRITERIA_IN_RANGE_LABEL,
  value: FILTER_CRITERIA_IN_RANGE,
  getFilterValue: ([value1, value2]) => {
    if (!value1 && !value2) return null;
    if (!value1) return { $lte: value2 };
    if (!value2) return { $gte: value1 };
    return {
      $gte: value1,
      $lte: value2,
    };
  },
};

export const FILTER_CRITERIA_NOT_CONTAINS_MULTI_SELECT_DROPDOWN =
  'NOT_CONTAINS_MULTI_DROPDOWN';
export const FILTER_CRITERIA_NOT_CONTAINS_MULTI_SELECT_DROPDOWN_LABEL =
  'Not Contains';
export const FILTER_CRITERIA_NOT_CONTAINS_MULTI_SELECT_DROPDOWN_OBJECT = {
  label: FILTER_CRITERIA_NOT_CONTAINS_MULTI_SELECT_DROPDOWN_LABEL,
  value: FILTER_CRITERIA_NOT_CONTAINS_MULTI_SELECT_DROPDOWN,
  getFilterValue: value => ({
    $nin: value,
  }),
};

export const FILTER_CRITERIA_WORKFLOW = 'WORKFLOW';
export const FILTER_CRITERIA_WORKFLOW_LABEL = 'Ворфлов';
export const FILTER_CRITERIA_WORKFLOW_OBJECT = {
  label: FILTER_CRITERIA_WORKFLOW_LABEL,
  value: FILTER_CRITERIA_WORKFLOW,
  getFilterValue: value =>
    value.length > 1
      ? {
          $or: value,
        }
      : value[0],
};

const getTextValue = value => {
  if (value === '[BLANK]') return { $eq: null };
  return { $like: `%${value}%` };
};
export const FILTER_CRITERIA_DEFAULT = 'DEFAULT';
export const FILTER_CRITERIA_DEFAULT_LABEL = 'Default';
export const FILTER_CRITERIA_DEFAULT_OBJECT = {
  label: FILTER_CRITERIA_DEFAULT_LABEL,
  value: FILTER_CRITERIA_DEFAULT,
  getFilterValue: (values, fieldKey) =>
    values.length > 1
      ? {
          $or: values.map(value => ({
            [fieldKey]: getTextValue(value),
          })),
        }
      : getTextValue(values[0]),
};

export const FILTER_CRITERIA_IN = 'IN';
export const FILTER_CRITERIA_IN_LABEL = 'In';
export const FILTER_CRITERIA_IN_OBJECT = {
  label: FILTER_CRITERIA_IN_LABEL,
  value: FILTER_CRITERIA_IN,
  getFilterValue: value => ({
    $in: value,
  }),
};

export const FILTER_CRITERIAS_PER_TYPE = {
  [FILTER_CRITERIA_CONTAINS]: FILTER_CRITERIA_CONTAINS_OBJECT,
  [FILTER_CRITERIA_NOT_CONTAINS]: FILTER_CRITERIA_NOT_CONTAINS_OBJECT,
  [FILTER_CRITERIA_EQUALS]: FILTER_CRITERIA_EQUALS_OBJECT,
  [FILTER_CRITERIA_NOT_EQUALS]: FILTER_CRITERIA_NOT_EQUALS_OBJECT,
  [FILTER_CRITERIA_GREATER_THAN]: FILTER_CRITERIA_GREATER_THAN_OBJECT,
  [FILTER_CRITERIA_LESS_THAN]: FILTER_CRITERIA_LESS_THAN_OBJECT,
  [FILTER_CRITERIA_IN_RANGE]: FILTER_CRITERIA_IN_RANGE_OBJECT,
  [FILTER_CRITERIA_NOT_CONTAINS_MULTI_SELECT_DROPDOWN]:
    FILTER_CRITERIA_NOT_CONTAINS_MULTI_SELECT_DROPDOWN_OBJECT,
  [FILTER_CRITERIA_WORKFLOW]: FILTER_CRITERIA_WORKFLOW_OBJECT,
  [FILTER_CRITERIA_DEFAULT]: FILTER_CRITERIA_DEFAULT_OBJECT,
  [FILTER_CRITERIA_IN]: FILTER_CRITERIA_IN_OBJECT,
};

export const FILTER_CRITERIAS = {
  Numeric: [FILTER_CRITERIA_EQUALS_OBJECT],
  Checkbox: [FILTER_CRITERIA_IN_OBJECT],
  Switch: [FILTER_CRITERIA_IN_OBJECT],
  SingleSelect: [FILTER_CRITERIA_IN_OBJECT],
  MultiSelect: [FILTER_CRITERIA_IN_OBJECT],
  Date: [FILTER_CRITERIA_IN_RANGE_OBJECT],
  WorkflowStep: [FILTER_CRITERIA_WORKFLOW_OBJECT],
  default: [FILTER_CRITERIA_DEFAULT_OBJECT],
};
