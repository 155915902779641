import ContentLoader from 'react-content-loader';

const ButtonLoader = ({
  height,
  width,
  radius,
  ...props
}: {
  speed?: number;
  radius?: number;
  width?: number | string;
  height?: number | string;
  backgroundColor?: string;
  foregroundColor?: string;
  className?: string;
}) => (
  <ContentLoader viewBox={`0 0 ${width} ${height}`} height={height} {...props}>
    <rect x="0" y="0" rx={radius} ry={radius} width="100%" height={height} />
  </ContentLoader>
);

ButtonLoader.defaultProps = {
  speed: 2,
  width: '100%',
  height: 30,
  radius: 15,
  backgroundColor: '#f3f3f3',
  foregroundColor: '#ecebeb',
};

export default ButtonLoader;
