import { mapValues } from 'lodash';
import moment from 'moment';
import { TsumQuery } from 'sdk/models/TsumQuery';

import { UIComponents } from 'constants/fieldTypes';

import {
  FILTER_CRITERIA_IN_RANGE,
  FILTER_CRITERIAS_PER_TYPE,
} from '../constants/filterCriterias';

const parseFilterValue = field => {
  const { value, operator } = field;

  if (!value || operator === FILTER_CRITERIA_IN_RANGE) {
    return value;
  }

  if (moment.isDate(value)) {
    return moment(value).format('YYYY-MM-DD');
  }

  if (Array.isArray(value)) {
    return value.map(prop => prop.value);
  }

  if (typeof value === 'object' && value.value) {
    return value.value;
  }

  return value;
};

/**
 * Function responsible to get criteria from each applied filter
 * and populate "$where" and "$withRelated" objects
 *
 * @returns {$where, $withRelated}
 */
const workoutCriterion = (filters): TsumQuery => {
  /** Preparing $where object */
  let $where: any = {};

  Object.entries(filters).forEach(
    ([key, filter]: [key: string, filter: any]) => {
      if (!filter.criteria) return;

      if (
        filter.withRelated &&
        filter.uiComponent === UIComponents.MultiSelect
      ) {
        $where.$exists = {
          ...$where.$exists,
          ...{
            [filter.withRelated]: {
              $where: { id: filter.criteria },
            },
          },
        };
        return;
      }

      $where = { ...$where, ...{ [key]: filter.criteria } };
    },
  );

  if ($where._workflow_current_step) {
    const hasToDeleteTheWorkFlowKey =
      !$where._workflow_current_step._workflow_current_step;
    $where = {
      ...$where,
      ...$where._workflow_current_step,
    };
    if (hasToDeleteTheWorkFlowKey) {
      delete $where._workflow_current_step;
    }
  }

  /** Preparing $withRelated object */
  const $withRelated = Object.entries(filters)
    .filter(
      ([, filter]: [_: any, filter: any]) =>
        !!filter.withRelated && filter.uiComponent !== UIComponents.MultiSelect,
    )
    .map(([, filter]: [_: any, filter: any]) => filter.withRelated);

  return { $where, $withRelated };
};

const transformFiltersDataBeforeSubmit = (data, filterFields) => {
  const values = mapValues(data, (field, fieldKey) => {
    const value = parseFilterValue(field);

    return {
      ...field,
      criteria:
        field.value && FILTER_CRITERIAS_PER_TYPE[field.operator]
          ? FILTER_CRITERIAS_PER_TYPE[field.operator].getFilterValue(
              value,
              fieldKey,
            )
          : null,
    };
  });

  // Coppied from old FilterBar logic
  // TODO: This needs to be refactored. Now N:N or 1:N fields must pass withRelated entities to query related data.
  // Remapping key fields to not use field.key but field.relationship.name + id.
  const newValues = Object.keys(values).reduce((prevValue, fieldName) => {
    const field = filterFields[fieldName];
    const fieldProps = prevValue;

    if (field.relationshipOptions) {
      const fieldCriteria = prevValue[fieldName];
      delete fieldProps[fieldName];

      return {
        ...fieldProps,
        [`${field.relationshipOptions.name}.id`]: {
          ...fieldCriteria,
          withRelated: field.relationshipOptions.name,
        },
      };
    }

    return fieldProps;
  }, values);

  return workoutCriterion(newValues);
};

/**
 * Function responsible to transform applied filters values,
 * so we can display them properly inside of the dropdown buttons
 * @param {string} filterKey
 */
const transformAppliedFilterValues = (filterFields, formValues) => {
  const values = mapValues(formValues, (field, key) => {
    if (field.uiComponent === UIComponents.Date) {
      if (!field.value) return '';

      const dateFormat = filterFields[key].ui_component_options?.time
        ? 'DD/MM/YYYY hh:mm A'
        : 'DD/MM/YYYY';

      const firstDate = moment.utc(field.value[0]).isValid()
        ? moment.utc(field.value[0]).format(dateFormat)
        : '';
      const secondDate = moment.utc(field.value[1]).isValid()
        ? moment.utc(field.value[1]).format(dateFormat)
        : '';

      return `${firstDate}${firstDate && secondDate ? ' - ' : ''}${secondDate}`;
    }

    if (field.uiComponent === UIComponents.Numeric) {
      return field.value;
    }

    if (!field.value) return '';
    return field.value.map(item => item.label).join(', ');
  });

  return values || '';
};

export {
  parseFilterValue,
  workoutCriterion,
  transformFiltersDataBeforeSubmit,
  transformAppliedFilterValues,
};
