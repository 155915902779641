import React from 'react';
import { memo, useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import { useTranslation } from 'react-i18next';
import { FaEye } from 'react-icons/fa';

import cx from 'classnames';
import L from 'leaflet';
import moment from 'moment';
import PropTypes from 'prop-types';
import { getPropValue } from 'utils/formUtils';

import { DATE_FORMAT_WITH_TIME, DATE_FORMAT } from 'constants/dateFormat';
import { useDialogManager } from 'contexts/DialogManagerContext';

import MapLayers from './MapLayers';
import MapMarkers from './MapMarkers';

import 'leaflet/dist/leaflet.css';

import s from './MapView.module.scss';

const MapView = ({
  entityKey,
  data,
  options,
  afterDeleteCallback,
  afterSaveCallback,
  fields,
}) => {
  const { openDialog } = useDialogManager();
  const [map, setMap] = useState<L.Map | null>(null);
  const { title, description } = options;
  const { t } = useTranslation();

  useEffect(() => {
    const mapInstance = L.map('location-map', {
      center: [47.901790125044585, 106.89100885445507],
      zoom: 14,
      maxZoom: 26,
      crs: L.CRS.EPSG3857,
    });

    mapInstance.on('popupopen', (evt: any) => {
      const {
        payload: { entity },
      } = evt.popup;
      const wrapper = ReactDOM.createRoot(
        document.getElementById(`popup-wrapper-${entity.id}`) as HTMLElement,
      );

      wrapper.render(
        <div>
          <h5 className={s.popupTitle}>
            {title.map(fieldKey => getPropValue(fieldKey, entity))}
          </h5>

          {description.map(fieldKey => {
            let value = getPropValue(fieldKey.value, entity);
            const field = getPropValue(fieldKey.value, fields);

            if (value && field?.ui_component === 'Date') {
              const format = field.ui_component_options.time
                ? DATE_FORMAT_WITH_TIME
                : DATE_FORMAT;

              value = moment(value).format(format);
            }

            return (
              <div className={s.popupItem} key={fieldKey.label}>
                <div>{fieldKey.label}:</div>
                <b>{value || '-'}</b>
              </div>
            );
          })}

          <div className="d-flex mt-2 justify-content-end">
            <button
              type="button"
              className="btn btn-light btn-sm"
              onClick={() => {
                openDialog({
                  entityKey,
                  entityId: entity.id,
                  afterSaveCallback,
                  afterDeleteCallback,
                });
              }}
            >
              <FaEye /> {t('entity.view.view-record')}
            </button>
          </div>
        </div>,
      );
    });

    setMap(mapInstance);

    return () => {
      setMap(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div className={cx('leaflet-touch', s.map)} id="location-map" />

      {map && (
        <>
          <MapLayers map={map} />
          <MapMarkers map={map} data={data} options={options} />
        </>
      )}
    </div>
  );
};

MapView.propTypes = {
  entityKey: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  afterSaveCallback: PropTypes.func.isRequired,
  afterDeleteCallback: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  options: PropTypes.shape({
    locationField: PropTypes.string.isRequired,
    title: PropTypes.array.isRequired,
    description: PropTypes.array.isRequired,
  }).isRequired,
};

MapView.defaultProps = {};

export default memo(MapView);
