import moment from 'moment';
import 'moment/locale/mn';
import 'moment/locale/en-au';

const Moment = ({
  format,
  fromNow,
  children,
}: {
  format: string | undefined;
  fromNow: boolean | undefined;
  children: any;
}) => {
  moment.locale(process.env.REACT_APP_LANG);
  if (fromNow) {
    return <>{moment(children).fromNow()}</>;
  }

  return <>{moment(children).format(format)}</>;
};

Moment.defaultProps = {
  format: 'DD/MM/YYYY hh:mm A',
  fromNow: false,
};

export default Moment;
