const ActionTypes = {
  FETCH_METADATA_LIST_REQUEST: '[App] Fetch Metadata List Request',
  FETCH_METADATA_LIST_SUCCESS: '[App] Fetch Metadata List Success',
  FETCH_METADATA_LIST_FAILURE: '[App] Fetch Metadata List Failure',
  CLEAR_JSON_METADATA_FILES: '[App] JSON Clear Metadata Files',
  FETCH_JSON_METADATA_REQUEST: '[App] Fetch JSON Metadata File Request',
  FETCH_JSON_METADATA_SUCCESS: '[App] Fetch JSON Metadata File Success',
  FETCH_JSON_METADATA_FAILURE: '[App] Fetch JSON Metadata File Failure',
  SAVE_JSON_METADATA_REQUEST: '[App] Save JSON Metadata File Request',
  SAVE_JSON_METADATA_SUCCESS: '[App] Save JSON Metadata File Success',
  SAVE_JSON_METADATA_FAILURE: '[App] Save JSON Metadata File Failure',
  CREATE_JSON_METADATA_REQUEST: '[App] Create JSON Metadata File Request',
  CREATE_JSON_METADATA_SUCCESS: '[App] Create JSON Metadata File Success',
  CREATE_JSON_METADATA_FAILURE: '[App] Create JSON Metadata File Failure',
  DELETE_JSON_METADATA_REQUEST: '[App] Delete JSON Metadata File Request',
  DELETE_JSON_METADATA_SUCCESS: '[App] Delete JSON Metadata File Success',
  DELETE_JSON_METADATA_FAILURE: '[App] Delete JSON Metadata File Failure',
  SELECT_METADATA_TABLE: '[App] Select Metadata Table',
};

const initialState = {
  isLoading: false,
  metadataList: [],
  workflowMetadata: null,
  tableMetadata: null,
  tableKey: undefined,
  error: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_METADATA_LIST_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
        metadataList: [],
      };
    }

    case ActionTypes.FETCH_METADATA_LIST_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        metadataList: action.payload,
      };
    }

    case ActionTypes.FETCH_METADATA_LIST_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    }

    case ActionTypes.CLEAR_JSON_METADATA_FILES: {
      return {
        ...state,
        isLoading: true,
        error: null,
        workflowMetadata: null,
        tableMetadata: null,
      };
    }

    case ActionTypes.FETCH_JSON_METADATA_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }

    case ActionTypes.FETCH_JSON_METADATA_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      };
    }

    case ActionTypes.FETCH_JSON_METADATA_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    }

    case ActionTypes.SAVE_JSON_METADATA_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }

    case ActionTypes.SAVE_JSON_METADATA_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      };
    }

    case ActionTypes.SAVE_JSON_METADATA_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    }

    case ActionTypes.CREATE_JSON_METADATA_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }

    case ActionTypes.CREATE_JSON_METADATA_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      };
    }

    case ActionTypes.CREATE_JSON_METADATA_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    }

    case ActionTypes.DELETE_JSON_METADATA_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }

    case ActionTypes.DELETE_JSON_METADATA_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      };
    }

    case ActionTypes.DELETE_JSON_METADATA_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    }

    case ActionTypes.SELECT_METADATA_TABLE: {
      return {
        ...state,
        isLoading: false,
        tableKey: action.payload,
      };
    }

    default:
      throw new Error();
  }
};

export { initialState, ActionTypes };

export default reducer;
