import { useFormContext, Controller } from 'react-hook-form';

import { Switch } from 'antd';
import cx from 'classnames';
import { ComponentInputProps } from 'sdk/models/ComponentProperties';

const SwitchComponent = ({
  name,
  defaultValue = false,
  props = { readOnly: false },
}: ComponentInputProps) => {
  const { readOnly } = props;
  const {
    control,
    formState: { errors },
  } = useFormContext();
  return (
    <div
      className={cx('custom-control-input form-switch-input', {
        'is-invalid': errors[name],
      })}
    >
      <Controller
        defaultValue={defaultValue}
        control={control}
        name={name}
        render={({ field }) => (
          <Switch
            id={`switch-${name}`}
            checked={field.value}
            disabled={readOnly}
            {...field}
          />
        )}
      />

      <label className="custom-control-label mx-1">{props.label}</label>

      <div>
        {props.description && (
          <small className="form-text text-body-tertiary">
            {props.description}
          </small>
        )}
      </div>
    </div>
  );
};

export default SwitchComponent;
