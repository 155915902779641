export const ENTITY_LOG_ACTION_ATTACHMENT_UPLOAD = 'ATTACHMENT/UPLOAD';
export const ENTITY_LOG_ACTION_ATTACHMENT_DOWNLOAD = 'ATTACHMENT/DOWNLOAD';
export const ENTITY_LOG_ACTION_ATTACHMENT_DELETE = 'ATTACHMENT/DELETE';
export const ENTITY_LOG_ACTION_UPDATE = 'DATA/UPDATE';
export const ENTITY_LOG_ACTION_CREATE = 'DATA/CREATE';
export const ENTITY_LOG_ACTION_ASSIGNED = 'DATA/ASSIGNED';
export const ENTITY_LOG_ACTION_REJECTED = 'DATA/REJECTED';
export const ENTITY_LOG_ACTION_APPROVED = 'DATA/APPROVED';
export const ENTITY_LOG_ACTION_PICKED_UP = 'DATA/PICKED_UP';
export const ENTITY_LOG_ACTION_MOVED_BACK = 'DATA/MOVED_BACK';
export const ENTITY_LOG_ACTION_MOVED_FORWARD = 'DATA/MOVED_FORWARD';

const colors = {
  green: '#4DAF80',
  red: '#F13A3A',
  purple: '#998BD3',
  darkBlue: '#2386A5',
  lightBlue: '#4CB0C6',
  yellow: '#FABE24',
};

export const ENTITY_LOG_ACTION_OBJECT = {
  [ENTITY_LOG_ACTION_ATTACHMENT_UPLOAD]: {
    icon: 'far fa-arrow-up',
    background: colors.purple,
    text: 'attachment-uploaded',
  },
  [ENTITY_LOG_ACTION_ATTACHMENT_DOWNLOAD]: {
    icon: 'far fa-arrow-down',
    background: colors.darkBlue,
    text: 'attachment-downloaded',
  },
  [ENTITY_LOG_ACTION_ATTACHMENT_DELETE]: {
    icon: 'far fa-trash-alt',
    background: colors.red,
    text: 'attachment-deleted',
  },
  [ENTITY_LOG_ACTION_UPDATE]: {
    icon: 'fas fa-pen',
    background: colors.green,
    text: 'entity-updated',
  },
  [ENTITY_LOG_ACTION_CREATE]: {
    icon: 'fas fa-layer-plus',
    background: colors.green,
    text: 'entity-created',
  },
  [ENTITY_LOG_ACTION_ASSIGNED]: {
    icon: 'fas fa-user-plus',
    background: colors.purple,
    text: 'user-assigned',
  },
  [ENTITY_LOG_ACTION_REJECTED]: {
    icon: 'fas fa-times',
    background: colors.red,
    text: 'record-rejected',
  },
  [ENTITY_LOG_ACTION_APPROVED]: {
    icon: 'fas fa-check',
    background: colors.green,
    text: 'record-approved',
  },
  [ENTITY_LOG_ACTION_PICKED_UP]: {
    icon: 'fas fa-level-up-alt',
    background: colors.yellow,
    text: 'record-picked',
  },
  [ENTITY_LOG_ACTION_MOVED_BACK]: {
    icon: 'fas fa-chevron-double-left',
    background: colors.lightBlue,
    text: 'record-backed',
  },
  [ENTITY_LOG_ACTION_MOVED_FORWARD]: {
    icon: 'fas fa-chevron-double-right',
    background: colors.lightBlue,
    text: 'record-assigned',
  },
};

export const ENTITY_LOG_ACTIONS = [
  ENTITY_LOG_ACTION_ATTACHMENT_UPLOAD,
  ENTITY_LOG_ACTION_ATTACHMENT_DOWNLOAD,
  ENTITY_LOG_ACTION_ATTACHMENT_DELETE,
  ENTITY_LOG_ACTION_UPDATE,
  ENTITY_LOG_ACTION_CREATE,
  ENTITY_LOG_ACTION_ASSIGNED,
  ENTITY_LOG_ACTION_REJECTED,
  ENTITY_LOG_ACTION_APPROVED,
  ENTITY_LOG_ACTION_PICKED_UP,
  ENTITY_LOG_ACTION_MOVED_BACK,
  ENTITY_LOG_ACTION_MOVED_FORWARD,
];
